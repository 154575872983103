import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import Media from "../../model/Media";
import User from "../../model/user";


export default class MediaDetailsModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openMediaDetailsModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { opened: true, undismissible: options.undismissible, close_cb: close_cb, media_id: options.media_id };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onSubmit = () => {

        this.state.closeModal();
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let media = Media.getMedia(this.state.media_id),
                access = media.access || {type: 'free'};
            content = <div id="media-details-modal-content" className="reglisse">
                <h3 style={{margin: 0, wordBreak: 'break-word'}}>{"Media details : " + media.name}</h3>
                <div style={{marginTop: '40px'}}>
                    <div className="media-toolbox-text"><span>Name : </span><span>{media.name}</span></div>
                    <div className="media-toolbox-text"><span>Type : </span><span>{media.type}</span></div>
                    {media.hosting === 'external' ? <div className="media-toolbox-text"><span>Url : </span><span>{media.url}</span></div> : null}
                    {media.hosting === 'internal' ? <div className="media-toolbox-text"><span>Filename : </span><span>{media.filename}</span></div> : null}
                    {User.isAdmin() && media.green_screen && <div className="media-toolbox-text"><span>Green Screen : </span><span className="green-text">yes</span></div>}
                    {media.preview_url ? <div className="media-toolbox-text"><span>Preview url : </span><span>{media.preview_url}</span></div> : undefined}
                    <div className="media-toolbox-text"><span>Access : </span><span>{access.type.upperFirstLetter()}</span></div>
                    {access.type === 'buy' || access.type === 'rent' ? <div className="media-toolbox-text">
                        <span>Price : </span><span>{media.access.price + ' €'}</span>
                    </div> : undefined}
                    {access.type === 'rent' ? <div className="media-toolbox-text">
                        <span>Renting time : </span><span>{access.renting_time > 24 ? (Math.round(access.renting_time / 24) + " days") : (access.renting_time + " hours")}</span>
                    </div> : undefined}
                </div>
                <div className="modal-footer">
                    <Button text={"Cancel"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="media-details-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
