import map_template_empty from './images/map_toolbox/templates/empty.jpg';

import map_template_building from './images/map_toolbox/templates/building.jpg';

import map_template_park_1 from './images/map_toolbox/templates/park_1.jpg';
import map_template_plaza_1 from './images/map_toolbox/templates/plaza_1.jpg';

import map_template_road_1 from './images/map_toolbox/templates/road_1.jpg';
import map_template_road_2 from './images/map_toolbox/templates/road_2.jpg';
import map_template_road_3 from './images/map_toolbox/templates/road_3.jpg';
import map_template_road_4 from './images/map_toolbox/templates/road_4.jpg';
import map_template_road_5 from './images/map_toolbox/templates/road_5.jpg';
import map_template_road_6 from './images/map_toolbox/templates/road_6.jpg';
import map_template_road_7 from './images/map_toolbox/templates/road_7.jpg';
import map_template_road_8 from './images/map_toolbox/templates/road_8.jpg';
import map_template_road_9 from './images/map_toolbox/templates/road_9.jpg';
import map_template_road_10 from './images/map_toolbox/templates/road_10.jpg';
import map_template_road_11 from './images/map_toolbox/templates/road_11.jpg';
import map_template_road_12 from './images/map_toolbox/templates/road_12.jpg';
import map_template_road_13 from './images/map_toolbox/templates/road_13.jpg';
import map_template_road_14 from './images/map_toolbox/templates/road_14.jpg';


const map_template_images = {
    empty: map_template_empty,
    building: map_template_building,
    park_1: map_template_park_1,
    plaza_1:  map_template_plaza_1,
    road_1: map_template_road_1,
    road_2: map_template_road_2,
    road_3: map_template_road_3,
    road_4: map_template_road_4,
    road_5: map_template_road_5,
    road_6: map_template_road_6,
    road_7: map_template_road_7,
    road_8: map_template_road_8,
    road_9: map_template_road_9,
    road_10: map_template_road_10,
    road_11: map_template_road_11,
    road_12: map_template_road_12,
    road_13: map_template_road_13,
    road_14: map_template_road_14
};

const images = {

    getMapTemplateImage: (target) => {
        return map_template_images[target];
    }

};

export default images;