import React from "react";
import Land from "../../model/Land";
import Screen from "../../model/Screen";
import MultiScreen from "../../model/MultiScreen";
import Link from "../../model/Link";
import generic from "../../model/generic";
import Button from "../global/Button";
import LoadingAnimation from "../global/LoadingAnimation";
import MediaDisplay from "./MediaDisplay";


export default class extends React.Component {

    state = {display_index: 0}

    componentDidMount() {
        generic.addEntityListener('screen', this.onEntityUpdate);
        generic.addEntityListener('multi_screen', this.onEntityUpdate);
        generic.addEntityListener('link', this.onEntityUpdate);
        this.checkEntityIsLoaded();
    };

    componentWillUnmount() {
        generic.removeEntityListener('screen', this.onEntityUpdate);
        generic.removeEntityListener('multi_screen', this.onEntityUpdate);
        generic.removeEntityListener('link', this.onEntityUpdate);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.entity_id !== this.props.entity_id){
            this.setState({display_index: 0});
            this.checkEntityIsLoaded();
        }
    };

    onEntityUpdate = (entity_id) => {
        if(entity_id === this.props.entity_id){
            if(this.props.entity_type === 'multi_screen'){
                let entity = MultiScreen.getMultiScreen(entity_id),
                    media_id_list = _.get(entity, 'data.media_id_list', []);
                if(this.state.display_index >= media_id_list.length) {
                    this.setState({display_index: media_id_list.length - 1});
                }
            }
            this.refresh();
        }
    }

    checkEntityIsLoaded = () => {
        let entity_type = this.props.entity_type, entity_id = this.props.entity_id;
        if(entity_id && !generic.getEntity(entity_type, entity_id)){
            generic.refreshEntity(entity_type, entity_id, (err, entity) => {
                if(entity_type === 'screen') {
                    let media_id = _.get(entity, 'data.media_id');
                    if (media_id && !Media.getMedia(media_id)) {
                        Media.refreshMedia(media_id, this.refresh.bind(this));
                    }
                }else if(entity_type === 'multi_screen'){
                    let media_id_list = _.get(entity, 'data.media_id_list');
                    if(media_id_list && media_id_list.length){
                        media_id_list.forEach((media_id) => {
                            if(media_id && !Media.getMedia(media_id)){
                                Media.refreshMedia(media_id, this.refresh.bind(this));
                            }
                        });
                    }
                }else if(entity_type === 'link'){
                    let target_list = _.get(entity, 'data.target_list');
                    if(target_list && target_list.length){
                        target_list.forEach((target) => {
                            if(target.type === 'video_360'){
                                let media_id = target.id;
                                if(media_id && !Media.getMedia(media_id)){
                                    Media.refreshMedia(media_id, this.refresh.bind(this));
                                }
                            }
                        });
                    }
                }
            });
        }
    }

    onShow = () => {
        if(this.props.screen_type === 'scene'){
            let options = {scene_id: this.props.scene_id}, entity_type = this.props.entity_type, entity_id = this.props.entity_id;
            if(entity_type === 'screen'){
                options.screen_id = entity_id;
            }else if(entity_type === 'multi_screen'){
                options.multi_screen_id = entity_id;
            }else if(entity_type === 'link'){
                options.link_id = entity_id;
            }
            window.openIndoorMediaDisplayPositionModal(options);
        }else{
            window.openLandMediaDisplayPositionModal({screen_id: this.props.entity_id, land_id: this.props.land_id});
        }
    };

    onPrevious = () => {
        this.setState({display_index: this.state.display_index - 1});
    };

    onNext = () => {
        this.setState({display_index: this.state.display_index + 1});
    };

    onAdd = () => {
        let entity_type = this.props.entity_type, entity_id = this.props.entity_id;
        let media_type_filter = ['picture', 'video', 'video_360'];
        if(entity_type === 'link'){
            media_type_filter = ['video_360'];
        }else if(entity_type === 'screen'){
            media_type_filter = ['picture', 'video'];
        }
        window.openDisplayMediaAddModal({
            multiple_select: entity_type === 'multi_screen',
            media_type_filter: media_type_filter,
            on_submit: (selected => {
                if(entity_type === 'screen'){
                    let options = {
                        target_entity: this.props.screen_type,
                        target_entity_id: this.props.screen_type === 'scene' ? this.props.scene_id : this.props.land_id,
                        target_index: this.props.target_index
                    };
                    Screen.updateScreenMedia(entity_id, selected, options, (err) => {
                        if(err){
                            window.alertPopup('Error', 'An error has occurred while updating the screen');
                        }
                        this.refresh();
                    });
                }else if(entity_type === 'multi_screen'){
                    let multi_screen = MultiScreen.getMultiScreen(entity_id), media_id_list = _.cloneDeep(_.get(multi_screen, "data.media_id_list", []));
                    MultiScreen.updateMultiScreenMedia(entity_id, media_id_list.concat(selected), (err) => {
                        if(err){
                            window.alertPopup('Error', 'An error has occurred while updating the multi screen');
                        }
                        this.refresh();
                    });
                }else if(entity_type === 'link'){
                    let target_list = [{type: 'video_360', id: selected}];
                    Link.updateLinkTargetList(entity_id, target_list, (err) => {
                        if(err){
                            window.alertPopup('Error', 'An error has occurred while updating the link');
                        }
                        this.refresh();
                    });
                }
        })});
    };

    onRemove = () => {
        if(this.props.entity_type !== 'multi_screen'){
            return
        }
        let multi_screen_id = this.props.entity_id, multi_screen = MultiScreen.getMultiScreen(multi_screen_id),
            media_id_list = _.cloneDeep(_.get(multi_screen, "data.media_id_list", [])), index = this.state.display_index, remove = () => {
            media_id_list.splice(index, 1);
            MultiScreen.updateMultiScreenMedia(multi_screen_id, media_id_list, (err) => {
                if(err){
                    window.alertPopup('Error', 'An error has occurred while updating the multi screen');
                }
                this.refresh();
            });
        };
        if(index === media_id_list.length - 1 && index !== 0){
            this.setState({display_index: index - 1}, remove);
        }else{
            remove();
        }
    };

    onOpenMultiScreen = () => {
        let multi_screen_id = this.props.entity_id;
        window.openMultiScreenModal({multi_screen_id: multi_screen_id});
    }

    refresh = () => {
        this.forceUpdate();
    };

    render() {
        let entity_type = this.props.entity_type, entity_id = this.props.entity_id, entity = generic.getEntity(entity_type, entity_id);
        let display_index = this.state.display_index, content_display = [], entity_loaded = false, media_id_list, multiple_select = entity_type === 'multi_screen';
        if(entity){
            entity_loaded = true;
            let media_id;
            if(entity_type === 'screen'){
                media_id = _.get(entity, 'data.media_id');
            }else if(entity_type === 'link'){
                media_id = _.get(entity, 'data.target_list[0].id');
            }else{
                media_id_list = _.get(entity, 'data.media_id_list', []);
                if(media_id_list.length){
                    media_id = media_id_list[display_index];
                }
            }
            if(media_id){
                content_display.push(<MediaDisplay key={media_id} media_id={media_id} onMediaLoad={this.refresh} displayName={true} />);
            }else{
                content_display = <div style={{color: 'black', paddingTop: '100px'}}><h5 className="inline-block">No centent yet</h5></div>;
            }
        }else{
            content_display = <LoadingAnimation type="circular" />;
        }
        return <div className='col s4 tm bm'>
            <div className='screen-card'>
                <div style={{height: "280px"}}>{content_display}</div>
                <div>
                    <Button className="t18-btn utils-btn" text={<i className="material-icons" style={{fontSize: '25px', position: 'relative', bottom: "1px"}} >my_location</i>} onClick={this.onShow} disabled={!entity_loaded} />
                    {!multiple_select ? <Button className="t18-btn utils-btn lm" text={<i className="material-icons" style={{fontSize: '25px', position: 'relative', bottom: "1px"}} >photo_library</i>} onClick={this.onAdd} disabled={!entity_loaded} /> : undefined}
                    {multiple_select ? <Button className="t18-btn utils-btn lm" text={'<'} onClick={this.onPrevious} disabled={!entity_loaded || display_index === 0} /> : undefined}
                    {multiple_select ? <Button className="t18-btn utils-btn lm" text={'>'} onClick={this.onNext} disabled={!entity_loaded || display_index >= media_id_list.length - 1} /> : undefined}
                    {false && multiple_select ? <Button className="t18-btn utils-btn lm" text={'+'} onClick={this.onAdd} disabled={!entity_loaded} /> : undefined}
                    {false && multiple_select ? <Button className="t18-btn utils-btn lm" text={'-'} onClick={this.onRemove} disabled={!entity_loaded || media_id_list.length === 0} /> : undefined}
                    {multiple_select ? <Button className="t18-btn utils-btn lm" text={<i className="material-icons" style={{fontSize: '25px', position: 'relative', bottom: "1px"}} >apps</i>}
                                               onClick={this.onOpenMultiScreen} disabled={!entity_loaded} /> : undefined}
                </div>
            </div>
        </div>
    }

}