import React from "react";

import FlashSales from "../../model/FlashSales";
import model from "../../model/model";
import CountDown from "../Common/CountDown";
import config from "../../config";
import IconButton from "../global/IconButton";


export default class FlashSaleBanner extends React.Component {

  state = {show: true, minimized: false};

  componentDidMount() {
      FlashSales.onFlashSaleUpdateSubscribe(this.onFlashSaleUpdate);
  }

  onFlashSaleUpdate = () => {
    if(!this.state.show && FlashSales.flashSaleOngoing()){
      this.setState({show: true})
    }
  };

  onClose = () => {
    this.setState({show: false});
  }

  refresh = () => {
    this.forceUpdate();
  }

  render(){
    if(!this.state.show) return null;
      if(!FlashSales.publicFlashSaleStarted() && !User.hasEarlyAccess()){
          return <div id="flash-sale-banner" className="waiting-start">
              <IconButton className="theme-color" style={{position: 'absolute', right: '5px', top: '5px'}} icon={"clear"} onClick={this.onClose} />
              <h5>Public Flash Sale Starting Soon !</h5>
              <CountDown target={FlashSales.getPublicFlashSaleOpeningDate()} onTargetReached={this.refresh} />
          </div>
      }
      if(FlashSales.publicFlashSaleEnded()){
            return <div id="flash-sale-banner" className="ended">
                <IconButton className="theme-color" style={{position: 'absolute', right: '5px', top: '5px'}} icon={"clear"} onClick={this.onClose} />
                <h5>Public Flash Sale Ended !</h5>
            </div>
      }
    let display = [], flash_sales = FlashSales.getFlashSales();
    for(let number in flash_sales){
        let flash_sale = flash_sales[number];
        if(flash_sale.count > 0){
            display.push(<FlashSale key={flash_sale.number} flash_sale={flash_sale}/>)
        }
    }
    return <div id="flash-sale-banner">
        <IconButton className="theme-color" style={{position: 'absolute', right: '5px', top: '5px'}} icon={"clear"} onClick={this.onClose} />
        <h5 style={{marginTop: '5px'}}>Flash sales ongoing!</h5>
        <div id="flash-sale-banner-content">
            {display}
        </div>
        <h5 className="black-text">Ending soon !</h5>
        <CountDown target={FlashSales.getFlashSaleEndDate()} onTargetReached={this.refresh} style={{marginTop: '0px'}} />
    </div>
  }

}

class FlashSale extends React.Component {

    render(){
        let flash_sale = this.props.flash_sale, zone = model.getZoneData(flash_sale.number);
        let discount = - Math.round((1 - parseFloat(flash_sale.price) / parseFloat(zone.price)) * 100);
        discount = Math.round(10 * Math.round(discount / 10));
        return <div className="flash-sale">
            <h6>
                <span className="black-text">Zone </span>
                <span className="theme-color">{flash_sale.number}</span>
                <span className="black-text"> : </span>
                <span className="blue-text">{flash_sale.count} / {flash_sale.original_count} </span>
                <span className="black-text">for </span>
                <span className="blue-text">{flash_sale.price} eth</span>
                {config.display_dollar_price && <span className="black-text"> ({model.getDollarPrice(flash_sale.price)}$)</span>}
                <span className="z-depth-1" style={{color: 'white', marginLeft: '10px', borderRadius: '10px', padding: '2px 5px', backgroundColor: '#4CAF50'}}>
                    {discount > 0 ? '+': ''}{discount}%
                </span>
            </h6>
        </div>
    }

}