import React from "react";
import _                 from "lodash";

import InputField from "./InputField";
import CheckBox   from './CheckBox.js';
import Select     from "./Select";

/**
 *  Props : --> id : id to give to the component
 *          --> style : style to apply to the table
 *          --> className : class to apply to the table
 *          --> headers : {headers:[header1,header2,...], style:{style}, className:classname }
 *          --> rows : [{data:[row1data1,row1data2,..],style:{style}},{data:[row2data1,row2data2,...]},...]
 *          --> enableFilters
 *          --> height
 *          --> fixedHeaders
 *          --> sortable (if true, auto sort on first column)
 *          --> sortExcludeColumns -> ex : [0,2,4]
 *          --> columnSortType
 *          --> enableSearch
 *          --> columnSelectFilters
 */

export default class extends React.Component {
    state = {
        id: (this.props.id ? this.props.id : window.generateID()),
        filters: [],
        columnSelectFilterValues: {},
        search: "",
        sort: { index: -1, reverse: false, sorting_type: this.props.columnSortType || {} }
    };

    componentDidMount() {
        this.resetFilters();
    }

    componentDidUpdate() {
        if(this.props.headers && this.state.filters.length !== this.props.headers.headers.length)
            this.resetFilters();
    }

    resetFilters = () => {
        const filters            = [],
              rawDisabledFilters = localStorage.getItem(this.state.id + "-disabled-filters"),
              disabledFilters    = rawDisabledFilters ? rawDisabledFilters.split(",") : [];
        if(this.props.headers) {
            for(let h in this.props.headers.headers) {
                if(this.props.saveFilters && disabledFilters.indexOf(h + "") > -1)
                    filters.push(false);
                else
                    filters.push(true);
            }
        }
        this.setState({ filters: filters });
    };

    onFilterClick = (index, checked) => {
        const filters = this.state.filters.slice();
        filters[index] = checked;
        this.setState({ filters: filters });
        if(this.props.saveFilters && this.props.id) {
            const rawDisabledFilters = localStorage.getItem(this.state.id + "-disabled-filters"),
                  disabledFilters    = rawDisabledFilters ? rawDisabledFilters.split(",") : [];
            if(filters[index]) {
                const i = disabledFilters.indexOf(index);
                if(i > -1)
                    disabledFilters.splice(i, 1);
            } else if(disabledFilters.indexOf(index) === -1) {
                disabledFilters.push(index);
            }
            localStorage.setItem(this.state.id + "-disabled-filters", disabledFilters.join(","));
        }
    };

    onSort = (index) => {
        const sort = this.state.sort.index == index ? {
            index: index,
            reverse: !this.state.sort.reverse,
            sorting_type: this.state.sort.sorting_type
        } : { index: index, reverse: false, sorting_type: this.state.sort.sorting_type };
        this.setState({ sort: sort });
    };

    onSearchChange = (value) => {
        this.setState({ search: value });
    };

    getColumnSelectFilter = (index) => {
        const column_select_filters = this.props.columnSelectFilters;
        if(column_select_filters && column_select_filters[index]) {
            let filter = column_select_filters[index], selected = this.state.columnSelectFilterValues[index] || 'all',
                options                                         = { all: window.appText.all };
            for(let value in filter) {
                options[value] = filter[value];
            }
            return <Select className="header-select-filter" options={options} selected={selected} onChange={(value) => {
                let values = _.cloneDeep(this.state.columnSelectFilterValues);
                values[index] = value.val;
                this.setState({ columnSelectFilterValues: values });
            }}/>
        }
    };

    getHeaders = () => {
        const headers = [];
        for(let h in this.props.headers.headers) {
            if(this.props.headers.headers.hasOwnProperty(h)) {
                if(this.state.filters[h]) {
                    let show_sort_icon = this.props.sortable && (!this.props.sortExcludeColumns || this.props.sortExcludeColumns.indexOf(parseInt(h)) === -1),
                        sort_icon;
                    if(show_sort_icon) {
                        let sorting = this.state.sort;
                        sort_icon = <i className="material-icons md-unfold_more clickable"
                                       style={{ position: "relative", top: 6 }}
                                       onClick={this.onSort.bind(null, parseInt(h))}/>;
                        if(sorting.index == h) {
                            if(sorting.reverse) {
                                sort_icon = <i className="material-icons md-keyboard_arrow_up clickable blue-text"
                                               style={{ position: "relative", top: 6 }}
                                               onClick={this.onSort.bind(null, parseInt(h))}/>;
                            } else {
                                sort_icon = <i className="material-icons md-keyboard_arrow_down clickable blue-text"
                                               style={{ position: "relative", top: 6 }}
                                               onClick={this.onSort.bind(null, parseInt(h))}/>;
                            }
                        }
                    }
                    let header_content = (
                        <span>
                            {this.props.headers.headers[h]}{show_sort_icon ? sort_icon : null}
                        </span>
                    );
                    let select_filter = this.getColumnSelectFilter(h);
                    if(select_filter) {
                        header_content = (
                            <span>
                                <div>{header_content}</div>
                                <div>{select_filter}</div>
                            </span>
                        );
                    }
                    headers.push(
                        <th key={`th-${h}`}
                            className={this.props.headers.className ? this.props.headers.className : ""}
                            style={this.props.headers.style ? this.props.headers.style : {}}>
                            {header_content}
                        </th>
                    );
                }
            }
        }

        return headers;
    };

    getFilteredData = (rows) => {
        let select_filters;
        if(this.props.columnSelectFilters) {
            select_filters = {};
            for(let index in this.state.columnSelectFilterValues) {
                let value = this.state.columnSelectFilterValues[index];
                if(value !== 'all') {
                    select_filters[index] = this.props.columnSelectFilters[index][value];
                }
            }
            if(Object.keys(select_filters).length === 0) select_filters = null;
        }
        if((!this.props.enableSearch || this.state.search === '') && !select_filters) return rows;
        let filtered = [], search = this.state.search.toLowerCase();
        for(let row of rows) {
            let include = false;
            for(let index in row.data) {
                let value         = textContent(row.data[index]),
                    select_filter = select_filters && select_filters[index] && select_filters[index] !== 'all' ? select_filters[index] : null;
                if((search && value.toLowerCase().indexOf(search) > -1) || (select_filter && value.indexOf(select_filter) > -1)) {
                    include = true;
                    break;
                }
            }
            if(include) {
                filtered.push(row);
            }
        }
        return filtered;
    };

    getSortedData = (rows) => {
        let sorting = this.state.sort;
        if(!this.props.sortable || sorting.index === -1) return rows;
        let sorted = [];
        let index = sorting.index, reverse = sorting.reverse;
        let i, j, row;
        for(i = 0; i < rows.length; i++) {
            row = rows[i];
            for(j = 0; j < sorted.length; j++) {
                if(sortingTest(textContent(row.data[index]), textContent(sorted[j].data[index]), reverse, sorting.sorting_type[index])) {
                    break;
                }
            }
            if(j < sorted.length)
                sorted.splice(j, 0, row);
            else
                sorted.push(row);
        }
        return sorted;
    };

    render() {
        const tableStyle = this.props.style ? this.props.style : {};
        let rows_display = [];
        let rows = this.getFilteredData(this.props.rows);
        rows = this.getSortedData(rows);
        for(let r in rows) {
            if(rows.hasOwnProperty(r)) {
                const cells = [], row = rows[r];
                for(let data in row.data) {
                    if(row.data.hasOwnProperty(data)) {
                        if((this.props.enableFilters && this.state.filters[data]) || !this.props.enableFilters)
                            cells.push(
                                <td key={`${r}-${data}`}
                                    className={row.tdClassName ? row.tdClassName : ""}>
                                    {row.data[data]}
                                </td>
                            );
                    }
                }
                rows_display.push(
                    <tr key={`${r}`} style={row.style ? row.style : {}}
                        onClick={row.onClick ? row.onClick : null}
                        className={row.className ? row.className : ""}>
                        {cells}
                    </tr>
                );
            }
        }
        return (
            <div id={this.state.id + "-container"}
                 style={{ height: this.props.containerHeight || "100%", position: "relative" }}>
                {this.props.enableFilters ?
                    <DataTableFilters tableId={this.state.id} headers={this.props.headers.headers}
                                      filters={this.state.filters} onFilterClick={this.onFilterClick}/> : null}
                {this.props.enableSearch ? <div>
                    <InputField style={{ height: "30px", display: 'inline-block', width: "250px" }}
                                value={this.state.search} onChange={this.onSearchChange}
                                label={window.appText.search}/>
                </div> : null}
                <div style={this.props.height ? {
                    height: this.props.height.indexOf("%") === -1 ? this.props.height + "px" : this.props.height,
                    "overflowY": "auto",
                    "overflowX": "hidden"
                } : {}}>
                    <table id={this.state.id} style={tableStyle}
                           className={"data-table " + (this.props.className ? this.props.className : "")}>
                        {this.props.headers ?
                            <thead id={this.state.id + "-headers"}>
                            <tr style={this.props.fixedHeaders ? {
                                position: "sticky",
                                top: 0
                            } : null}>{this.getHeaders()}</tr>
                            </thead> : null}
                        <tbody>{rows_display}</tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export class DataTableFilters extends React.Component {
    render() {
        const filters = [];
        for(let h in this.props.headers)
            filters.push(<CheckBox label={this.props.headers[h]}
                                   className={"data-table-filter " + (h === this.props.headers.length - 1 ? "no-border" : "")}
                                   checked={this.props.filters[h]} onClick={this.props.onFilterClick.bind(null, h)}/>)
        return (
            <div id={this.props.tableId + "-data-filters"} style={{ margin: "2% 0" }}>
                {filters}
            </div>
        )
    }
}

function textContent(elem) {
    if(!elem) {
        return '';
    }
    if(typeof elem === 'string') {
        return elem;
    }
    if(elem.type === "i" && elem.props && elem.props.className && elem.props.className.indexOf('material-icons') > -1) {
        return '';
    }
    let text = (elem.props && elem.props.text) || "";
    const children = elem.props && elem.props.children;
    if(children instanceof Array) {
        return text + children.map(textContent).join('');
    }
    return text + textContent(children);
}

function sortingTest(value_1, value_2, reverse, type = 'string') {
    let compare_function = compareString;
    if(type === 'day_date') {
        compare_function = compareDayDate;
    }
    return reverse ? !compare_function(value_1, value_2) : compare_function(value_1, value_2);
}

function compareString(value_1, value_2) {
    return value_1.toLowerCase() < value_2.toLowerCase();
}

function compareDayDate(value_1, value_2) {
    return compareString(value_1.split('/').reverse().join('/'), value_2.split('/').reverse().join('/'));
}
