import { Buffer }  from 'buffer';

global.Buffer = Buffer;
window.Buffer = window.Buffer || Buffer;

import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './idGenerator';
import 'materialize-css/dist/css/materialize.css';
import 'material-icons/iconfont/material-icons.css';
import moment from 'moment';

window.moment = moment;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
