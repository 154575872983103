import generic from "./generic";
import utils from "../utils";

const self = {

    loadOwnedSubscriptionTokens: (cb = () => {}, options) => {
        generic.loadOwnedEntities('subscription_token', () => {
            let tokens = generic.getEntities('subscription_token', true);
            utils.asyncMap(tokens, (token, next) => {
                let subscription_id = token.subscription_id;
                let subscription = generic.getEntity('subscription', subscription_id);
                if (subscription) {
                    return next();
                } else {
                    generic.refreshEntity('subscription', subscription_id, next);
                }
            }, cb);
        }, options);
    }

}

export default self;