import React from "react";

/**
 * Props :  --> id : id to give to the component
 *          --> className
 *          --> content : [{content:content1,data:data1,style:style1,className:class1},{content:content2,data:data2}, ...]
 *                          items to show in the collection (content can be text or component)
 *                          style and className props are for content container
 *          --> onClick : callback(data) when an item is clicked
 *          --> clickableDisplay : if false, disable highlight and pointer cursor (default :true)
 *          --> style
 */
export default class Collection extends React.Component {
    state = { id: (this.props.id ? this.props.id : window.generateID()) };

    onClick = (item) => {
        if(this.props.onClick)
            this.props.onClick(item);
    };

    getClasses = (item) => {
        const ret = ["collection-item"];
        let hasSupplClasses = false;
        if(item && item.className) {
            ret.push(item.className);
            hasSupplClasses = true;
        }
        if(this.props.clickableDisplay !== false) {
            if(!hasSupplClasses || item.className.indexOf("selected") === -1) {
                ret.push("clickable");
            }
        }
        return ret;
    };

    render() {
        return (
            <ul id={this.state.id} className={"collection " + (this.props.className ? this.props.className : "")}
                style={this.props.style || {}}>
                {this.props.content.map((item, index) => {
                    return (
                        <li key={`list-item-${index}`} style={item.style}
                            className={this.getClasses(item).join(" ")}
                            onClick={this.onClick.bind(null, item.data ? item.data : "")}>
                            {item.content}
                        </li>
                    )
                })}
            </ul>
        );
    }
}
