import React from "react";
import _ from 'lodash';

import Media from "../../../model/Media";
import User from "../../../model/user";

import MediaSelector from "../../Common/MediaSelector";
import MediaToolbox from "./MediaToolbox";

import TagSelect from "../../Common/TagSelect";

export default class extends React.Component {

    state = {selected: [], path: '/'};

    isCtrlPressed = false;

    componentDidMount() {
        Media.onMediaUpdateSubscribe(this.refresh);
        // debounced refresh on window resize
        window.addEventListener('resize', _.debounce(this.refresh, 500));
        document.addEventListener('keydown', (event) => {
            if (event.ctrlKey) {
                this.isCtrlPressed = true;
            }
        });
        document.addEventListener('keyup', (event) => {
            if (event.ctrlKey) {
                this.isCtrlPressed = false;
            }
        });
    }

    onMediaSelectOne = (media_id) => {
        if(this.isCtrlPressed){
            this.onMediaSelect(media_id);
        }else{
            this.setState({selected: [media_id]});
        }
    };

    onMediaSelect = (media_id) => {
        let selected = this.state.selected.slice(), index = selected.indexOf(media_id);
        if (index > -1) {
            selected.splice(index, 1);
        }else{
            selected.push(media_id);
        }
        this.setState({selected: selected});
    };

    onUploadMedia = () => {
        window.openMediaUploadModal({path: this.state.path});
    };

    onShowMedia = (media_id) => {
        window.openMediaDisplayModal({media_id: media_id});
    };

    onMediaDetails = (media_id) => {
        window.openMediaDetailsModal({media_id: media_id});
    }

    onEditMedia = (media_id) => {
        window.openMediaForm({action :'edit', target_id: media_id});
    };

    onDeleteMedia = (media_id) => {
        let selected = this.state.selected, media_list = [];
        if(selected.length > 0 && selected.indexOf(media_id) > -1){
            for(let media_id of selected){
                media_list.push(Media.getMedia(media_id));
            }
        }else{
            media_list.push(Media.getMedia(media_id));
        }
        let media_names_display = media_list.map((media) => {
            return <div>{" - " + media.name}</div>
        });
        window.openConfirmActionModal({
            action_text: <div>
                <div>{"Do you really want to delete the following media ?"}</div>
                {media_names_display}
            </div>,
            confirm_cb: () => {
                if(selected.length > 1){
                    for(let media_id of selected){
                        generic.deleteEntity("media", media_id, (err) => {
                            if(err){
                                window.alertPopup('Error', 'An error has occurred while deleting the media.');
                            }
                        });
                    }
                }else{
                    generic.deleteEntity("media", media_id, (err) => {
                        if(err){
                            window.alertPopup('Error', 'An error has occurred while deleting the media.');
                        }
                    });
                }
            }
        });
    };

    onAddFolder = () => {
        window.openNameForm({title: 'Add folder', on_submit: (name) => {
            User.addMediaFolder({parent_path: this.state.path, name: name}, (err, res) => {
                if(err){
                    console.log(err);
                    window.alertPopup('Error', 'An error has occurred while creating new folder.');
                }else{
                    this.refresh();
                }
            });
        }})
    }

    onFolderSelect = (path) => {
        this.setState({path: path, selected: []});
    }

    onFolderReturn = () => {
        let path = this.state.path;
        if(path !== '/'){
            path = path.substring(0, path.lastIndexOf('/'));
            if(path === '') path = '/';
            this.onFolderSelect(path);
        }
    }

    onMoveMedia = (media_id) => {
        window.openMediaFolderSelectModal({path: this.state.path, on_submit: (target_path) => {
            let selected = this.state.selected;
            if(selected.length > 1){
                for(let media_id of selected){
                    this.moveMediaToFolder(media_id, target_path);
                }
            }else{
                this.moveMediaToFolder(media_id, target_path);
            }
        }});
    }

    onMediaDrop = (media_id, target_path) => {
        let selected = this.state.selected;
        if(selected.length > 1){
            for(let media_id of selected){
                this.moveMediaToFolder(media_id, target_path);
            }
        }else{
            this.moveMediaToFolder(media_id, target_path);
        }
    }

    moveMediaToFolder = (media_id, target_path) => {
        generic.updateEntity('media', media_id, {path: target_path}, (err, res) => {
            if(err){
                console.log(err);
                window.alertPopup('Error', 'An error has occurred while moving media.');
            }else{
                this.refresh();
            }
        });
    }

    onAddMedia = () => {
        window.openMediaForm({action :'add'});
    };

    getMediaSelectActions = () => {
        let actions = [
            {icon: 'upload', tooltip: 'Upload medias', onClick: this.onUploadMedia},
            {icon: 'create_new_folder', tooltip: 'Create new folder', onClick: this.onAddFolder}
        ];
        let email = User.auth_user.email || "";
        if(email.includes('@terminal18.org')){
            actions.unshift({icon: 'add', tooltip: 'Add media', onClick: this.onAddMedia});
        }
        if(this.state.path !== '/'){
            actions.push({icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn});
        }
        return actions;
    }

    getMediaActions = () => {
        let actions = this.state.selected.length > 1 ? [
            {icon: 'drive_file_move', tooltip: 'Move media', onClick: this.onMoveMedia},
            {icon: "delete", tooltip: 'Delete media', onClick: this.onDeleteMedia}
        ] : [
            {icon: "zoom_out_map", tooltip: 'Display media', onClick: this.onShowMedia},
            {icon: "edit", tooltip: 'Edit media', onClick: this.onEditMedia},
            {icon: 'drive_file_move', tooltip: 'Move media', onClick: this.onMoveMedia},
            {icon: 'notes', tooltip: 'Media details', onClick: this.onMediaDetails},
            {icon: "delete", tooltip: 'Delete media', onClick: this.onDeleteMedia}
        ];
        return actions;
    }

    refresh = () => {
        if (this.state.selected.length > 0) {
            let selected = this.state.selected.slice(), checked = [];
            for(let media_id of selected){
                let media = Media.getMedia(media_id);
                if(media && media.path === this.state.path){
                    checked.push(media_id);
                }
            }
            if(selected.length !== checked.length){
                this.setState({selected: checked});
            }
        } else {
            this.forceUpdate();
        }
    };

    render() {
        let medias = Media.getOwnedMedias();
        return <div className={"row" + (this.props.show ? '' : ' hide')}>
            <div className="col s12" style={{borderRight: '2px solid white', minHeight: "90px"}}>
                <MediaSelector medias={medias} selected={this.state.selected} tagSelectColor="black" textColor="white"
                               selectCheckBox={true} onSelectCheckBoxClick={this.onMediaSelect}
                               hideActionsIfNotSelected={this.state.selected.length > 1}
                               onFolderSelect={this.onFolderSelect} onMediaDrop={this.onMediaDrop}
                               onSelect={this.onMediaSelectOne} currentPath={this.state.path} largeWrapper={window.innerWidth > 1600}
                               actions={this.getMediaSelectActions()} media_actions={this.getMediaActions()}
                />
            </div>
        </div>
    }

}