import React from "react";
import _ from "lodash";
import drawing from "../../drawing";
import generic from "../../model/generic";

const entity_children = ['screen', 'link', 'object', 'multi_screen'];

const ENTITY_STYLES = {
    screen: {
        color: 'blue',
        length: 1,
        width: 0.4
    },
    multi_screen: {
        color: 'green',
        length: 1,
        width: 0.4
    },
    link: {
        color: 'red',
        length: 1,
        width: 0.4
    },
    object: {
        color: 'purple',
        length: 1,
        width: 1,
        compute_size: computeObjectSize
    }
}

const DEFAULT_PARENT_ENTITY_SIZE = {
    width: 10,
    length: 10
}

function computeObjectSize(object_id){
    let object = generic.getEntity('object', object_id);
    if(!object){
        console.log('WARNING : Object not found : ' + object_id);
    }
    return {length: _.get(object, 'metadata.length', ENTITY_STYLES.object.length), width: _.get(object, 'metadata.width', ENTITY_STYLES.object.width)};
}

export default class TopViewDisplay extends React.Component {

    state = { id: (this.props.id ? this.props.id : window.generateID()), mounted: false };

    componentDidMount = () => {
        this.setState({mounted: true}, () => {
            this.renderCanvas();
            let img = document.getElementById(this.state.id + "-image");
            img.addEventListener('load', this.renderCanvas);
            setInterval(this.checkWrapperSize, 500);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.mounted && !_.isEqual(prevProps, this.props)){
            this.renderCanvas();
        }
    };

    previous_wrapper_size = 0;

    checkWrapperSize = () => {
        let wrapper_size = this.getWrapperSize();
        if(wrapper_size !== this.previous_wrapper_size){
            this.setState({}, this.renderCanvas);
        }
        this.previous_wrapper_size = wrapper_size;
    }

    getWrapperSize = () => {
        let wrapper = document.getElementById(this.state.id + "-wrapper");
        return (wrapper && wrapper.clientWidth) || 0;
    };

    renderBackground = (ctx) => {
        let img = document.getElementById(this.state.id + "-image"),
            size = this.getWrapperSize();
        //ctx.drawImage(img, 0, 0, size, size);
        ctx.save();
        ctx.scale(1, -1);
        ctx.drawImage(img, 0, 0, size, -size);
        ctx.restore();
    };

    renderItem = (ctx, entity_type, entity_id, data, wrapper_size, is_selected, is_children) => {
        let style = ENTITY_STYLES[entity_type],
            color = style.color,
            rotation = - data.rotation.y,
            parent_width = this.props.parentWidth || DEFAULT_PARENT_ENTITY_SIZE.width,
            parent_length = this.props.parentLength || DEFAULT_PARENT_ENTITY_SIZE.length,
            x = data.position.x + parent_length / 2, y = data.position.z + parent_width / 2,
            width, length;
        if(style.compute_size){
            let size = style.compute_size(entity_id);
            width = size.width;
            length = size.length;
        }else{
            width = style.width;
            length = style.length;
            if(is_children){
                width *= 0.5;
                length *= 0.66;
                x += length / 3;
                y += width / 2;
            }
        }
        x *= wrapper_size / parent_length;
        y *= wrapper_size / parent_width;
        width *= wrapper_size / parent_length;
        length *= wrapper_size / parent_width;
        length = Math.max(Math.max(length, length * data.scale.x), length * data.scale.z);

        let x_overflow_min = x - width / 2, y_overflow_min = y - width / 2;
        if(x_overflow_min < 0){
            x -= x_overflow_min;
        }
        if(y_overflow_min < 0){
            y -= y_overflow_min;
        }
        let x_overflow_max = x + width / 2 - wrapper_size, y_overflow_max = y + width / 2 - wrapper_size;
        if(x_overflow_max > 0){
            x -= x_overflow_max;
        }
        if(y_overflow_max > 0){
            y -= y_overflow_max;
        }

        ctx.save();
        ctx.translate(x , y);
        ctx.rotate(rotation.toRad());
        if(is_selected){
            drawing.fillRectangle(ctx, {x: - (length / 2), y: - (width / 2)}, {width: length, height: width}, 'white');
            drawing.fillRectangle(ctx, {x: 2 - (length / 2), y: 2 - (width / 2)}, {width: length - 4, height: width - 4}, color);
        }else{
            //drawing.fillRectangle(ctx, {x: 0, y: 0}, {width: length, height: width}, color);
            drawing.fillRectangle(ctx, {x: - (length / 2), y: - (width / 2)}, {width: length, height: width}, color);
        }
        ctx.restore();
    };

    renderItemChildren = (ctx, wrapper_size, item_data) => {
        return;
        console.log('renderItemChildren', item_data);
        for(let entity_type of entity_children){
            if(item_data[entity_type + 's']){
                for(let child of item_data[entity_type + 's']){
                    let child_data = _ .cloneDeep(child);
                    child_data.position.x += item_data.position.x;
                    child_data.position.y += item_data.position.y;
                    child_data.position.z += item_data.position.z;
                    child_data.rotation.y += item_data.rotation.y;
                    this.renderItem(ctx, entity_type, child.id, child_data, wrapper_size, false, true);
                }
            }
        }
    }

    renderItems = (ctx, wrapper_size) => {
        for(let i = 0; i < this.props.items.length; i++){
            let item = this.props.items[i];
            this.renderItem(ctx, item.type, item.id, item.data, wrapper_size, i === this.props.selected);
            this.renderItemChildren(ctx, wrapper_size, item.data);
        }
    }

    renderCanvas = () => {
        const canvas = document.getElementById(this.state.id);
        if(!canvas){
            console.log('Error, scene top display canvas not found');
            return;
        }
        const ctx = canvas.getContext('2d'),
            wrapper_size = this.getWrapperSize();
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.renderBackground(ctx);
        this.renderItems(ctx, wrapper_size);
    };

    render() {
        let size = 0, rotation = this.props.rotation || 0, canvas_style = {position: "relative", top: 0, left: 0, backgroundColor: 'white'};
        if(rotation){
            if(rotation === 90){
                rotation = -90;
            }else if(rotation === -90){
                rotation = 90;
            }
            canvas_style.transform = 'rotate(' + rotation + 'deg)';
        }
        if(this.state.mounted){
            size = this.getWrapperSize();
        }
        return <div id={this.state.id + "-wrapper"} style={{width: this.props.width || '100%', height: this.props.height ||'100%'}}>
            <canvas id={this.state.id}
                    style={canvas_style}
                    width={size} height={size} >
            </canvas>
            <img id={this.state.id + "-image"} src={this.props.background_url} style={{display: 'none'}} />
        </div>
    }

}