import React from "react";

import ToolTipped from "./ToolTipped";

export default class InfoIcon extends React.Component {
    render() {
        return (
            <div className="inline-block" style={{ verticalAlign: "middle", marginLeft: "5px" }}>
                <ToolTipped
                    content={<i className="material-icons blue-text md-help_outline inline-block"/>}
                    position={"bottom"}
                    tooltip={this.props.infoText} tooltipClassName={"z-depth-2 info-tooltip " + (this.props.className || "")}/>
            </div>
        )
    }
}


