import model from "./model";
import Config from "../config";
import moment from "moment";
import User from './user';

const current_flash_sale_counts = {
    1: 11,
    2: 38,
    3: 29,
    4: 21
};

const price_decimals = Config.price_decimals;

const FlashSales = {

    contract: null,

    zone_flash_sales: {
        1: {
            number: 1,
            count: 0,
            price: 0,
            original_count: 0
        },
        2: {
            number: 2,
            count: 0,
            price: 0,
            original_count: 0
        },
        3: {
            number: 3,
            count: 0,
            price: 0,
            original_count: 0
        },
        4: {
            number: 4,
            count: 0,
            price: 0,
            original_count: 0
        }
    },

    public_date: moment('2023-04-03T18:00:00.000Z'),
    metaverse_opening_date: moment('2023-04-03T20:30:00.000Z'),
    //opening_date: moment('2023-03-16T19:00:00.000Z'),
    opening_date: moment('2023-03-20T19:00:00.000Z'),
    end_date: moment('2023-04-23T22:00:00.000Z'),
    //end_date: moment('2023-03-20T19:00:00.000Z'),

    initialize: (contract, cb = () => {}) => {
        return cb();
        FlashSales.contract = contract;
        FlashSales.reloadFlashSaleData().then(() => {
            console.log('Flash sales initialized');
            return cb();
        })
    },

    reloadFlashSaleData: async () => {
        return;
        const data = await FlashSales.contract.getFlashSales();
        for(let i = 0; i < 4; i++){
            let flash_sale = {
                number: parseInt(data[i].zone.toString()),
                price: (parseFloat(data[i].price.toString()) / price_decimals) + "",
                count: parseInt(data[i].count.toString()),
                original_count: current_flash_sale_counts[i + 1]
            };
            FlashSales.zone_flash_sales[i + 1] = flash_sale;
        }
        FlashSales.onFlashSaleUpdate();
    },

    getFlashSales: () => {
        return FlashSales.zone_flash_sales;
    },

    getZoneFlashSaleData: (zone_number) => {
        return FlashSales.zone_flash_sales[zone_number];
    },

    flashSaleOngoing: () => {
        for(let zone in FlashSales.zone_flash_sales){
            if(FlashSales.zone_flash_sales[zone].count > 0){
                return true;
            }
        }
        return false;
    },

    metaverseOpened: () => {
        return User.isAdmin() || moment().isSameOrAfter(FlashSales.metaverse_opening_date, 'second');
    },

    flashSaleStarted: () => {
        return User.isAdmin() || moment().isSameOrAfter(FlashSales.opening_date, 'second');
    },

    publicFlashSaleStarted: () => {
        return moment().isSameOrAfter(FlashSales.public_date, 'second');
    },

    publicFlashSaleEnded: () => {
        return moment().isSameOrAfter(FlashSales.end_date, 'second');
    },

    getFlashSaleOpeningDate: () => {
        return FlashSales.opening_date;
    },

    getFlashSaleEndDate: () => {
        return FlashSales.end_date;
    },

    getPublicFlashSaleOpeningDate: () => {
        return FlashSales.public_date;
    },

    flashSaleUpdateListenerList: [],

    onFlashSaleUpdateSubscribe: (cb) => {
        console.log('On flash sale update subscribe');
        FlashSales.flashSaleUpdateListenerList.push(cb);
    },

    onFlashSaleUpdate: () => {
        for(let cb of FlashSales.flashSaleUpdateListenerList){
            cb();
        }
    }

};

export default FlashSales;

window.FlashSales = FlashSales;