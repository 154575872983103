import React from "react";
import moment from "moment";

import Config from "../../config";

import User from "../../model/user";
import Currency from "../../model/currency";
import generic from "../../model/generic";

import InputField from "../global/InputField";
import Button from "../global/Button";
import DataTable from "../global/DataTable";

export default class extends React.Component {

    state = {};

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        if (!User.isAuthenticated()) {
            return null;
        }
        return <div id="profile-view" className={"white-text row" + (this.props.show ? '' : ' hide')} style={{ backgroundColor: 'black' }}>
            <div className="col l6 s12" style={{ height: "100%", borderRight: '1px solid white' }}>
                <AccountDisplay />
            </div>
            <div className="col l6 s12">
                {Config.enable_subscription_handling && <SubscriptionTokenDisplay />}
            </div>
        </div>

    }

}

function checkPassword(password){
    return password.match(/[a-zA-Z0-9~!@#$%^&\(\)_+\-={}\[\];\\\',.]{8}/)
        && password.match(/[a-z]{1}/)
        && password.match(/[A-Z]{1}/)
        && password.match(/[0-9]{1}/);
}


class AccountDisplay extends React.Component {

    state = {
        editing_email: false, editing_password: false, email_input: "",
        previous_password_input: "", password_input: "", confirm_password_input: ""
    };

    onEmailEditClick = () => {
        let user = User.getUser();
        this.setState({ editing_email: true, email_input: user.email || "" });
    }

    onPasswordEditClick = () => {
        this.setState({ editing_password: true });
    }

    onEmailChange = (value) => {
        this.setState({ email_input: value });
    };

    onPreviousPasswordChange = (value) => {
        this.setState({ previous_password_input: value });
    }

    onPasswordChange = (value) => {
        this.setState({ password_input: value });
    }

    onConfirmPasswordChange = (value) => {
        this.setState({ confirm_password_input: value });
    }

    onCancelEmailEditClick = () => {
        this.setState({ editing_email: false, email_input: "" });
    }

    onCancelPasswordEditClick = () => {
        this.setState({ editing_password: false, previous_password_input: "", password_input: "", confirm_password_input: "" });
    }

    onValidateEmail = () => {
        let email = this.state.email_input;
        if (!email) {
            return;
        }
        User.updateUser({ email }, (err) => {
            if (err) {
                window.alertPopup('Error', 'An error has occurred while updating your email.');
            } else {
                this.setState({ editing_email: false, email_input: "" });
            }
        });
    }

    onValidatePassword = () => {
        let previous_password = this.state.previous_password_input;
        let password = this.state.password_input;
        let user = User.getUser(), data = { pwd: password };
        if (user.has_password) {
            data.previous_pwd = previous_password;
        }
        User.changePassword(data, (err) => {
            if (err) {
                let msg = "An error has occurred while updating your password.";
                if (err.message === 'previous-password-incorrect') {
                    msg = "Your previous password is incorrect.";
                }
                window.alertPopup('Error', msg);
            } else {
                this.setState({ editing_password: false, previous_password_input: "", password_input: "", confirm_password_input: "" });
            }
        });
    }

    checkPasswordValidity = () => {
        let user = User.getUser(), password = this.state.password_input, previous_password = this.state.previous_password_input,
            confirm_password = this.state.confirm_password_input;
        let check = true;
        if (confirm_password !== password) {
            check = false;
        } else if (!checkPassword(password)) {
            check = false;
        } else if (!checkPassword(confirm_password)) {
            check = false;
        } else if (user.has_password) {
            if (!checkPassword(previous_password)) {
                check = false;
            } else if (previous_password === password) {
                check = false;
            }
        }
        return check;
    }

    render() {
        let user = User.getUser(), editing_email = this.state.editing_email, editing_password = this.state.editing_password;
        let validate_password_disabled = false;
        if (editing_password) {
            validate_password_disabled = !this.checkPasswordValidity();
        }
        return <div className="profile-view" style={{ padding: "20px" }}>
            <div className="center-align">
                <h5 className="inline-block">Account</h5>
            </div>
            <h5 style={{ wordBreak: 'break-word' }}>{"Wallet address : " + user.address}</h5>
            {!editing_email ? <h5>{"Email : " + (user.email || "None")}
                <i className="material-icons theme-color clickable" onClick={this.onEmailEditClick}
                    style={{ marginLeft: '10px', position: 'relative', top: '3px' }}>edit</i></h5> : undefined}
            {editing_email ? <div>
                <InputField label="Email" style={{ display: 'inline-block', width: '300px' }}
                    value={this.state.email_input} onChange={this.onEmailChange} />
                <i className="material-icons clickable green-text"
                    style={{ marginLeft: '10px', position: 'relative', top: '5px' }} onClick={this.onValidateEmail}>check</i>
                <i className="material-icons clickable red-text"
                    style={{ marginLeft: '10px', position: 'relative', top: '5px' }} onClick={this.onCancelEmailEditClick}>clear</i>
            </div> : undefined}
            {!editing_password ? <h5>{"Password : " + (user.has_password ? "*************" : "None")}
                <i className="material-icons theme-color clickable" onClick={this.onPasswordEditClick}
                    style={{ marginLeft: '10px', position: 'relative', top: '3px' }}>edit</i></h5> : undefined}
            {editing_password ? <div>
                {user.has_password ? <div>
                    <InputField label="Previous password" style={{ display: 'inline-block', width: '300px' }}
                        value={this.state.previous_password_input} onChange={this.onPreviousPasswordChange} type="password" />
                </div> : undefined}
                <div>
                    <InputField label="Password" style={{ display: 'inline-block', width: '300px' }}
                        value={this.state.password_input} onChange={this.onPasswordChange} type="password" />
                </div>
                <div>
                    <InputField label="Confirm Password" style={{ display: 'inline-block', width: '300px' }}
                        value={this.state.confirm_password_input} onChange={this.onConfirmPasswordChange} type="password" />
                    <i className={validate_password_disabled ? "material-icons grey-text" : "material-icons clickable green-text"}
                        style={{ marginLeft: '10px', position: 'relative', top: '5px' }} onClick={validate_password_disabled ? undefined : this.onValidatePassword}>check</i>
                    <i className="material-icons clickable red-text"
                        style={{ marginLeft: '10px', position: 'relative', top: '5px' }} onClick={this.onCancelPasswordEditClick}>clear</i>
                </div>
                {validate_password_disabled && <div className="red-text">{"Password needs at least 8 characters, 1 uppercase, 1 lowercase, 1 digit and needs to be different than previous."}</div>}
            </div> : undefined}
            {!user.email || !user.has_password ? <div className="tm">
                You need to set an email and a password to be able to connect to the Metaverse.
            </div> : null}
            {Config.enable_subscription_handling && (User.isLandOwner() || User.isAdmin()) && <CurrencyInfo user={user} />}
        </div>
    }

}

class CurrencyInfo extends React.Component {

    onCurrencyEditClick = () => {
        window.openUserCurrenciesModal({ submit_cb: this.refresh });
    };

    refresh = () => {
        this.forceUpdate();
    };

    render() {
        let user = this.props.user,
            currency_count = 0,
            user_currencies = user.accepted_currencies || {},
            currency_display = {};
        for (let network in user_currencies) {
            for (let chain_id of user_currencies[network]) {
                let currency = Currency.getCurrencyFromChainId(network, chain_id);
                if (currency) {
                    currency_display[network] = currency_display[network] || [];
                    currency_display[network].push(currency.symbol);
                    currency_count++;
                }
            }
        }
        return (
            <div className="tm">
                <h5>{"Accepted currencies : " + (currency_count === 0 ? "None" : "")}
                    <i className="material-icons theme-color clickable" onClick={this.onCurrencyEditClick}
                        style={{ marginLeft: '10px', position: 'relative', top: '3px' }}>edit</i></h5>
                {currency_count > 0 ? <div>
                    {Object.keys(currency_display).map((network) => {
                        return <div key={network}>
                            <div className="tm" style={{ fontSize: "20px", marginLeft: "20px" }}>{network + " : " + currency_display[network].join(', ')}</div>
                        </div>
                    })}
                </div> : undefined}
            </div>
        )
    }

}

class SubscriptionTokenDisplay extends React.Component {

    render() {
        let headers = ["Subscription", "Expiration"],
            rows = [],
            subscription_tokens = generic.getOwnedEntities('subscription_token');
        for (let token of subscription_tokens) {
            let subscription = generic.getEntity('subscription', token.subscription_id);
            rows.push({
                data: [
                    subscription.name + " (" + (subscription.media_id_list || []).length + " medias)",
                    moment(token.expiration_time * 1000).format('DD/MM/YYYY HH:mm')
                ]
            })
        }
        return <div style={{ padding: "20px" }}>
            <div className="center-align">
                <h5 className="inline-block">Subscription Tokens</h5>
            </div>
            <DataTable headers={{ headers }} rows={rows} />
        </div>
    }

}
