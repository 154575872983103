import generic from "./generic";

const Link = {

    loadOwnedLinks: (cb) => {
        generic.loadOwnedEntities('link', cb);
    },

    refreshLink: (link_id, cb = () => {}) => {
        generic.refreshEntity('link', link_id, cb);
    },

    getLink: (id) => {
        return generic.getEntity('link', id);
    },

    getOwnedLinks: () => {
        return generic.getOwnedEntities('link');
    },

    updateLinkTargetList: (id, target_list, cb = () => {}) => {
        let link = generic.getEntity('link', id),
            data = link.data;
        data.target_list = target_list;
        generic.updateEntity('link', id, {data: data}, cb);
    }

}

export default Link;

window.Link = Link;