import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";

export default class NameForm extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, name: "" };

    componentDidMount() {
        window.openNameForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = () => {}) => {
        let state = { opened: true, name: options.name || "", title: options.title || "Edit name",
            undismissible: options.undismissible, close_cb: close_cb, on_submit: options.on_submit };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onNameChange = (value) => {
        this.setState({name: value});
    };

    onSubmit = () => {
        this.state.on_submit(this.state.name);
        this.state.closeModal();
    };

    render() {
        let disabled = !this.state.name || this.state.name === "";
        const content = this.state.opened ? <div id="name-form-content" className="reglisse">
            <h3 style={{margin: 0}}>{this.state.title}</h3>
            <div style={{marginTop: '50px'}}>
                <InputField label={'Name'} value={this.state.name} onChange={this.onNameChange} />
            </div>
            <div className="modal-footer">
                <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{fontSize: "20px"}}/>
            </div>
        </div> : undefined;
        return (
            <Modal id="name-form"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
