import * as $ from "jquery";
import React  from "react";

/**
 * Props : --> id : id to give to the component
 *         --> options : { val1 : name1, val2 : name2 } options for the select
 *         --> label : label for the select
 *         --> placeholder : placeholder for the select
 *         --> selected : selected option
 *         --> className : custom className for the select
 *         --> style : custom styles for the select
 *         --> onChange : callback({val:selectedVal,name:selectedName}) when there is a change in selected option
 *         --> defaultSelect : the value of the option to select when component is mounted
 *         --> getReset : send the reset function (which select placeholder) to getReset
 *         --> getSelectOption : send the select option by value function to getSelectOption
 *         --> displayTop : display select options on top of it instead of under
 *         --> sort : alphanumerical sort of options name
 *         --> invert : invert options order
 */
export default class Select extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id || "select-" + window.generateID(),
            selector: null,
            selected: this.getInitialSelectedValue(props),
            opened: false
        };
    }

    getInitialSelectedValue(props) {
        let value = props.selected;
        if(!value && !props.placeholder && props.options && Object.keys(props.options).length > 0)
            value = Object.keys(props.options)[0];
        return value;
    }

    componentDidMount() {
        if(this.props.getReset)
            this.props.getReset(this.onReset);
        if(this.props.getSelectOption)
            this.props.getSelectOption(this.onSelect);
        $("body").click(this.onOutsideClick);
        this.setState({ selector: document.querySelector('#' + this.state.id) });
    }

    componentDidUpdate() {
        if(this.state.selected && !this.props.options[this.state.selected])
            this.setState({ selected: null });
    }

    onOutsideClick = (e) => {
        if(!this.state.selector.contains(e.target) && this.state.opened) {
            this.setState({ opened: false });
        }
    }
    onSelect = (option) => {
        if(this.props.onChange && option.val !== this.state.selected) {
            this.props.onChange(option);
        }
        this.setState({ selected: option.val, opened: false })
    }
    onOpen = () => {
        this.setState({ opened: !this.state.opened });
    }
    onReset = () => {
        this.setState({ selected: this.getInitialSelectedValue(this.props) });
    }

    render() {
        const selectStyle = {
            display: "inline-block",
            borderRadius: "3px",
            border: "1px solid #9e9e9e"
        };
        if(this.props.style)
            for(let s in this.props.style) {
                if(this.props.style.hasOwnProperty(s))
                    selectStyle[s] = this.props.style[s];
            }
        selectStyle.position = "relative";
        const optionsStyle = {
            display: (this.state.opened ? "inline-block" : "none"),
            position: "absolute",
            backgroundColor: "white",
            borderRadius: "3px",
            zIndex: 2,
            maxHeight: "250px",
            overflowY: "auto",
            // Prevent horizontal scrollbar (Firefox generates one for no reason...)
            overflowX: "hidden",
            left: 0
        };
        if(this.props.displayTop) {
            optionsStyle.marginBottom = "1px";
            optionsStyle.bottom = "100%";
        } else
            optionsStyle.marginTop = "1px";
        const options = [];
        let selected = "";
        if(this.props.selected || this.props.selected === 0)
            selected = this.props.options[this.props.selected];
        else if(this.state.selected)
            selected = this.props.options[this.state.selected];
        else if(this.props.placeholder)
            selected = this.props.placeholder;
        if(this.props.sort) {
            const sorted = [];
            if(this.props.sortedList){
                for(let val of this.props.sortedList){
                    if(this.props.invert){
                        sorted.unshift({val: val, name: this.props.options[val]});
                    }else{
                        sorted.push({val: val, name: this.props.options[val]});
                    }
                }
            }else{
                for(const val in this.props.options) {
                    if(this.props.options.hasOwnProperty(val)) {
                        let i = 0;
                        for(; i < sorted.length; i++) {
                            if(this.props.options[val] < sorted[i].val)
                                break;
                        }
                        sorted.splice(i, 0, { val: val, name: this.props.options[val] });
                    }
                }
            }
            for(const i in sorted) {
                if(sorted.hasOwnProperty(i)) {
                    options.push(
                        <div onClick={this.onSelect.bind(null, { val: sorted[i].val, name: sorted[i].name })}
                             className="clickable"
                             style={{ padding: "10px", borderBottom: "1px solid #d2d2d2" }}>
                            <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>{sorted[i].name}</span>
                        </div>
                    )
                }
            }
        } else {
            for(const val in this.props.options) {
                if(this.props.options.hasOwnProperty(val)) {
                    if(val === 'all' || this.props.invert)
                        options.unshift(
                            <div onClick={this.onSelect.bind(null, { val: val, name: this.props.options[val] })}
                                 className="clickable"
                                 style={{ padding: "10px", borderBottom: "1px solid #d2d2d2" }}>
                                <span style={{
                                    marginRight: "10px",
                                    whiteSpace: "nowrap"
                                }}>{this.props.options[val]}</span>
                            </div>)
                    else
                        options.push(
                            <div onClick={this.onSelect.bind(null, { val: val, name: this.props.options[val] })}
                                 className="clickable"
                                 style={{ padding: "10px", borderBottom: "1px solid #d2d2d2" }}>
                                <span style={{
                                    marginRight: "10px",
                                    whiteSpace: "nowrap"
                                }}>{this.props.options[val]}</span>
                            </div>)
                }
            }
        }
        return (
            <div id={this.state.id} className={"select " + (this.props.className ? this.props.className : "")}
                 style={selectStyle}>
                <div className="valign-wrapper clickable z-depth-1 select-header" onClick={this.onOpen}
                     style={{ position: "relative" }}>
                    <span style={{ padding: "10px", overflowX: "hidden" }}
                          className="valign bold select-header-value">{selected}</span>
                    <span className="valign">▼</span>
                </div>
                <div className="select-options z-depth-2" style={optionsStyle}>
                    {options}
                </div>
            </div>
        )
    }
}
