import React from "react";
import _ from 'lodash';

import config from '../../config';
import Media from "../../model/Media";

import IconButton from "../global/IconButton";
import MediaDisplay from "./MediaDisplay";

export default class MediaCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isDraggedOver: false};
        this.debouncedDragOver = _.debounce(this.onDebouncedDragOver, 20);
        this.debouncedDragLeave = _.debounce(this.onDebouncedDragLeave, 20);
    }

    onDragOver = (e) => {
        e.preventDefault();
        this.debouncedDragOver(e);
    };

    onDragLeave = (e) => {
        e.preventDefault();
        this.debouncedDragLeave(e);
    };

    onDebouncedDragOver = (e) => {
        if (!this.state.isDraggedOver) {
            this.setState({isDraggedOver: true});
        }
    };

    onDebouncedDragLeave = (e) => {
        if (this.state.isDraggedOver) {
            this.setState({isDraggedOver: false});
        }
    };

    onDrop = (e) => {
        e.preventDefault();
        if (this.state.isDraggedOver) {
            this.setState({isDraggedOver: false});
        }
        if (this.props.onMediaDrop) {
            const media_id = e.dataTransfer.getData("media_id");
            this.props.onMediaDrop(media_id, this.props.path);
        }
    };

    onRefresh = () => {
        this.forceUpdate();
    }

    render = () => {
        let className = "media-card folder-card z-depth-1 " + (this.props.isSelected || this.state.isDraggedOver ? ' selected': '');
        if(this.props.className){
            className += " " + this.props.className;
        }
        if(this.props.onClick){
            className += " pointer";
        }
        let media_count = 0;
        if(this.props.medias){
            media_count = _.filter(this.props.medias, (media) => {return media.path === this.props.path || _.startsWith(media.path, this.props.path + '/')}).length;
        }
        if(this.props.hideEmptyFolders && media_count === 0){
            return null;
        }
        return <div className={"col " + (this.props.largeWrapper ? "xl2 l3 m4 s6" : "l3 m4 s6")} style={{padding: "20px"}}>
            <div className={className} style={{position: "relative"}}
                 onClick={this.props.onClick} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} onDrop={this.onDrop} >
                <div>
                    <div style={{height: "200px"}} className="valign-wrapper">
                        <i className='folder-card-icon material-icons grey-text'
                           style={{margin: 'auto'}}>folder</i>
                        <span className='pink-t18-bg folder-media-counter'>{media_count}</span>
                    </div>
                    <div className="media-card-name">{this.props.name}</div>
                </div>
                {this.props.onDelete && <IconButton className="theme-color" icon="delete" style={{position: "absolute", bottom: "10px", right: "10px"}}
                                                    onClick={this.props.onDelete && this.props.onDelete.bind(null, media_id)} />}
            </div>
        </div>
    }

}