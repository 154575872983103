import React from "react";
import Land from "../../model/Land";
import model from "../../model/model";
import generic from "../../model/generic";
import FlashSales from "../../model/FlashSales";
import User from "../../model/user";
import Subscription from "../../model/Subscription";
import config from "../../config";
import web3 from "../../web3";

import Button from "../global/Button";
import IconButton from "../global/IconButton";
import CountDown from "../Common/CountDown";

import LoadingAnimation from "../global/LoadingAnimation";
import ToolTipped from "../global/ToolTipped";
import FlashSaleBanner from "./FlashSaleBanner";

import logo_t18 from '../../images/logo512.png';

export default class extends React.Component {

    render = () => {
        return <div id="land-toolbox" style={{ padding: "20px" }}>
            <h4>Land Toolbox</h4>
            <ToolBoxDisplay selected={this.props.selected} onBuySelectedLand={this.props.onBuySelectedLand} />
        </div>

    }

}

class ToolBoxDisplay extends React.Component {

    onManageLand = () => {
        window.onAppViewSelect('management');
        window.onManagementViewSelect('land');
        let land = Land.getLandFromCoordinates(this.props.selected.x, this.props.selected.y);
        window.onLandManagementViewLandSelect(land.id);
    };

    onOpenInMetaverse = () => {
        let land = Land.getLandFromCoordinates(this.props.selected.x, this.props.selected.y),
            scene_id = Land.getLandSceneId(land.id, this.onOpenInMetaverse);
        if (!scene_id) {
            if (land.linkedTo) {
                scene_id = Land.getLandSceneId(land.linkedTo, this.onOpenInMetaverse)
            } else {
                return;
            }
        }
        if (!scene_id) return;
        let url = 'https://app.terminal18.org/?id=' + scene_id;
        window.open(url, '_blank').focus();
    }

    renderOpenInMetaverseButton = () => {
        let land = Land.getLandFromCoordinates(this.props.selected.x, this.props.selected.y), show = false;
        if (FlashSales.metaverseOpened() && land.type === 'land') {
            if (land.owner || land.handlers) {
                show = true;
            } else if (land.linkedTo) {
                let linked_land = Land.getLand(land.linkedTo);
                if (linked_land.owner || linked_land.handlers) {
                    show = true;
                }
            }
        }
        return show && <div className="center-align">
            <Button style={{ fontSize: '20px', marginTop: '40px' }} className="t18-btn" text="Open In Metaverse" onClick={this.onOpenInMetaverse} />
        </div>
    }

    renderOwnerDisplay = (land) => {
        let owner = land.owner, handlers = land.handlers || [];
        if (!owner && handlers.length === 0 && land.linkedTo) {
            let linked_land = Land.getLand(land.linkedTo);
            handlers = linked_land.handlers || [];
        }
        if (!owner && handlers.length === 0) {
            return undefined;
        }
        return <div>
            {owner ? <h5><span className="bold">Owner : </span><span>{owner}</span></h5> : <h5><span className="bold">Handler : </span><span>{handlers[0]}</span></h5>}
        </div>
    }

    renderBuyDisplay = (land) => {
        if (Land.isReservedLand(land)) {
            return <div>
                <h5><span className="bold">Lands in this area are reserved for partners.</span></h5>
            </div>
        } else if (land.booked) {
            return <div>
                <h5><span className="bold">This Land has been booked.</span></h5>
            </div>
        }
        if (!FlashSales.flashSaleStarted()) {
            return <div>
                <h5>Flash Sale Early Access Starting Soon !</h5>
                <CountDown target={FlashSales.getFlashSaleOpeningDate()} onTargetReached={this.refresh} />
            </div>
        }
        if (FlashSales.publicFlashSaleEnded()) {
            return <div>
                <h5>Public Flash Sale Ended !</h5>
            </div>
        }
        if (land.owner) {
            return undefined;
        } else if (land.processing_transaction) {
            return this.renderProcessingTransaction();
        }
        let zone = model.getZoneData(land.zone),
            flash_sale = FlashSales.getZoneFlashSaleData(land.zone),
            flash_sale_ongoing = flash_sale && flash_sale.count > 0, discount = 0;
        if (zone.public_sale || flash_sale_ongoing) {
            let price = zone.price;
            if (flash_sale_ongoing) {
                price = flash_sale.price;
                discount = - Math.round((1 - parseFloat(flash_sale.price) / parseFloat(zone.price)) * 100);
                discount = Math.round(10 * Math.round(discount / 10));
            }
            return <div>
                {web3.initialized ? <h5><span className="bold">Price : </span><span>{price + " ETH" + (config.display_dollar_price ? (" (" + model.getDollarPrice(price) + "$)") : "")}</span></h5> :
                    <h5><span className="bold">Connect a wallet to see Land prices</span></h5>}
                <BuyButton onBuySelectedLand={this.props.onBuySelectedLand} />
                {flash_sale_ongoing && <span className="z-depth-1"
                    style={{
                        color: 'white', marginLeft: '15px', borderRadius: '10px', padding: '2px 5px',
                        fontSize: '28px', position: 'relative', top: '12px', backgroundColor: '#4CAF50'
                    }}>
                    {discount > 0 ? '+' : ''}{discount}%
                </span>}
                {!FlashSales.publicFlashSaleStarted() && <div className="tm">
                    <h5>Public Flash Sale Starting Soon !</h5>
                    <CountDown target={FlashSales.getPublicFlashSaleOpeningDate()} onTargetReached={this.refresh} />
                </div>}
                <div>
                    <h5>Public Flash Sale Ending Soon !</h5>
                    <CountDown target={FlashSales.getFlashSaleEndDate()} onTargetReached={this.refresh} />
                </div>
            </div>
        } else {
            return <div>
                <h5><span className="bold">Public sale not open yet for this zone</span></h5>
            </div>
        }
    }

    renderProcessingTransaction = () => {
        return <div>
            <h5>Processing transaction
                <span style={{ display: "inline-block", verticalAlign: 'middle', marginLeft: "15px" }}>
                    <LoadingAnimation type="circular" size="small" color={"red"} /></span></h5>
        </div>
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let selected, zone, template_type, misc_template_name, flash_sale_ongoing;
        if (this.props.selected) {
            selected = Land.getLandFromCoordinates(this.props.selected.x, this.props.selected.y);
            zone = model.getZoneData(selected.zone);
            template_type = selected.data.template.type;
            misc_template_name = 'road';
            if (template_type === 'park_1') {
                misc_template_name = 'park'
            } else if (template_type === 'plaza_1') {
                misc_template_name = 'plaza';
            }
        }
        return <div>{selected ? <div>
            {selected.name && <h5><span className="bold">Name : </span><span>{selected.name}</span></h5>}
            <h5><span className="bold">Zone : </span><span>{selected.zone}</span></h5>
            <h5><span className="bold">x : </span><span>{selected.position.x}</span></h5>
            <h5><span className="bold">y : </span><span>{selected.position.y}</span></h5>
            {selected.type === 'misc' ? <div>
                <h5>{"This is a " + misc_template_name}</h5>
            </div> : <div>
                <h5><span className="bold">Token ID : </span><span>{selected.tokenId ? '0x' + selected.tokenId.toString(16) : "Undefined"}</span></h5>
                {this.renderOwnerDisplay(selected)}
                {this.renderBuyDisplay(selected)}
                {this.renderOpenInMetaverseButton()}
                {User.isAdmin() ? <div className="center-align">
                    <Button style={{ fontSize: '20px', marginTop: '40px' }} className="t18-btn" text="Management" onClick={this.onManageLand} />
                </div> : undefined}
                {config.enable_subscription_handling && <SubscriptionDisplay owner={selected.owner || (selected.handlers && selected.handlers[0])} />}
            </div>}

        </div> : <div>No selected Land</div>}</div>
    }

}

class BuyButton extends React.Component {

    onHelp = () => {
        window.openEarlyAccessInfo();
    }

    render() {
        let disabled = false, tooltip = undefined;
        if (!User.isAuthenticated(true)) {
            disabled = true;
            tooltip = "You need to be authenticated with a wallet to buy a Land.";
        }
        let btn = <Button className="t18-btn" style={{ fontSize: "30px", height: "58px", padding: "10px 20px", borderRadius: "15px", marginTop: "15px" }}
            text={"Buy Land"} onClick={this.props.onBuySelectedLand} disabled={disabled} />;
        if (!disabled) {
            return btn;
        } else {
            return <span>
                <IconButton icon={"help"} onClick={this.onHelp} className="early-access-info-btn" />
                <ToolTipped style={{ display: 'inline-block' }} content={btn} tooltip={tooltip} />
            </span>
        }
    }
}

function getPayUrl(){
    return "/pay";
}

class SubscriptionDisplay extends React.Component {

    onBuyClick = (subscription) => {
        let target_url = getPayUrl() + '?s=' + subscription.id + '&t=' + User.getUserAddress(); 
        window.open(target_url, '_blank');
        window.openWaitingSubscriptionBuyModal();
    };

    onExtendClick = (subscription, subscription_token) => {
        let target_url = getPayUrl() + '?s=' + subscription.id + '&t=' + User.getUserAddress() + '&t_id=' + subscription_token.id; 
        window.open(target_url, '_blank');
        window.openWaitingSubscriptionBuyModal();
    };

    render() {
        let owner = this.props.owner,
            subscriptions = generic.getEntitiesFromProperty('subscription', 'owner', owner);
        if (subscriptions.length === 0) {
            return null;
        }
        let display = [];
        for(let subscription of subscriptions){
            let unit_time = subscription.unit_time, unit_time_text = Subscription.getUnitTimeText(unit_time);
            let subscription_token = generic.getMatchingEntity('subscription_token', {subscription_id: subscription.id, owner: User.getUserAddress()});
            display.push(<div className="col s6 reglisse" style={{ padding: "20px" }}>
                <div className={"subscription-card"}  >
                    <div className="subscription-card-name">
                        {subscription.name}
                    </div>
                    <img style={{ maxHeight: "200px", width: "100%" }} src={subscription.image_url || logo_t18} />
                    <div className="subscription-card-price ">{subscription.price + '$ / ' + unit_time_text}</div>
                    {!subscription_token && <Button className="t18-btn" text='Buy' onClick={this.onBuyClick.bind(null, subscription)} />}
                    {subscription_token && <Button className="t18-btn" text='Extend' onClick={this.onExtendClick.bind(null, subscription, subscription_token)} />}
                </div>
            </div>)
        }
        return <div>
            <h5>Subscriptions</h5>
            <div className="row">
                {display}
            </div>
        </div>
    }

}