import React  from 'react';
import _ from 'lodash';

import config from "../../config";

import User from "../../model/user";
import Currency from "../../model/currency";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import DataTable from "../global/DataTable";


export default class ModalModel extends React.Component {

  state = { opened: false, openModal: null, closeModal: null };

  componentDidMount() {
    window.openUserCurrenciesModal = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  openModal = (options = {}, close_cb = function() {}) => {
    let state = { opened: true, undismissible: options.undismissible, close_cb: close_cb, submit_cb: options.submit_cb };
    let user = User.getUser();
    state.currencies = user.accepted_currencies || {};

    this.setState(state, this.state.openModal);
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
  };

  afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
  };

  onCurrencyClick = (network, chain_id) => {
    let currencies = _.cloneDeep(this.state.currencies);
    currencies[network] = currencies[network] || [];
    if(currencies[network].indexOf(chain_id) === -1){
        currencies[network].push(chain_id);
    }else{
        currencies[network] = currencies[network].filter((c) => c !== chain_id);
    }
    this.setState({currencies: currencies});
  };

  renderCurrenciesRows = () => {
    let networks = config.contract.enabled_networks,
        currencies = Currency.getCurrencies(),
        currency_map = {},
        rows = [];
    for(let network of networks){
        currency_map[network] = [];
    }
    for(let currency_id in currencies){
        let currency = currencies[currency_id];
        if(currency_map[currency.network] !== undefined){
            currency_map[currency.network].push(currency);
        }
    }
    let max_index = 0;
    for(let network of networks){
        if(currency_map[network].length > max_index){
            max_index = currency_map[network].length;
        }
    }
    for(let i = 0; i < max_index; i++){
        let row = [];
        for(let network of networks){
            let currency = currency_map[network][i];
            if(currency){
                row.push(<CheckBox label={currency.symbol}
                    checked={this.state.currencies[network] && this.state.currencies[network].indexOf(currency.chain_id) > -1} 
                    onClick={() => {this.onCurrencyClick(network, currency.chain_id)}} />);
            }else{
                row.push('');
            }
        }
        rows.push({data: row});
    }
    return rows;
  };

  onSubmit = () => {
    User.updateAcceptedCurrencies(this.state.currencies, (err) => {
        this.state.closeModal();
        if(err){
            console.log('Error while updating accepted currencies');
            window.alertPopup('Error', 'An error has occurred while updating accepted currencies.');
        }else{
            this.state.submit_cb();
        }
    });
  };

  render() {
    let disabled = false, content;
    if(this.state.opened){
      let headers = config.contract.enabled_networks,
        rows = this.renderCurrenciesRows();
      content = <div id="user-currencies-form-content" className="reglisse">
        <h3 style={{margin: 0}}>{"Accepted currencies"}</h3>
        <div style={{fontSize: '22px'}}>
          <DataTable headers={{headers}} rows={rows} />
        </div>
        <div className="modal-footer">
          <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
          <Button text={"Cancel"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                  style={{fontSize: "20px"}}/>
        </div>
      </div>;
    }
    return (
      <Modal id="user-currencies-form"
             content={content}
             getOpen={this.getOpen}
             getClose={this.getClose}
             afterOpen={this.afterOpen}
             afterClose={this.afterClose}
             undismissible={this.state.undismissible}
      />
    )
  }
};
