import API from "../api";


const self = {

    currencies: {},

    getCurrency: (currency_id) => {
        return self.currencies[currency_id];
    },

    getCurrencyFromChainId: (network, chain_id) => {
        for(let currency_id in self.currencies){
            if(self.currencies[currency_id].network === network && self.currencies[currency_id].chain_id === chain_id){
                return self.currencies[currency_id];
            }
        }
        return null;
    },

    getCurrencies: () => {
        return self.currencies;
    },

    loadCurrencies: (cb = () => {}) => {
        API.getEntitiesRequest('currency', (data) => {
            for(let currency of data.currencies){
                self.currencies[currency.id] = currency;
            }
            cb();
        }, (err) => {
            console.log('Error while loading currencies : ' + JSON.stringify(err));
            cb(err);
        });
    }

}

export default self;