import generic from "./generic";
import API from "../api";

const Scene = {

    loadOwnedScenes: (cb) => {
        generic.loadOwnedEntities('scene', cb);
    },

    loadAllScenes: (cb) => {
        generic.loadEntities('scene', cb);
    },

    refreshScene: (scene_id, cb = () => {}) => {
        generic.refreshEntity('scene', scene_id, cb);
    },

    getScene: (id) => {
        return generic.getEntity('scene', id);
    },

    getOwnedScenes: () => {
        return generic.getOwnedEntities('scene');
    },

    getSceneEntityData: (scene_id, entity_type, entity_id) => {
        return generic.getTargetEntityData('scene', scene_id, entity_type, entity_id);
    },

    userUpdateScene: (scene_id, update_data, successcb, errorcb) => {
        generic.updateEntity('scene', scene_id, update_data, (err) => {
            if(err){
                return errorcb(err);
            }else{
                return successcb();
            }
        });
    },

    updateSceneTemplate: (scene_id, template_id, successcb, errorcb) => {
        let data = {};
        API.updateSceneTemplate(scene_id, template_id, data, (data) => {
            Scene.refreshScene(scene_id, successcb);
        }, errorcb);
    },

    getSceneExitLink: (scene_id) => {
        let scene = Scene.getScene(scene_id), links = _.get(scene, 'data.links', []);
        for(let data_link of links){
            let link = generic.getEntity('link', data_link.id);
            if(!link){
                generic.refreshEntity('link', data_link.id);
            }else{
                let target_list = _.get(link, 'data.target_list', []);
                if(_.find(target_list, {type: 'land'})){
                    return link;
                }
            }
        }
    },

    addSceneFloor: (target_scene_id, name, template_id, cb) => {
        let data = {scene_name: name};
        API.addSceneFloor(target_scene_id, template_id, data, (res) => {
            let new_scene_id = res.scene_id, exit_link_id = res.exit_link_id;
            generic.refreshEntity('scene', new_scene_id, () => {
                generic.refreshEntity('link', exit_link_id, () => {
                    cb();
                });
            });
        }, cb);
    },

    removeScene: (scene_id, cb) => {
        generic.deleteEntity('scene', scene_id, cb);
    }

}

export default Scene;