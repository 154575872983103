import React from "react";
import _ from "lodash";
import config from "../../config";
import Land from "../../model/Land";
import User from "../../model/user";

import images from "../../images";
import drawing from "../../drawing";

const MAP_CONTAINER_SIZE = config.MOBILE_DISPLAY ? 340 : 738;

const CELL_SIZE = 16;

const ROW_CELL_COUNT = config.map_size;

const ZONE_DATA = [
    {
        number: 4,
        start: 0,
        color: '#676767'
    },
    {
        number: 3,
        start: 18,
        color: '#ac8295'
    },
    {
        number: 2,
        start: 36,
        color: '#b4597d'
    },
    {
        number: 1,
        start: 54,
        color: '#cd507c'
    },
    {
        number: 0,
        start: 65,
        color: '#ff5252'
    }
];

function getZoneColor(zone){
    return _.find(ZONE_DATA, {number: zone}).color;
}

const arena_bounds = {
    nw: { x: 37, y: -43 },
    se: { x: 43, y: -38 }
};

const main_plaza_bounds = {
    nw: { x: -1, y: -1 },
    se: { x: 1, y: 1 }
};

export default class extends React.Component {

    state = { zoom: 0.3, draw_roads: true };

    componentDidMount = () => {
        window.refreshMap = this.drawMap;
        //window.addEventListener('scroll', this.onScroll, true);
        this.drawMap();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selected !== this.props.selected) {
            this.drawMap();
        }
    }

    refresh = () => {
        console.log('refresh map');
        this.forceUpdate();
    };

    current_zoom = 0.3;

    map_x = 0;

    map_y = 0;

    dragging = false;

    previousX = null;

    previousY = null;

    ignoreCellClick = false;

    ignoreCellClickTimeout = null;

    onDragStart = () => {
        this.dragging = true;
        this.previousX = null;
        this.previousY = null;
    };

    onDragStop = () => {
        this.dragging = false;
        setTimeout(() => {
            this.ignoreCellClick = false;
        }, 100);
    };

    onClick = (e) => {
        if (!this.ignoreCellClick) {
            let x = e.nativeEvent.layerX, y = e.nativeEvent.layerY;
            let cell = this.convertMapToLandCoordinates(x, y);
            this.props.onSelect(cell.x, cell.y);
        }
    };

    convertMapToLandCoordinates = (x, y) => {
        let offset = Math.floor(ROW_CELL_COUNT / 2), mult = config.browser === 'Firefox' ? 1 : -1;
        let cell_size = Math.round(this.current_zoom * CELL_SIZE);
        return {
            x: Math.floor(x / cell_size) - offset,
            y: mult * (Math.floor(y / cell_size) - offset)
        }
    };

    onMouseMove = (e) => {
        if (this.dragging) {
            this.ignoreCellClick = true;
            let x = e.pageX, y = e.pageY;
            if (this.previousX != null) {
                let dx = x - this.previousX, dy = y - this.previousY;
                this.moveMapCenter(dx, dy);
            }
            this.previousX = x;
            this.previousY = y;
        }
    };

    onScroll = (e) => {
        if (e.deltaY > 0) {
            this.zoomOut();
        } else if (e.deltaY < 0) {
            this.zoomIn();
        }
    };

    moveMapCenter = (dx, dy) => {
        let new_x = Math.min(0, this.map_x + dx), new_y = Math.min(0, this.map_y + dy);
        let cell_size = Math.round(this.current_zoom * CELL_SIZE);
        let bottom_right_limit = - (cell_size * ROW_CELL_COUNT - MAP_CONTAINER_SIZE + 3);
        new_x = Math.max(new_x, bottom_right_limit);
        new_y = Math.max(new_y, bottom_right_limit);
        if (new_x === this.map_x && new_y === this.map_y) return;
        let map = document.getElementById('land-map');
        map.style.top = new_y + "px";
        map.style.left = new_x + "px";
        this.map_x = new_x;
        this.map_y = new_y;
    };

    zoomIn = () => {
        let d_z = 0.2, new_zoom = Math.min(2, this.current_zoom + d_z);
        d_z = this.current_zoom - new_zoom;
        if (d_z !== 0) {
            this.setZoom(new_zoom);
        }
    };

    zoomOut = () => {
        let d_z = - 0.2, new_zoom = Math.max(0.3, this.current_zoom + d_z);
        d_z = this.current_zoom - new_zoom;
        if (d_z !== 0) {
            this.setZoom(new_zoom);
        }
    };

    setZoom = (zoom) => {
        if (this.zooming) return;
        this.zooming = true;
        let previous_map_size = Math.round(this.current_zoom * CELL_SIZE) * ROW_CELL_COUNT;
        this.current_zoom = zoom;
        let map_size = Math.round(this.current_zoom * CELL_SIZE) * ROW_CELL_COUNT;
        let map = document.getElementById('land-map');
        map.width = map_size;
        map.height = map_size;
        this.drawMap();
        let d_z = (previous_map_size - map_size) / 2;
        this.moveMapCenter(d_z, d_z);
        setTimeout(() => {
            this.zooming = false;
        }, 50);
    };

    fillMapZones = (ctx) => {
        let cell_size = Math.round(this.current_zoom * CELL_SIZE), map_size = cell_size * ROW_CELL_COUNT;
        for (let zone of ZONE_DATA) {
            let offset = zone.start * cell_size,
                from = { x: offset, y: offset },
                size = map_size - 2 * offset;
            drawing.fillRectangle(ctx, from, { width: size, height: size }, zone.color);
        }
    };

    fillMapCell = (ctx, land, linked) => {
        let cell_size = Math.round(this.current_zoom * CELL_SIZE), map_size = cell_size * ROW_CELL_COUNT, draw_roads = this.state.draw_roads;
        let x = land.position.x, y = land.position.y, template = _.get(land, 'data.template.type');
        let selected = this.props.selected, is_selected = selected && selected.x === x && selected.y === y, half_map_size = (map_size - cell_size) / 2;
        let cell_x = half_map_size + x * cell_size, cell_y = half_map_size + y * cell_size;
        let color = 'white';
        let owner = land.owner, handlers = land.handlers || [];
        if (!owner && handlers.length === 0 && land.linkedTo) {
            let linked_land = Land.getLand(land.linkedTo);
            handlers = linked_land.handlers || [];
        }
        if (is_selected) {
            color = 'red';
        } else if (owner || handlers.length > 0) {
            color = 'black';
            if (owner) {
                if (owner.toLowerCase() === '0x159d4d6a45a94035389b4652dfa4d82c30484273') {
                    color = "red";
                } else {
                    color = "#" + owner.substring(2, 8);
                }
            } else if (handlers.length > 0) {
                color = "#" + handlers[0].substring(2, 8);
            }
        } else if (land.type === 'misc') {
            color = 'white';
            if (x >= arena_bounds.nw.x && x <= arena_bounds.se.x && y >= arena_bounds.nw.y && y <= arena_bounds.se.y) {
                color = 'purple';
            } else if (x >= main_plaza_bounds.nw.x && x <= main_plaza_bounds.se.x && y >= main_plaza_bounds.nw.y && y <= main_plaza_bounds.se.y) {
                color = 'blue';
            } else if (template === 'park_1') {
                color = '#64dd17';
            } else if (template === 'plaza_1') {
                color = 'blue';
            }
        } else if(!linked){
            return;
        } else {
            // color = getZoneColor(land.zone);
        }
        if (land.type === 'misc' && draw_roads && template && template.indexOf('road') > -1) {
            if (is_selected) {
                drawing.fillRectangle(ctx, { x: cell_x - 2, y: cell_y - 2 }, { width: cell_size + 4, height: cell_size + 4 }, 'white');
            }
            let rotation = _.get(land, 'data.template.rotation', 0);
            drawing.drawTemplate(ctx, template, rotation, cell_x, cell_y, cell_size);
        } else if (is_selected) {
            drawing.fillRectangle(ctx, { x: cell_x, y: cell_y }, { width: cell_size, height: cell_size }, 'white');
            drawing.fillRectangle(ctx, { x: cell_x + 2, y: cell_y + 2 }, { width: cell_size - 4, height: cell_size - 4 }, color);
        } else {
            if(linked){
                drawing.fillRectangle(ctx, { x: cell_x, y: cell_y }, { width: cell_size, height: cell_size }, 'white');
                drawing.fillRectangle(ctx, { x: cell_x + 2, y: cell_y + 2 }, { width: cell_size - 4, height: cell_size - 4 }, color);
            }else{
                drawing.fillRectangle(ctx, { x: cell_x, y: cell_y }, { width: cell_size, height: cell_size }, color);
            }
        }
    };

    fillMapCells = (ctx) => {
        let selected = this.props.selected, selected_land;
        for (let land of Land.getLands(true)) {
            let x = land.position.x, y = land.position.y, is_selected = selected && selected.x === x && selected.y === y;
            if (is_selected) {
                selected_land = land;
            } else {
                this.fillMapCell(ctx, land);
            }
        }
        if (selected_land) {
            this.fillMapCell(ctx, selected_land);
            if(User.isAdmin()){
                let linked_lands = Land.getLinkedLands(selected_land.id);
                for (let linked_land of linked_lands) {
                    this.fillMapCell(ctx, linked_land, true);
                }
            }
        }
    };

    drawMapLines = (ctx) => {
        let cell_size = Math.round(this.current_zoom * CELL_SIZE), map_size = cell_size * ROW_CELL_COUNT;
        for (let y = 0; y <= ROW_CELL_COUNT; y++) {
            drawing.drawLine(ctx, { y: y * cell_size, x: 0 }, { y: y * cell_size, x: map_size }, 'grey');
        }
        for (let x = 0; x <= ROW_CELL_COUNT; x++) {
            drawing.drawLine(ctx, { x: x * cell_size, y: 0 }, { x: x * cell_size, y: map_size }, 'grey');
        }
    };

    firstDraw = true;

    drawMap = () => {
        const canvas = document.getElementById('land-map');
        if (!canvas) {
            console.log('Error, map canvas not found');
            return;
        }
        const ctx = canvas.getContext('2d');
        this.fillMapZones(ctx);
        this.fillMapCells(ctx);
        this.drawMapLines(ctx);
    };

    render = () => {
        let template_images = [];
        for (let i = 1; i <= 14; i++) {
            template_images.push(<img id={"template_road_" + i} src={images.getMapTemplateImage('road_' + i)} style={{ display: 'none' }} />);
        }
        let cell_size = Math.round(this.current_zoom * CELL_SIZE), map_size = cell_size * ROW_CELL_COUNT;
        return <div style={{ margin: "20px", textAlign: 'center' }}>
            <div id="land-map-container" style={map_container_style} >
                <canvas id="land-map"
                    style={{ position: "relative", top: 0, left: 0, backgroundColor: 'white', cursor: 'pointer' }}
                    width={map_size} height={map_size}
                    onClick={this.onClick}
                    onMouseDown={this.onDragStart}
                    onMouseUp={this.onDragStop}
                    onMouseLeave={this.onDragStop}
                    onWheel={this.onScroll}
                    onMouseMove={this.onMouseMove}  >
                </canvas>
            </div>
            <div id="land-map-zoom-container" className="z-depth-2">
                <div className="land-map-zoom-button clickable bold" onClick={this.zoomIn}
                    style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>+</div>
                <div className="land-map-zoom-button clickable bold" onClick={this.zoomOut}
                    style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", borderTop: "1px solid #e0e0e0" }}>-</div>
            </div>
            {template_images}
        </div>
    }

}

const map_container_style = {
    width: MAP_CONTAINER_SIZE + "px",
    height: MAP_CONTAINER_SIZE + "px",
    border: "1px solid white",
};

if (config.MOBILE_DISPLAY) {
    map_container_style.overflow = 'auto';
    map_container_style.width = 'calc(100% - 40px)';
}