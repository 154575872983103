import React  from 'react';

import Land from "../../model/Land";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";

export default class LandNameForm extends React.Component {

  state = { opened: false, openModal: null, closeModal: null, name: "" };

  componentDidMount() {
    window.openLandNameForm = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  openModal = (options, close_cb = function() {}) => {
    let land = Land.getLand(options.land_id);
    let state = { opened: true, land_id: options.land_id, name: land.name || "", undismissible: options.undismissible, close_cb: close_cb };

    this.setState(state, this.state.openModal);
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
  };

  afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
  };

  onNameChange = (value) => {
    this.setState({name: value});
  };

  onSubmit = () => {
    Land.userUpdateLand(this.state.land_id, {name: this.state.name}, this.state.closeModal, () => {
      this.state.closeModal();
      window.alertPopup('Error', 'An error has occurred while updating Land name.');
    });
  };

  render() {
    let disabled = !this.state.name || this.state.name === "";
    const content = this.state.opened ? <div id="land-name-form-content" className="reglisse">
      <h3 style={{margin: 0}}>{"Edit Land Name"}</h3>
      <div style={{marginTop: '50px'}}>
        <InputField label={'Land Name'} value={this.state.name} onChange={this.onNameChange} />
      </div>
      <div className="modal-footer">
        <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
        <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                style={{fontSize: "20px"}}/>
      </div>
    </div> : undefined;
    return (
      <Modal id="land-name-form"
             content={content}
             getOpen={this.getOpen}
             getClose={this.getClose}
             afterOpen={this.afterOpen}
             afterClose={this.afterClose}
             undismissible={this.state.undismissible}
      />
    )
  }
};
