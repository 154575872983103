import React from "react";

import generic from "../../model/generic";

import LoadingAnimation from "../global/LoadingAnimation";


export default class extends React.Component {

    componentDidMount() {
        if(this.props.entity && this.props.id){
            generic.refreshEntity(this.props.entity, this.props.id, this.props.onLoad);
        }
    }

    render() {
        return (
            <div className="center-align">
                <LoadingAnimation type='circular' />
            </div>
        )
    }

}