import API from "../api";
import User from "./user";
import Dev from './Dev';
import Scene from './Scene';
import generic from "./generic";

const LAND_LAST_LOADING_TS = 'lands-last-loading-ts';

const CACHED_LANDS = 'cached-lands';

const ENABLE_CACHE = false;

let model;

const Land = {

    lands: {},

    initialize: (model_ref, zones, cb = () => {}) => {
        model = model_ref;
        let cached_data = Land.getCachedLands();
        if(cached_data){
            model.lands = cached_data;
        }
        let req_ts = Date.now();
        Land.loadServerLandsData((err, lands) => {
            if(err){
                return cb(err);
            }else{
                for(let land of lands){
                    generic.onEntityDataLoad('land', land.id, land, false);
                }
                Land.setLastLandsLoadingTimestamp(req_ts);
                Land.cacheLands();
                generic.onEntityDataUpdate('land');
                return cb();
            }
        })
    },

    loadServerLandsData: (cb = () => {}) => {
        let options = {
            modified_since: Land.getLastLandsLoadingTimestamp(),
            without: ['data.objects', 'data.links', 'data.screens', 'creation_date', 'last_modified']
        };
        API.getLandsRequest(options, (data) => {
            return cb(null, data.lands);
        }, (err) => {
            console.log('ERROR WHILE LOADING LANDS : ' + JSON.stringify(err));
            return cb(err);
        });
    },

    loadLandScene: (land_id, cb = () => {}) => {
        let land = Land.getLand(land_id);
        if(!land){
            return cb('Land not found');
        }

    },

    refreshLand: (land_id, cb = () => {}) => {
        generic.refreshEntity('land', land_id, cb);
    },

    getLandZoneFromCoordinates: (land) => {
        let zone_limits = [17, 35, 53], zone_index;
        for(zone_index = 0; zone_index < zone_limits.length; zone_index++){
            let limit = zone_limits[zone_index];
            if(Math.abs(land.position.x) <= limit && Math.abs(land.position.y) <= limit){
                break;
            }
        }
        return zone_index + 1;
    },

    getLastLandsLoadingTimestamp: () => {
        if(!ENABLE_CACHE) return;
        let ts = localStorage.getItem(LAND_LAST_LOADING_TS);
        if(ts){
            return ts;
        }else{
            return undefined;
        }
    },

    setLastLandsLoadingTimestamp: (ts) => {
        if(!ENABLE_CACHE) return;
        localStorage.setItem(LAND_LAST_LOADING_TS, ts);
    },

    getCachedLands: () => {
        if(!ENABLE_CACHE) return;
        let cache = localStorage.getItem(CACHED_LANDS);
        if(cache){
            try{
                let lands = JSON.parse(cache), result = {};
                for(let land of lands){
                    result[land.id] = land;
                }
                return result;
            }catch(err){
                console.log('Error while parsing cached lands data');
                Land.resetLandsCache();
                return undefined;
            }
        }else{
            return undefined;
        }
    },

    cacheLands: () => {
        if(!ENABLE_CACHE) return;
        localStorage.setItem(CACHED_LANDS, JSON.stringify(Object.values(model.lands)));
    },

    resetLandsCache: () => {
        if(!ENABLE_CACHE) return;
        localStorage.setItem(LAND_LAST_LOADING_TS, '');
        localStorage.setItem(CACHED_LANDS, '');
    },

    getLand: (id) => {
        return generic.getEntity('land', id);
    },

    getLands: (as_array) => {
        return generic.getEntities('land', as_array);
    },

    getLandFromTokenId: (tokenId) => {
        return generic.getEntityFromProperty('land', 'tokenId', tokenId);
    },

    getLandFromCoordinates: (x, y) => {
        return generic.getEntityFromProperty('land', 'position', {x, y});
    },

    getOwnedLands: () => {
        if(User.isAdmin()){
            return generic.getEntitiesFromFilter('land', (land) => {
                return land.handlers && land.handlers.length > 0;
            });
        }else{
            let address = User.getUserAddress();
            console.log(address);
            return generic.getEntitiesFromFilter('land', (land) => {
                return address && (land.owner === address || (land.handlers && land.handlers.indexOf(address) !== -1));
            });
        }
    },

    getLinkedLands: (land_id) => {
        let land = Land.getLand(land_id),
            linked = [];
        if(land.linkedWith){
            for(let linked_id of land.linkedWith){
                let linked_land = Land.getLand(linked_id);
                if(linked_land){
                    linked.push(linked_land);
                }
            }
        }else if(land.linkedTo){
            let master_land = Land.getLand(land.linkedTo);
            linked.push(master_land);
            for(let linked_id of master_land.linkedWith){
                if(linked_id !== land_id){
                    let linked_land = Land.getLand(linked_id);
                    if(linked_land){
                        linked.push(linked_land);
                    }
                }
            }
        }
        return linked;
    },

    // TO DO : remove this function
    getLandData: (land_id) => {
        let land = Land.getLand(land_id);
        if(!land.tmp_data){
            Dev.initializeLandData(land);
        }
        return land.tmp_data;
    },

    getLandsFromZone: (zone_number) => {
        return generic.getEntitiesFromProperty('land', 'zone', zone_number);
    },

    isReservedLand: (land) => {
        let x = land.position.x, y = land.position.y;
        return Math.abs(x) <= 7 && Math.abs(y) <= 7;
    },

    userUpdateLand: (land_id, update_data, successcb, errorcb) => {
        generic.updateEntity('land', land_id, update_data, (err) => {
            if(err){
                return errorcb(err);
            }else{
                return successcb();
            }
        });
    },

    initializeLand: (land_id, land_data, scene_data, successcb, errorcb) => {
        let data = {
            land_name: land_data.name,
            land_template_id: land_data.template_id,
            land_rotation: land_data.rotation,
            scene_name: scene_data.name,
            scene_template_id: scene_data.template_id
        };
        API.initializeLand(land_id, data, (data) => {
            Scene.refreshScene(data.scene_id, () => {
                Land.refreshLand(land_id, successcb);
            })
        }, errorcb);
    },

    onLandUpdate: (land_id, land_data) => {
        generic.onEntityDataUpdate('land', land_id, land_data);
    },

    onLandUpdateSubscribe: (cb) => {
        generic.addEntityListener('land', cb);
    },

    getLandEntityData: (land_id, entity_type, entity_id) => {
        return generic.getTargetEntityData('land', land_id, entity_type, entity_id);
    },

    updateLandTemplate: (land_id, template_id, rotation, position_offset, successcb, errorcb) => {
        let data = {
            land_rotation: rotation,
            position_offset: position_offset
        };
        API.updateLandTemplate(land_id, template_id, data, (data) => {
            Land.refreshLand(land_id, successcb);
        }, errorcb);
    },

    getLandSceneId: (land_id, on_link_load = () => {}) => {
        let land = Land.getLand(land_id),
            building_object = _.find(land.data.objects, {type: 'building'});
        let links = building_object ? building_object.links || [] : _.get(land, 'data.links', []);
        if(links.length > 0){
            let link_id = links[0].id, link = generic.getEntity('link', link_id);
            if(link){
                return link.data.target_list[0].id;
            }else{
                generic.refreshEntity('link', link_id, on_link_load);
            }
        }
        return null;
    },

    userUpdateLandType: (land_id, type, successcb, errorcb) => {
        API.updateAdminLandType(land_id, type, () => {
            let land = Land.getLand(land_id);
            land.type = type;
            Land.onLandUpdate();
            successcb();
        }, errorcb);
    },

    updateLandTemplateType: (land_id, template_type, successcb, errorcb) => {
        API.updateAdminLandTemplate(land_id, {type: template_type}, () => {
            let land = Land.getLand(land_id);
            land.data.template.type = template_type;
            Land.onLandUpdate();
            successcb();
        }, errorcb);
    },

    updateLandTemplateRotation: (land_id, rotation, successcb, errorcb) => {
        API.updateAdminLandTemplate(land_id, {rotation: rotation}, () => {
            let land = Land.getLand(land_id);
            land.data.template.rotation = rotation;
            Land.onLandUpdate();
            successcb();
        }, errorcb);
    },

};

window.printLandZoneCounts = () => {
    let counts = {};
    for(let id in model.land){
        let land = model.land[id];
        if(land.type === 'land'){
            let zone = Land.getLandZoneFromCoordinates(land);
            if(zone === 1){
                if(Math.abs(land.position.x) <= 7 && Math.abs(land.position.y) <= 7){
                    zone = '1a';
                }else{
                    zone = '1b';
                }
            }
            counts[zone] = (counts[zone] || 0) + 1;
        }
    }
    console.log(JSON.stringify(counts));
};

export default Land;

window.Land = Land;