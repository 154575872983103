import React from "react";

import generic from "../../../model/generic";
import Subscription from "../../../model/Subscription";

import Button from "../../global/Button";

import logo_t18 from '../../../images/logo512.png';

export default class extends React.Component {

    state = {};

    componentDidMount = () => {
        
    };

    onCreate = () => {
        window.openSubscriptionForm();
    };

    onEdit = () => {
        window.openSubscriptionForm({action: 'edit', subscription_id: this.props.selected});
    };

    refresh = () => {
        this.forceUpdate();
    };

    render() {
        let subscriptions = generic.getOwnedEntities('subscription');
        return <div>
            <div className="center-align white-text">
                <h4 className="inline-block">Subscriptions</h4>
            </div>
            <div className="center-align">
                <Button className="t18-btn tm rm" text="Create" onClick={this.onCreate} style={{fontSize: '24px'}} />
                {this.props.selected && <Button className="t18-btn tm lm" text="Edit" onClick={this.onEdit} style={{fontSize: '24px'}} />}
            </div>
            {
                subscriptions.length === 0 ? <div className="center-align" style={{marginTop: '50px'}}>
                        <h4>No subscriptions</h4>
                    </div> : <SubscriptionSelector subscriptions={subscriptions} selected={this.props.selected} onSelect={this.props.onSelect} />
            }
        </div>
    }
}

class SubscriptionSelector extends React.Component {

    renderSubscription = (subscription) => {
        let is_selected = this.props.selected === subscription.id;
        let unit_time = subscription.unit_time, unit_time_text = Subscription.getUnitTimeText(unit_time);
        return <div className="col s6 reglisse" style={{padding: "20px"}}>
        <div className={"subscription-card" + (is_selected ? ' selected': '')} 
            onClick={this.props.onSelect.bind(null, subscription.id)} >
          <div className="subscription-card-name">
            {subscription.name}
          </div>
          <img style={{maxHeight: "200px", width: "100%"}} src={subscription.image_url || logo_t18} />
          <div className="subscription-card-price ">{subscription.price + '$ / ' + unit_time_text}</div>
        </div>
      </div>
    };

    render() {
        return <div className="row">
            {
                this.props.subscriptions.map((subscription, index) => {
                    return this.renderSubscription(subscription)
                })
            }
        </div>
    }

}