import React from 'react';

const ContextMenu = ({ x, y, items, closeMenu }) => {
    const menuStyle = {
        position: 'absolute',
        top: y,
        left: x,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        padding: '8px',
        zIndex: 999,
    };

    return (
        <div style={menuStyle} onClick={closeMenu}>
            <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                {items.map((item, index) => (
                    <li key={index} onClick={item.onClick}>
                        {item.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ContextMenu;
