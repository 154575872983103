

let mediainfo;
MediaInfo(
    {
        format: 'text',
        locateFile: (path, prefix) => prefix + path, // Make sure WASM file is loaded from CDN location
    },
    (m) => {
        mediainfo = m;
        fileinput.removeAttribute('disabled')
        fileinput.addEventListener('change', () => onChangeFile(mediainfo))
    })

function getMediaOutput(file, cb){
    const getSize = () => file.size

    const readChunk = (chunkSize, offset) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event.target.error) {
                    reject(event.target.error)
                }
                resolve(new Uint8Array(event.target.result))
            }
            reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
        })

    mediainfo
        .analyzeData(getSize, readChunk)
        .then((result) => {
            cb(null, result);
        })
        .catch((error) => {
            cb(error);
        })
}

function parseDurationIntoSeconds(duration){
    let parts = duration.replace(' h ', ':').replace(' min ', ':').replace(' s', '').split(':');
    let seconds = 0;
    for(let i = parts.length - 1; i >= 0; i--){
        seconds += parseInt(parts[i]) * Math.pow(60, parts.length - 1 - i);
    }
    return seconds;
}

function parsePixelsString(size){
    return parseInt(size.replace(' pixels', '').replace(' ', ''));
}

function parseMetadata(result){
    try{
        let metadata = {raw: {}}, raw_target = 'Unknown';
        let lines = result.split("\n");
        for(let line of lines){
            let parts = line.split(": ");
            if(parts.length === 2){
                let key = parts[0];
                while(key[key.length - 1] === ' '){
                    key = key.slice(0, -1);
                }
                metadata.raw[raw_target][key] = parts[1];
                switch(key){
                    case 'Duration':
                        metadata.duration = parseDurationIntoSeconds(parts[1]);
                        break;
                    case 'Width':
                        metadata.width = parsePixelsString(parts[1]);
                        break;
                    case 'Height':
                        metadata.height = parsePixelsString(parts[1]);
                        break;
                }
            }else{
                raw_target = line;
                metadata.raw[raw_target] = {};
            }
        }
        delete metadata.raw;
        return {metadata};
    }catch(err){
        console.error(err);
        return {err};
    }
}

const self = {

    getMediaFileMetadata: (file, cb) => {
        getMediaOutput(file, (err, result) => {
           if(err){
                cb(err);
           }else{
               let {err, metadata} = parseMetadata(result);
               return cb(err, metadata);
           }
        });
    }

}

export default self;