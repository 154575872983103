import React  from 'react';
import _ from 'lodash';

import Scene from "../../model/Scene";

import Modal from "../global/Modal";
import Button from "../global/Button";
import SceneTemplateEditor from "../Common/SceneTemplateEditor";
import Templates from "../../model/Templates";


export default class LandIndoorTemplateForm extends React.Component {

  state = { opened: false, openModal: null, closeModal: null };

  componentDidMount() {
    window.openLandIndoorTemplateForm = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  openModal = (options, close_cb = function() {}) => {
    let state = {scene_id: options.scene_id, scene_template_id: Templates.getDefaultSceneTemplate(),
      opened: true, undismissible: options.undismissible, close_cb: close_cb };
    let scene = Scene.getScene(options.scene_id);
    state.scene_template_id = scene.scene_template_id;
    this.setState(state, this.state.openModal);
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
  };

  afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
  };

  onSceneTemplateSelect = (template_id) => {
    this.setState({scene_template_id: template_id});
  };

  onSubmit = () => {
    Scene.updateSceneTemplate(this.state.scene_id, this.state.scene_template_id, this.state.closeModal, () => {
      this.state.closeModal();
      window.alertPopup('Error', 'An error has occurred while updating Indoor scene template.');
    })
  };

  render() {
    let disabled = false, content;
    if(this.state.opened) {
      let scene = Scene.getScene(this.state.scene_id), template_id = this.state.scene_template_id;
      disabled = template_id === scene.scene_template_id;
      content = <div id="land-indoor-template-form-content" className="reglisse">
        <h3 style={{ margin: 0 }}>{"Indoor scene template"}</h3>
        <div style={{marginTop: '50px'}}>
          <SceneTemplateEditor selected={template_id} onTemplateSelect={this.onSceneTemplateSelect} />
        </div>
        <div className="modal-footer">
          <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled}
                  style={{ fontSize: "20px" }}/>
          <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                  style={{ fontSize: "20px" }}/>
        </div>
      </div>;
    }
    return (
        <Modal id="land-indoor-template-form"
               content={content}
               getOpen={this.getOpen}
               getClose={this.getClose}
               afterOpen={this.afterOpen}
               afterClose={this.afterClose}
               undismissible={this.state.undismissible}
        />
    )
  }
};