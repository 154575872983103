import React from "react";

import Templates from "../../model/Templates";
import Button from "../global/Button";
import logo_t18 from "../../images/logo_t18.png";
import Land from "../../model/Land";
import User from "../../model/user";

import utils from "../../utils";

import LandPreviewMap from "../../../../shared/components/common/LandPreviewMap";

export default class LandTemplateEditor extends React.Component {

    onPreviousTemplate = () => {
        let selected = this.props.selected, 
            templates = Templates.getLandTemplates();
        if(!User.isAdmin()){
            templates = _.filter(templates, (template) => {
                return !template.admin;
            });
        }
        let template_id_list = Object.keys(templates), 
            index = template_id_list.indexOf(selected);
        index--;
        if(index < 0){
            index = template_id_list.length - 1;
        }
        this.props.onTemplateSelect(template_id_list[index]);
    };

    onNextTemplate = () => {
        let selected = this.props.selected, templates = Templates.getLandTemplates(),
            template_id_list = Object.keys(templates), index = template_id_list.indexOf(selected);
        index++;
        if(index >= template_id_list.length){
            index = 0;
        }
        this.props.onTemplateSelect(template_id_list[index]);
    };

    onRotateLeft = () => {
        let rotation = this.props.rotation;
        if(rotation === 0){
            rotation = 90;
        }else if(rotation === 90){
            rotation = 180;
        }else if(rotation === 180){
            rotation = -90;
        }else if(rotation === -90){
            rotation = 0;
        }
        this.props.onSetRotation(rotation);
    };

    onRotateRight = () => {
        let rotation = this.props.rotation;
        if(rotation === 0){
            rotation = -90;
        }else if(rotation === 90){
            rotation = 0;
        }else if(rotation === 180){
            rotation = 90;
        }else if(rotation === -90){
            rotation = 180;
        }
        this.props.onSetRotation(rotation);
    };

    onMovePositionOffsetUp = () => {
        let position_offset = this.props.positionOffset;
        this.props.onSetPositionOffset({
            x: position_offset.x,
            z: position_offset.z + 5
        });
    }

    onMovePositionOffsetDown = () => {
        let position_offset = this.props.positionOffset;
        this.props.onSetPositionOffset({
            x: position_offset.x,
            z: position_offset.z - 5
        });
    }

    onMovePositionOffsetLeft = () => {
        let position_offset = this.props.positionOffset;
        this.props.onSetPositionOffset({
            x: position_offset.x - 5,
            z: position_offset.z
        });
    }

    onMovePositionOffsetRight = () => {
        let position_offset = this.props.positionOffset;
        this.props.onSetPositionOffset({
            x: position_offset.x + 5,
            z: position_offset.z
        });
    }

    renderTemplateSelector = () => {
        let selected = this.props.selected,
            template = Templates.getLandTemplate(selected);
        return <div>
            <div className="center-align">
                <h5 className="inline-block">{'Template name : ' + template.name}</h5>
            </div>
            <div className="row">
                <div className="col s8 offset-s2">
                    <img style={{width: "100%"}} src={template.preview_url} />
                </div>
            </div>
            <div className="center-align">
                <Button className="t18-btn utils-btn" onClick={this.onPreviousTemplate} style={{height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_left</i>} />
                <Button className="t18-btn utils-btn" onClick={this.onNextTemplate} style={{height: '50px', marginLeft: "50px"}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_right</i>} />
            </div>

        </div>
    };

    renderRotationEditor = () => {
        let selected = this.props.selected, template = Templates.getLandTemplate(selected), land = this.props.land,
            rotation = this.props.rotation;
        return <div>
            <div className="row">
                <div className="col s8 offset-s2 center-align">
                    <LandPreviewMap landHandler={Land} template={template} x={land.position.x} y={land.position.y} 
                        rotation={rotation} positionOffset={this.props.positionOffset} size={4} showFootprint={true} />
                </div>
            </div>
            <div className="center-align">
                <Button className="t18-btn utils-btn" onClick={this.onRotateLeft} style={{height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>rotate_left</i>} />
                <Button className="t18-btn utils-btn" onClick={this.onRotateRight} style={{marginLeft: "50px", height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>rotate_right</i>} />
            </div>
            {User.isAdmin() && <div className="center-align tm">
                <Button className="t18-btn utils-btn" onClick={this.onMovePositionOffsetLeft} style={{height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_left</i>} />
                <Button className="t18-btn utils-btn" onClick={this.onMovePositionOffsetRight} style={{marginLeft: "50px", height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_right</i>} />
                <Button className="t18-btn utils-btn" onClick={this.onMovePositionOffsetUp} style={{marginLeft: "50px", height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_up</i>} />
                <Button className="t18-btn utils-btn" onClick={this.onMovePositionOffsetDown} style={{marginLeft: "50px", height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_down</i>} />
            </div>}
        </div>;
    };

    render(){
        let selected = this.props.selected,
            template = Templates.getLandTemplate(selected),
            building_object = _.find(template.data.objects, {type: 'building'}),
            screen_count = _.get(building_object, 'screens.length', 0),
            link_count = _.get(building_object, 'links.length', 0);
        return <div className="row">
            <div>
                <div className="center-align" style={{display: 'inline-block', width: '50%'}}>
                    <h5 className="inline-block">Choose your building template</h5>
                </div>
                <div className="center-align" style={{display: 'inline-block', width: '50%'}}>
                    <h5 className="inline-block">Set your building rotation</h5>
                </div>
            </div>
            <div>
                <div style={{display: 'inline-block', width: '50%'}}>
                    {this.renderTemplateSelector()}
                </div>
                <div style={{display: 'inline-block', width: '50%'}}>
                    {this.renderRotationEditor()}
                </div>
            </div>
            <div className="row">
                <div className="col s8 offset-s2 center-align tm">
                    <h5 className="inline-block">Template details :</h5>
                    {screen_count > 0 ? <h5 className="red-text">{screen_count + ' logo(s)'}</h5> : undefined}
                    {link_count > 0 ? <h5 className="blue-text">{link_count + ' entry door(s)'}</h5> : undefined}
                </div>
            </div>
        </div>
    }

}