/**
 * Created by alexandre on 23/06/16.
 */

/**
 * Props : --> id : id to give to the component
 *         --> type : linear or circular (default : linear)
 *         --> color : red, blue, green or yellow (not implemented for linear) (default : blue)
 *         --> progressColor
 *         --> backgroundProgressColor
 *         --> value : % of loading, for linear only, if not given : linear animation
 *         --> size : big or small (nothing for normal)
 */

import React from 'react';

export default class extends React.Component {
  state = {id:(this.props.id ? this.props.id : window.generateID())};

  render() {
    var progressStyle = {};
    if(this.props.type === "linear"){
      if(this.props.value)
        progressStyle.width = this.props.value + "%";
      if(this.props.progressColor)
        progressStyle.backgroundColor = this.props.progressColor;
    }
    return (<div>
              {(this.props.type && this.props.type === "circular"
                  ? <div className={"preloader-wrapper active "+(this.props.size ? this.props.size : "")}>
                      <div className={"spinner-layer spinner-"+(this.props.color ? this.props.color : "blue")+"-only"}>
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                          <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                          <div className="circle"></div>
                        </div>
                      </div>
                  </div>
                  : <div className="progress" style={this.props.backgroundProgressColor ? {backgroundColor:this.props.backgroundProgressColor} : {}}>
                      <div className={this.props.value ? "determinate" : "indeterminate"} style={progressStyle}></div>
                    </div>
              )}
          </div>
    )
  }
}
