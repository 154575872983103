import React from "react";

import config from '../../config';
import Media from "../../model/Media";

import IconButton from "../global/IconButton";
import CheckBox from "../global/CheckBox";
import MediaDisplay from "./MediaDisplay";
import ContextMenuComponent from "../global/ContextMenuComponent";

export default class MediaCard extends React.Component {

    onRefresh = () => {
        this.forceUpdate();
    }

    onDragStart = (e, media_id) => {
        e.dataTransfer.setData("media_id", media_id);
    };

    onDragEnd = (e) => {
        e.preventDefault();
    }

    onActionClick = (e, action, media_id) => {
        e.stopPropagation();
        action.onClick(media_id);
    }

    onSelectCheckBoxClick = (media_id) => {
        this.props.onSelectCheckBoxClick(media_id);
    }

    render = () => {
        let media_id = this.props.media_id, media = Media.getMedia(media_id),
            action_display = [], media_actions = this.props.media_actions || [],
            className = "media-card z-depth-1 " + (this.props.isSelected ? ' selected': '');
        if(this.props.className){
            className += " " + this.props.className;
        }
        if(this.props.onClick){
            className += " pointer";
        }
        for(let action of media_actions){
            action_display.push(<span onClick={(e) => {this.onActionClick(e, action, media_id)}}>
                <IconButton icon={action.icon} tooltip={action.tooltip} className="theme-color lm"/></span>)
        }
        return <div className={"col " + (this.props.largeWrapper ? "xl2 l3 m4 s6" : "l3 m4 s6")} style={{padding: "20px"}}>
            <div className={className} style={{position: "relative"}}
                 onClick={this.props.onClick && this.props.onClick.bind(null, media_id)}
                 draggable="true" onDragStart={(e) => this.onDragStart(e, media_id)} onDragEnd={this.onDragEnd} >
                <MediaDisplay key={media && media.id} media_id={media && media.id} onMediaLoad={this.onRefresh} displayName={true} />
                {action_display.length > 0 && (!this.props.hideActionsIfNotSelected || this.props.isSelected) &&
                    <div style={{textAlign: 'right', paddingRight: '10px', paddingTop: this.props.isSelected ? '' : '5px'}}>
                    {action_display}
                </div>}
                {this.props.selectCheckBox && <div style={{position: 'absolute', bottom: this.props.isSelected ? '0' : '4px', left: '12px'}}>
                    <CheckBox checked={this.props.isSelected} preventClickPropagation={true}
                              onClick={this.onSelectCheckBoxClick.bind(null, media_id)} />
                </div>}
            </div>
        </div>
    }

}