import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import SubscriptionToken from "../../model/SubscriptionToken";


export default class WaitingSubscriptionBuyModal extends React.Component {

  state = { opened: false, openModal: null, closeModal: null };

  componentDidMount() {
    window.openWaitingSubscriptionBuyModal = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  openModal = (options = {}, close_cb = function() {}) => {
    let state = { opened: true, undismissible: options.undismissible, close_cb: close_cb };

    this.setState(state, this.state.openModal);
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
  };

  afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
  };

  onValueChange = (value) => {
    this.setState({value: value});
  };

  onSubmit = () => {
    SubscriptionToken.loadOwnedSubscriptionTokens(undefined, {no_loading: true});
    setTimeout(() => {
        SubscriptionToken.loadOwnedSubscriptionTokens(undefined, {no_loading: true});
    }, 1000);
    setTimeout(() => {
        SubscriptionToken.loadOwnedSubscriptionTokens(undefined, {no_loading: true});
    }, 2000);
    this.state.closeModal();
  };

  render() {
    let disabled = false, content;
    if(this.state.opened){
      content = <div id="waiting-subscription-buy-modal-content" className="reglisse">
        <h3 style={{margin: 0}}>{"Subscription Buy"}</h3>
        <h5>Click on 'Refresh Subscriptions' button once you have successfully bought/extended the subscription.</h5>
        <div className="modal-footer">
          <Button text={'Refresh Subscriptions'} className='t18-btn rm' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
          <Button text={"Cancel"} className='t18-btn black lm' onClick={this.state.closeModal} large={true}
                  style={{fontSize: "20px"}}/>
        </div>
      </div>;
    }
    return (
      <Modal id="waiting-subscription-buy-modal"
             content={content}
             getOpen={this.getOpen}
             getClose={this.getClose}
             afterOpen={this.afterOpen}
             afterClose={this.afterClose}
             undismissible={this.state.undismissible}
      />
    )
  }
};
