
var currentID = 0;

window.generateID = function(){
    currentID++;
    return ""+currentID;
};

window.isTouchDevice = ((window.navigator.msPointerEnabled && navigator.msMaxTouchPoints > 0) ||
(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  ? true : false;

window.getSizePx = function(selector){
  return{width:parseFloat(selector.css("width").replace("px","")),
    height:parseFloat(selector.css("height").replace("px",""))}
};
