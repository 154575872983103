import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";


export default class EarlyAccessInfo extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openEarlyAccessInfo = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { opened: true, undismissible: options.undismissible, close_cb: close_cb };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onSubmit = () => {

        this.state.closeModal();
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            content = <div id="early-access-info-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Land buying instructions"}</h3>
                <div style={{fontSize: '20px'}}>
                    <p>To buy a Land during the presale, you must authenticate with your wallet extension (such as Metamask).</p>
                    <p>Select the Land you want to buy on the map then click on the 'Buy Land' Button.</p>
                    <p>A pop-up will show you a summary of the Land information.</p>
                    <p>Once you click on the 'Confirm' button, your wallet should prompt you with the associated transaction.</p>
                    <p>
                        If you have any trouble with this process, please contact us on our Discord server (directly or by creating a ticket).
                    </p>
                </div>
                <div className="modal-footer">
                    <Button text={"close"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="early-access-info-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
