import React from "react";
import Config from "../../../config";
import Scene from "../../../model/Scene";
import utils from "../../../utils";
import generic from "../../../model/generic";
import Land from "../../../model/Land";
import User from "../../../model/user";
import Templates from "../../../model/Templates";

import Button from "../../global/Button";

import ScreenEditor from "../../Common/ScreenEditor";
import SceneTemplateTopViewDisplay from "../../Common/SceneTemplateTopViewDisplay";
import EntityLoader from "../../Common/EntityLoader";


export default class extends React.Component {

    state = {selected: null};

    componentDidMount() {
        generic.addEntityListener('scene', this.refresh);
        generic.addEntityListener('link', this.refresh);
        this.selectRootLandScene();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.land_id !== this.props.land_id){
            this.selectRootLandScene();
        }
    }

    selectRootLandScene = () => {
        let land_root_scene_id = Land.getLandSceneId(this.props.land_id), land_root_scene = Scene.getScene(land_root_scene_id);
        if(land_root_scene){
            this.setState({selected: land_root_scene_id});
        }else{
            generic.refreshEntity('scene', land_root_scene_id, () => {
                this.setState({selected: land_root_scene_id});
            });
        }
    }

    onSceneSelect = (id) => {
        this.setState({selected: id});
    }

    onAddFloor = () => {
        let land_root_scene_id = Land.getLandSceneId(this.props.land_id);
        window.openLandInitializationForm({add_scene_floor: true, target_scene_id: land_root_scene_id});
    }

    onRemoveSelectedFloor = () => {
        window.openConfirmActionModal({
            action_text: "Do you really want to delete this floor ?",
            confirm_cb: () => {
                Scene.removeScene(this.state.selected, (err) => {
                    if(err){
                        window.alertPopup('Error', 'An error has occurred while deleting the scene.');
                    }else{
                        this.selectRootLandScene();
                    }
                });
            }
        });
    }

    getCurrentScenes = () => {
        let scenes = [], land_root_scene_id = Land.getLandSceneId(this.props.land_id), land_root_scene = Scene.getScene(land_root_scene_id);
        if(land_root_scene){
            scenes.push(land_root_scene);
            let exit_link = Scene.getSceneExitLink(land_root_scene_id);
            if(exit_link){
                let target_list = _.get(exit_link, 'data.target_list', []);
                for(let target of target_list){
                    if(target.type === 'scene' && target.id !== land_root_scene_id){
                        let scene = Scene.getScene(target.id);
                        if(scene){
                            scenes.push(scene);
                        }else{
                            generic.refreshEntity('scene', target.id);
                        }
                    }
                }
            }
        }
        return scenes;
    };

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let scenes = this.getCurrentScenes(), scene_select = [], selected = this.state.selected;
        for(let scene of scenes){
            scene_select.push(<Button className={"view-select-btn lm" + (selected === scene.id ? ' selected': '')} text={scene.name}
                                      onClick={this.onSceneSelect.bind(null, scene.id)} />);
        }
        let add_floor_disabled = scene_select.length >= 2 && !User.isAdmin();
        scene_select.push(<Button className="t18-btn view-select-btn lm" text={"Add floor"} color={"green"}
                                  onClick={this.onAddFloor} disabled={add_floor_disabled} />);
        return <div>
            <div className="center-align tm">
                {scene_select}
            </div>
            <ToolBox selected={this.state.selected} disableTemplateEdit={this.props.disableTemplateEdit} onRemoveFloor={this.onRemoveSelectedFloor} />
        </div>
    }

}

class ToolBox extends React.Component {

    componentDidMount() {
        window.printSelectedScene = () => {
            console.log(generic.getEntity('scene', this.props.selected));
        }
    }

    onEditName = () => {
        window.openSceneNameForm({scene_id: this.props.selected});
    };

    onEditTemplate = () => {
        window.openLandIndoorTemplateForm({scene_id: this.props.selected});
    };

    onOpenInMetaverse = () => {
        let scene_id = this.props.selected;
        if(!scene_id)return;
        let url = 'https://app.terminal18.org/?id=' + scene_id;
        window.open(url, '_blank').focus();
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let scene = Scene.getScene(this.props.selected);
        if(!scene){
            return <EntityLoader entity='scene' id={this.props.selected} onLoad={this.refresh} />;
        }
        let template = Templates.getSceneTemplate(scene.scene_template_id),
            screens = _.get(scene, 'data.screens', []),
            multi_screens = _.get(scene, 'data.multi_screens', []),
            links = _.get(scene, 'data.links', []),
            screen_display = [], multi_screen_display = [], link_display = [];
        for(let i = 0; i < screens.length; i++){
            let screen = screens[i];
            screen_display.push(<ScreenEditor entity_type="screen" entity_id={screen.id} scene_id={this.props.selected} screen_type="scene" target_index={i} />);
        }
        for(let multi_screen of multi_screens){
            multi_screen_display.push(<ScreenEditor entity_type="multi_screen" entity_id={multi_screen.id} scene_id={this.props.selected} screen_type="scene" />);
        }
        for(let link of links){
            let link_data = generic.getEntity('link', link.id);
            if(link_data && link_data.type !== 'vid360'){
                continue;
            }
            link_display.push(<ScreenEditor entity_type="link" entity_id={link.id} scene_id={this.props.selected} screen_type="scene" />);
        }
        return <div className={"row"} style={{color: 'white', paddingTop: '30px'}}>
            <div style={{marginLeft: '50px'}}>
                <Button className="t18-btn" text="Open In Metaverse" onClick={this.onOpenInMetaverse} />
            </div>
            <div style={{marginLeft: '50px'}}>
                <h4 className="inline-block rm">{'Scene name : ' + (scene.name || 'Unnamed')}</h4>
                {!this.props.disableTemplateEdit && <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}} onClick={this.onEditName}
                                                           text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} />}
                {!this.props.disableTemplateEdit && <Button className="t18-btn lm" color="red"
                                                            style={{position: 'relative', bottom: '6px'}} disabled={scene.land_root}
                                                            text="Remove floor" onClick={this.props.onRemoveFloor} />}
                    </div>
            <div style={{marginLeft: '50px'}}>
                <div>
                    <h4 className="inline-block rm">Scene template</h4>
                    {!this.props.disableTemplateEdit ? <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}} onClick={this.onEditTemplate}
                                                               text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} /> : undefined}
                </div>
                <div className="tm">
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px',
                        height: '300px', padding: '32px', verticalAlign: 'top'}}>
                        <img style={{height: "100%"}} src={template.preview_url} />
                    </div>
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px', width: '300px',
                        height: '300px', padding: '32px', marginLeft: '40px', verticalAlign: 'top'}}>
                        <SceneTemplateTopViewDisplay template={template} showAllScreens={true} showAllMultiScreens={true} showAllLinks={true}
                                                     screen_display_size={{width: 40, height: 15}} link_display_size={{width: 40, height: 15}} />
                    </div>
                </div>
            </div>
            <div className="row" style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Scene screens</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {screen_display}
                </div>
            </div>
            {link_display.length > 0 && <div className="row" style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Scene media doors</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {link_display}
                </div>
            </div>}
            <div className="row" style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Scene multi screens</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {multi_screen_display}
                </div>
            </div>
        </div>
    }

}