import React  from 'react';

const TAG_LIST = ['180°', '360°', 'amateur', 'anal', 'arab', 'asian', 'ass', 'babe', 'bdsm', 'big ass', 'big dick',
    'big tits', 'bisexual male', 'blonde', 'blowjob', 'bondage', 'brunette', 'bukkake', 'cartoon', 'casting', 'caucasian', 'cosplay',
    'couple', 'creampie', 'cuckold', 'cumshot', 'deepthroat', 'dick', 'double penetration', 'ebony', 'euro', 'feet', 'female orgasm',
    'fetish', 'fingering', 'fisting', 'food', 'french', 'gangbang', 'gay', 'handjob', 'hardcore', 'hentai', 'indian', 'interracial',
    'latina', 'lesbian', 'massage', 'masturbation', 'mature', 'milf', 'muscular men', 'orgy', 'outdoor', 'party', 'pissing', 'pornstar',
    'POV', 'public', 'pussy', 'pussy licking', 'reality', 'red head', 'role play', 'romantic', 'rough sex', 'sextoys', 'small tits',
    'solo female', 'solo male', 'squirt', 'striptease', 'tattooed', 'teen(18+)', 'threesome', 'transgender'];

export default class TagSelect extends React.Component {

    render(){
        let tags = [], selected = this.props.selected;
        for(let tag of TAG_LIST){
            tags.push(<div className={'z-depth-1 clickable tag-badge' + (selected.indexOf(tag) > -1 ? " selected": "")}
                           onClick={this.props.onTagClick.bind(null, tag)}>{tag}</div>)
        }
        return <div>
            {tags}
        </div>
    }

}