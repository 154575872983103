import React from "react";

import Config from "../../config";

import User from "../../model/user";

import MediaView from "./MediaView/MediaView";
import LandView from "./LandView/LandView";
import SubscriptionView from "./SubscriptionView/SubscriptionView";
import DemoSceneView from "./DemoSceneView";
import FlashSales from "../../model/FlashSales";
import CountDown from "../Common/CountDown";

window.onManagementViewSelect = null;

export default class extends React.Component {

    state = {selectedView: 'media'};

    componentDidMount() {
        window.onManagementViewSelect = this.onViewSelect;
    }

    onViewSelect = (view) => {
      if(view !== this.state.selectedView){
        this.setState({selectedView: view});
      }
    };

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        if(!FlashSales.publicFlashSaleStarted() && !User.isAdmin() && !User.isInLandHandlerWhitelist()){
            return <div id="manager-view" className={"row" + (this.props.show ? '' : ' hide')} style={{color: 'white', textAlign: 'center', backgroundColor: 'black'}}>
                <h4>Manager View Will be accessible once the public presale has started</h4>
                <CountDown target={FlashSales.getPublicFlashSaleOpeningDate()} onTargetReached={this.refresh} />
            </div>
        }
      let selectedView = this.state.selectedView;
      return <div id="manager-view" className={"row" + (this.props.show ? '' : ' hide')} style={{backgroundColor: 'black'}}>
        {User.isAuthenticated() ? (User.isLandOwner() || User.isAdmin() ? <div>
          <div style={{paddingTop: "15px", paddingLeft: "20px", borderTop: "2px solid white"}}>
            <span className={"navbar-menu-option" + (selectedView === 'media' ? ' selected' : '')} onClick={this.onViewSelect.bind(null, 'media')}>Media</span>
            {Config.enable_subscription_handling && <span className={"navbar-menu-option" + (selectedView === 'subscription' ? ' selected' : '')} onClick={this.onViewSelect.bind(null, 'subscription')}>Subscription</span>}
            <span className={"navbar-menu-option" + (selectedView === 'land' ? ' selected' : '')} onClick={this.onViewSelect.bind(null, 'land')}>Land</span>
          </div>
          <div style={{marginTop: "15px", borderTop: "2px solid white"}}>
            <MediaView show={selectedView === 'media'}/>
            {Config.enable_subscription_handling && <SubscriptionView show={selectedView === 'subscription'}/>}
            <LandView show={selectedView === 'land'}/>
          </div>
        </div> : <h4 className="white-text center-align" style={{margin: 0, paddingTop: "100px"}}>You need to own a Land to access this view. If you just bought your first Land, disconnect/reconnect.</h4>) : <div>
          <h4 className="white-text center-align" style={{margin: 0, paddingTop: "100px"}}>You need to be authenticated to access this view and manage your assets.</h4>
        </div>}
      </div>

    }

}