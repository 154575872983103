import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import MediaSelector from "../Common/MediaSelector";
import User from "../../model/user";


export default class MediaFolderSelectModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openMediaFolderSelectModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { opened: true, path: options.path || '/', undismissible: options.undismissible, close_cb: close_cb, on_submit: options.on_submit };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onFolderSelect = (path) => {
        this.setState({path: path});
    }

    onFolderReturn = () => {
        let path = this.state.path;
        if(path !== '/'){
            path = path.substring(0, path.lastIndexOf('/'));
            if(path === '') path = '/';
            this.setState({path: path});
        }
    }

    onAddFolder = () => {
        window.openNameForm({title: 'Add folder', on_submit: (name) => {
                User.addMediaFolder({parent_path: this.state.path, name: name}, (err, res) => {
                    if(err){
                        console.log(err);
                        window.alertPopup('Error', 'An error has occurred while creating new folder.');
                    }else{
                        this.refresh();
                    }
                });
            }})
    }

    getMediaSelectActions = () => {
        let actions = [
            {icon: 'create_new_folder', tooltip: 'Create new folder', onClick: this.onAddFolder},
        ];
        if(this.state.path !== '/'){
            actions.push({icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn});
        }
        return actions;
    }

    onSubmit = () => {
        this.state.on_submit(this.state.path);
        this.state.closeModal();
    };

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let disabled = false, content;
        if(this.state.opened){
            content = <div id="model-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Move media"}</h3>
                <MediaSelector onlyFolders={true} currentPath={this.state.path}
                               actions={this.getMediaSelectActions()} onFolderSelect={this.onFolderSelect} />
                <div className="modal-footer">
                    <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Cancel"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="model-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
