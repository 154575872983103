import React from "react";

import generic from "../../model/generic";

import EntityLoader from "./EntityLoader";
import IconButton from "../global/IconButton";

const size_styles = [
    {height: "100%", maxWidth: "100%"},
    {width: "100%", maxHeight: "100%"}
]

export default class MediaDisplay extends React.Component {

    state = {render_video: false, id: generateID()};

    componentDidMount() {
        this.forceUpdate();
    }

    onPlayVideo = () => {
        this.setState({render_video: true});
    }

    renderProcessing = () => {
        return <div style={{height: "200px", paddingTop: '90px', fontSize: '20px'}}>
            <div className={"green-text"}>Processing ongoing ...</div>
        </div>
    }

    renderPicture = (url, width, height) => {
        let style = {height: "100%", maxWidth: "100%"};
        if(width && height){
            let wrapper = document.getElementById("wrapper-" + this.state.id),
                ratio = width / height,
                wrapper_ratio = wrapper ? wrapper.clientWidth / wrapper.clientHeight : 1;
            if(ratio > wrapper_ratio){
                style = {width: "100%", maxHeight: "100%"};
            }
        }
        return <img style={style} src={url} />
    }

    renderPictureMedia = (media) => {
        return this.renderPicture(media.url, _.get(media, 'metadata.width'), _.get(media, 'metadata.height'));
    }

    renderVideoMedia = (media) => {
        if(media.thumbnail_url && !this.state.render_video){
            if(this.state.render_video){
                return <video style={{height: "100%", maxWidth: "100%"}} src={media.url} controls preload="metadata" />
            }else{
                return <div style={{position: 'relative', height: '100%'}}>
                    {this.renderPicture(media.thumbnail_url, _.get(media, 'metadata.width'), _.get(media, 'metadata.height'))}
                    <IconButton icon="play_circle" onClick={this.onPlayVideo} className="white-text"
                                style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: '50px'}} />
                </div>;
            }
        }else{
            return <video style={{height: "100%", maxWidth: "100%"}} src={media.url} controls preload="metadata" />
        }
    }

    onMediaLoad = () => {
        if(this.props.onMediaLoad){
            this.props.onMediaLoad();
        }
        this.forceUpdate();
    }

    render() {
        let media = generic.getEntity('media', this.props.media_id);
        if(!media){
            return <EntityLoader entity='media' id={this.props.media_id} onLoad={this.onMediaLoad} />
        }else if(media.processing){
            return this.renderProcessing();
        }
        return (
            <div >
                <div id={"wrapper-" + this.state.id} style={{height: this.props.media_height || "200px", textAlign: 'center'}}>
                    {media.type === 'picture' ? this.renderPictureMedia(media) : this.renderVideoMedia(media)}
                </div>
                {this.props.displayName && <div className="media-card-name">{media && media.name}</div>}
            </div>
        )
    }

}