import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import MediaCard from "../Common/MediaCard";

import generic from "../../model/generic";
import Screen from "../../model/Screen";
import MultiScreen from "../../model/MultiScreen";
import Link from "../../model/Link";
import media from "../../model/Media";


export default class MultiScreenModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openMultiScreenModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { multi_screen_id: options.multi_screen_id, opened: true, undismissible: options.undismissible, close_cb: close_cb };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onAdd = () => {
        let multi_screen_id = this.state.multi_screen_id,
            multi_screen = MultiScreen.getMultiScreen(multi_screen_id),
            media_id_list = _.cloneDeep(_.get(multi_screen, "data.media_id_list", [])),
            media_type_filter = ['picture', 'video', 'video_360'];
        window.openDisplayMediaAddModal({
            multiple_select: true,
            media_type_filter: media_type_filter,
            filter_out: media_id_list,
            on_submit: (selected => {
                MultiScreen.updateMultiScreenMedia(multi_screen_id, media_id_list.concat(selected), (err) => {
                    if(err){
                        window.alertPopup('Error', 'An error has occurred while updating the multi screen');
                    }
                    this.refresh();
                });
            })});
    };

    onRemove = (media_id) => {
        let multi_screen_id = this.state.multi_screen_id,
            multi_screen = MultiScreen.getMultiScreen(multi_screen_id),
            media_id_list = _.cloneDeep(_.get(multi_screen, "data.media_id_list", []));
        MultiScreen.updateMultiScreenMedia(multi_screen_id, _.without(media_id_list, media_id), (err) => {
            if(err){
                window.alertPopup('Error', 'An error has occurred while updating the multi screen');
            }
            this.refresh();
        });
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let multi_screen = generic.getEntity('multi_screen', this.state.multi_screen_id),
                media_id_list = _.get(multi_screen, 'data.media_id_list', []),
                media_display = [];
            _.map(media_id_list, (media_id) => {
                media_display.push(<MediaCard key={media_id} media_id={media_id}
                                              media_actions={[{
                                                  icon: 'delete',
                                                  onClick: this.onRemove,
                                                  tooltip: 'Remove Media from Multi-screen'
                                              }]}  />);
            });
            content = <div id="multi-screen-modal-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Multi-Screen"}</h3>
                <Button text={'Add Media'} className='t18-btn' onClick={this.onAdd} disabled={disabled}
                        style={{fontSize: "20px", position: 'absolute', top: '20px', right: '20px'}} />
                <div className="row">
                    {media_display}
                </div>
                <div className="modal-footer">
                    <Button text={'Add Media'} className='t18-btn' onClick={this.onAdd} disabled={disabled}
                            style={{fontSize: "20px"}} />
                    <Button text={"Close"} className='t18-btn black lm' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="multi-screen-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
