import React from "react";
import Config from "../../../config";
import Land from "../../../model/Land";
import Templates from "../../../model/Templates";
import utils from "../../../utils";
import User from "../../../model/user";

import Button from "../../global/Button";

import LandPreviewMap from "../../../../../shared/components/common/LandPreviewMap";
import ScreenEditor from "../../Common/ScreenEditor";


export default class extends React.Component {

    onEditName = () => {
        window.openLandNameForm({land_id: this.props.selected});
    };

    onEditAccess = () => {
        window.openLandAccessForm({land_id: this.props.selected});
    };

    onEditTemplate = () => {
        window.openLandBuildingTemplateForm({land_id: this.props.selected});
    };

    render() {
        let land = Land.getLand(this.props.selected),
            template = Templates.getLandTemplate(land.land_template_id),
            access = land.access || {type: 'free'},
            rotation = land.data.template.rotation,
            position_offset = {x: 0, z: 0},
            building_object = _.find(_.get(land, 'data.objects', []), {type: 'building'}),
            screens = building_object ? building_object.screens || [] : _.get(land, 'data.screens', []),
            screen_display = [];
        if(building_object){
            rotation = building_object.rotation.y;
            position_offset = {x: building_object.position.x, z: building_object.position.z};
        }
        for(let i = 0; i < screens.length; i++){
            let screen = screens[i];
            screen_display.push(<ScreenEditor entity_type={"screen"} entity_id={screen.id} land_id={this.props.selected} screen_type="land" target_index={i} />);
        }
        return <div className={"row"} style={{color: 'white', paddingTop: '30px'}}>
            <div style={{marginLeft: '50px'}}>
                <h4 className="inline-block rm">{'Land name : ' + (land.name || 'Unnamed')}</h4>
                <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}} onClick={this.onEditName}
                        text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} />
            </div>
            <div style={{marginLeft: '50px'}}><h4>{'Position  :  X = ' + land.position.x + '  |  Y = ' + land.position.y + '  (Zone ' + land.zone + ')'}</h4></div>
            {Config.enable_access_handling ? <div style={{marginLeft: '50px'}}>
                <div>
                    <h4 className="inline-block rm">{"Access : " + access.type.upperFirstLetter()}</h4>
                    <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}} onClick={this.onEditAccess}
                            text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} />
                </div>
                {access.type === 'buy' || access.type === 'rent' ? <h4 className="lm-30">{'Price : ' + access.price + " €"}</h4> : undefined}
                {access.type === 'rent' ? <h4 className="lm-30">{'Renting time : ' + (access.renting_time > 24 ? (Math.round(access.renting_time / 24) + " days") : (access.renting_time + " hours"))}</h4> : undefined}
            </div> : undefined}
            <div style={{marginLeft: '50px'}}>
                <div>
                    <h4 className="inline-block rm">Building template</h4>
                    <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}}
                            onClick={this.onEditTemplate} disabled={(land.linkedTo || land.linkedWith) != null && !User.isAdmin()}
                            text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} />
                </div>
                <div className="tm">
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px', width: '300px', height: '300px', padding: '32px'}}>
                        <img style={{maxHeight: '234px', maxWidth: '234px'}} src={(template && template.preview_url) || Config.default_image_placeholder_url} />
                    </div>
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px', width: '300px', height: '300px',
                        marginLeft: '40px', verticalAlign: 'top', padding: '32px'}}>
                        <LandPreviewMap landHandler={Land} template={template} x={land.position.x} y={land.position.y} 
                            rotation={rotation} positionOffset={position_offset} size={4} showFootprint={true} />
                    </div>
                </div>
            </div>
            <div style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Building logo</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {screen_display}
                </div>
            </div>
        </div>
    }

}