import React from "react";
import _ from "lodash";
import $ from "jquery";

import Config from "../config";
import User from "../model/user";
import web3 from "../web3";
import Land from "../model/Land";
import utils from "../utils";

import logo_t18 from '../images/logo_t18.png';
import eth_logo from '../images/eth_logo.png';
import Button from "./global/Button";
import IconButton from "./global/IconButton";

export default class extends React.Component {

    render() {
        return <div id="navbar" style={{ backgroundColor: "black", padding: "10px", position: 'relative' }}>
            <div style={{ display: 'inline-block', marginLeft: "20px" }}>
                <img src={logo_t18} height={60} style={{ marginRight: '40px' }} />
                <span id="marketplace-title" >Land Marketplace</span>
                {!Config.MOBILE_DISPLAY ? <span>
                    <span className={"navbar-menu-option" + (this.props.selectedView === 'marketplace' ? ' selected' : '')} onClick={this.props.onViewSelect.bind(null, 'marketplace')}>
                        Marketplace
                    </span>
                    <span className={"navbar-menu-option" + (this.props.selectedView === 'management' ? ' selected' : '')} onClick={this.props.onViewSelect.bind(null, 'management')}>
                        Management
                    </span>
                </span> : undefined}
                <UserDisplay selectedView={this.props.selectedView} onViewSelect={this.props.onViewSelect} />
            </div>
        </div>
    }

}


class UserDisplay extends React.Component {

    onSignin = () => {
        window.openLoginModal();
    };

    render() {
        let is_auth = User.isAuthenticated();
        return <span className='user-navbar-display'>
            {is_auth ? <UserButton selected={this.props.selectedView === 'profile'} onClick={this.props.onViewSelect.bind(null, 'profile')} /> : <span>
                <Button className="pink-t18-bg" style={{ fontSize: "30px", height: "58px", padding: "10px 20px", borderRadius: "15px", marginTop: "15px" }}
                    text={"Log In"} onClick={this.onSignin} />
            </span>}
        </span>
    }

}

class UserButton extends React.Component {

    render() {
        return <span>
            <i id="navbar-user-button" className={"material-icons white-text clickable" + (this.props.selected ? " selected" : "")} onClick={this.props.onClick}
                style={{ fontSize: "50px", marginTop: "18px", borderRadius: "50%" }}>account_circle</i>
        </span>
    }

}

class UserOverlay extends React.Component {

    state = { opened: false };

    componentDidMount() {
        $(document).on('click', () => {
            this.setState({ opened: false });
        });
        Land.onLandUpdateSubscribe(this.refresh);
    }

    onToggle = (e) => {
        e.stopPropagation();
        this.setState({ opened: !this.state.opened });
        web3.reloadUserBalance(this.refresh);
    }

    onProfileClick = () => {
        window.openProfileModal();
        this.setState({ opened: false });
    }

    onOverlayClick = (e) => {
        e.stopPropagation();
    }

    onShowLand = (land_id) => {
        window.marketPlaceMapSelectLand(land_id);
        this.setState({ opened: false });
    }

    onLogOut = () => {
        User.signOut();
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let land_display, lands = Land.getOwnedLands();
        if (lands.length > 0) {
            land_display = <div>
                <span style={{ fontSize: '18px' }}>Owned Lands</span>
                <ul style={{ margin: 0, marginBottom: "10px" }}>
                    {lands.map((land) => {
                        return <li key={land.id}>
                            <IconButton className="theme-color" style={{ position: 'relative', top: '7px', marginRight: '10px' }}
                                icon="my_location" onClick={this.onShowLand.bind(null, land.id)} />
                            <span style={{ fontSize: '16px' }}>{utils.getHexTokenId(land.tokenId) + " : x = " + land.position.x + ' | y = ' + land.position.y + ' (Zone ' + land.zone + ')'}</span>
                        </li>
                    })}
                </ul>
            </div>
        }
        return (
            <span>
                <i className="material-icons white-text clickable" onClick={this.onToggle}
                    style={{ fontSize: "50px", marginTop: "10px", borderRadius: "50%" }}>account_circle</i>
                {this.state.opened ? <div className="user-overlay z-depth-2" onClick={this.onOverlayClick}>
                    {web3.user_address ? <div>
                        <img src={eth_logo} height={30} />
                        <span style={{ fontSize: '28px', position: 'relative', bottom: '5px' }}>{_.round(web3.user_balance, 6)}</span>
                    </div> : undefined}
                    {land_display}
                    <div>
                        <Button color="black" text="Profile" onClick={this.onProfileClick} style={{ width: '100%' }} />
                    </div>
                    <div>
                        <Button color="black" text="Disconnect" onClick={this.onLogOut} style={{ width: '100%', marginTop: '10px' }} />
                    </div>
                </div> : undefined}
            </span>
        )
    }
}