import React from "react";
import moment from "moment";

export default class CountDown extends React.Component {

    refresh_interval = null;

    componentDidMount() {
        this.startCountDown();
    };

    startCountDown = () => {
        if(new Date().getTime() % 1000 < 100){
            this.refresh_interval = setInterval(() => {
                this.forceUpdate();
            }, 1000);
        }else{
            setTimeout(this.startCountDown, 1000 - new Date().getTime() % 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.refresh_interval);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let now = moment(), target = this.props.target, diff = utils.getDifferenceBetweenTwoDates(now, target), reached = true;
        for(let prop in diff){
            if(diff[prop] !== 0){
                reached = false;
                break;
            }
        }
        if(reached){
            this.onTargetReached();
        }
    }

    target_reached = false;

    onTargetReached = () => {
        if(this.target_reached) return;
        this.target_reached = true;
        if(this.props.onTargetReached){
            setTimeout(() => {
                this.props.onTargetReached();
            }, 1000);
        }
    }

    render = () => {
        let now = moment(), target = this.props.target, diff = utils.getDifferenceBetweenTwoDates(now, target);
        return <h5 style={this.props.style}>
            {diff.days > 0 && <span><span className="theme-color">{diff.days}</span> days + </span>}<span className="theme-color">{getTimeDisplay(diff.hours)}</span> : <span className="theme-color">{getTimeDisplay(diff.minutes)}</span> : <span className="theme-color">{getTimeDisplay(diff.seconds)}</span>
        </h5>
    }

}

function getTimeDisplay(time){
    return time < 10 ? "0" + time : time;
}