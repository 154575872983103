import React from "react";
import _ from "lodash";

import drawing from "../../lib/drawing";

const CELL_SIZE = 26, SIDE_HIGHLIGHT_SIZE = 4, SIDE_HIGHLIGHT_COLOR = 'blue';

const arena_bounds = {
    nw: {x: 37, y: -43},
    se: {x: 43, y: -38}
};

const main_plaza_bounds = {
    nw: {x: -1, y: -1},
    se: {x: 1, y: 1}
};

const ZONE_DATA = [
    {
        number: 4,
        start: 0,
        color: '#676767'
    },
    {
        number: 3,
        start: 18,
        color: '#ac8295'
    },
    {
        number: 2,
        start: 36,
        color: '#b4597d'
    },
    {
        number: 1,
        start: 54,
        color: '#cd507c'
    },
    {
        number: 0,
        start: 65,
        color: '#ff5252'
    }
];

function getZoneColor(zone){
    return _.find(ZONE_DATA, {number: zone}).color;
}

export default class LandPreviewMap extends React.Component {

    state = { id: (this.props.id ? this.props.id : window.generateID()) };

    componentDidMount = () => {
        this.drawMap();
    };

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if(prevProps.x !== this.props.x || prevProps.y !== this.props.y || prevProps.size !== this.props.size || prevProps.template !== this.props.template ||
            !_.isEqual(prevProps.positionOffset, this.props.positionOffset) || prevProps.rotation !== this.props.rotation){
            this.drawMap();
        }
    };

    drawMapLines = (ctx) => {
        let size = this.props.size, cell_size = CELL_SIZE, row_cell_count = 2 * size + 1, map_size = cell_size * row_cell_count;
        for(let y = 0; y <= row_cell_count; y++){
            drawing.drawLine(ctx, {y: y * cell_size, x: 0}, {y: y * cell_size, x: map_size}, 'grey');
        }
        for(let x = 0; x <= row_cell_count; x++){
            drawing.drawLine(ctx, {x: x * cell_size, y: 0}, {x: x * cell_size, y: map_size}, 'grey');
        }
    };

    fillCell = (ctx, x, y, options = {}) => {
        let size = this.props.size, 
            cell_size = CELL_SIZE, 
            row_cell_count = 2 * size + 1, 
            map_size = cell_size * row_cell_count, 
            center_x = this.props.x, 
            center_y = this.props.y,
            half_map_size = (map_size - cell_size) / 2,
            half_cell_size = cell_size / 2,
            fill_template = options.fill_template,
            template_rotation = options.template_rotation || 0,
            fill_color = options.fill_color,
            border_color = options.border_color,
            outer_border_color = options.outer_border_color,
            center_color = options.center_color,
            highlight_sides = options.highlight_sides,
            sides_rotation = options.sides_rotation || 0;
        x -= center_x;
        y -= center_y;
        let cell_x = half_map_size + x * cell_size, 
            cell_y = half_map_size + y * cell_size;
        if(outer_border_color){
            drawing.fillRectangle(ctx, {x: cell_x - 2, y: cell_y - 2}, {width: cell_size + 4, height: cell_size + 4}, outer_border_color);
        }
        if(border_color){
            drawing.fillRectangle(ctx, {x: cell_x, y: cell_y}, {width: cell_size, height: cell_size}, border_color);
        }
        if(fill_template){
            drawing.drawTemplate(ctx, fill_template, template_rotation, cell_x, cell_y, cell_size);
        }else if(fill_color){
            if(border_color){
                drawing.fillRectangle(ctx, { x: cell_x + 2, y: cell_y + 2 }, { width: cell_size - 4, height: cell_size - 4 }, fill_color);
            }else{
                drawing.fillRectangle(ctx, { x: cell_x, y: cell_y }, { width: cell_size, height: cell_size }, fill_color);
            }
        }
        if(center_color){
            drawing.fillRectangle(ctx, {x: cell_x + half_cell_size - 4, y: cell_y + half_cell_size - 4}, {width: 8, height: 8}, center_color);
        }
    };

    fillMapCell = (ctx, land, linked) => {
        let color = 'white',
            options = {};

        let draw_roads = true;
        let land_x = land.position.x, land_y = land.position.y, center_x = this.props.x, center_y = this.props.y;
        let template = _.get(land, 'data.template.type');
        let is_selected = land_x === center_x && land_y === center_y;
        
        if(is_selected){
            color = 'red';
        }else if(land.owner && this.props.colorizeOwnedLands){
            color = 'black';
            let owner = land.owner;
            if(owner){
                if(owner.toLowerCase() === '0x159d4d6a45a94035389b4652dfa4d82c30484273'){
                    color = "#de5c89";
                }else{
                    color = "#" + owner.substring(2, 8);
                }
            }
        }else if(land.type === 'misc'){
            color = 'white';
            if(land_x >= arena_bounds.nw.x && land_x <= arena_bounds.se.x && land_y >= arena_bounds.nw.y && land_y <= arena_bounds.se.y){
                color = 'purple';
            }else if(land_x >= main_plaza_bounds.nw.x && land_x <= main_plaza_bounds.se.x && land_y >= main_plaza_bounds.nw.y && land_y <= main_plaza_bounds.se.y){
                color = 'blue';
            }else if(template === 'park_1'){
                color = '#64dd17';
            }else if(template === 'plaza_1'){
                color = 'blue';
            }
        }else{
            color = getZoneColor(land.zone);
        }
        if(draw_roads && template && template.indexOf('road') > -1){
            if(is_selected){
                options.outer_border_color = 'white';
            }
            options.fill_template = template;
            options.template_rotation = _.get(land, 'data.template.rotation', 0);
        }else if(is_selected || linked){
            options.border_color = 'white';
        }
        options.fill_color = color;
        this.fillCell(ctx, land_x, land_y, options);
    };

    fillMapCells = (ctx) => {
        let center_x = this.props.x, center_y = this.props.y, size = this.props.size, selected;
        for(let x = center_x - size; x <= center_x + size; x++){
            for(let y = center_y - size; y <= center_y + size; y++){
                let land = this.props.landHandler.getLandFromCoordinates(x, y);
                if(land){
                    if(center_x === land.position.x && center_y === land.position.y){
                        selected = land;
                    }else{
                        this.fillMapCell(ctx, land);
                    }
                }
            }
        }
        this.fillMapCell(ctx, selected);
        let linked_lands = this.props.landHandler.getLinkedLands(selected.id);
        for (let linked_land of linked_lands) {
            this.fillMapCell(ctx, linked_land, true);
        }
        if(this.props.showFootprint){
            this.drawBuildingFootprint(ctx);
        }
    };

    drawBuildingFootprint = (ctx) => {
        let template = this.props.template,
            building_object = _.find(_.get(template, 'data.objects', []), {type: 'building'}),
            rotation = this.props.rotation || 0,
            position_offset = this.props.positionOffset,
            size = this.props.size, 
            cell_size = CELL_SIZE,
            half_cell_size = cell_size / 2, 
            row_cell_count = 2 * size + 1, 
            map_size = cell_size * row_cell_count,
            half_map_size = (map_size - cell_size) / 2,
            center_x = half_map_size + half_cell_size, 
            center_y = half_map_size + half_cell_size,
            template_width = _.get(template, 'metadata.width', 10),
            template_length = _.get(template, 'metadata.length', 10),
            map_template_width = (template_width / 10) * cell_size,
            map_template_length = (template_length / 10) * cell_size,
            offset_x = (_.get(position_offset, 'x', 0) / 10) * cell_size,
            offset_z = (_.get(position_offset, 'z', 0) / 10) * cell_size;

        ctx.save();
        ctx.translate(center_x + offset_x, center_y + offset_z);
        ctx.rotate(rotation.toRad());
        ctx.translate(- center_x, - center_y);

        drawing.fillRectangle(ctx, { x: center_x - map_template_width / 2, y: center_y - map_template_length / 2 }, { width: map_template_width, height: map_template_length }, "green");
        let links = _.get(building_object, 'links', []);
        for(let link of links){
            let link_x = (link.position.x / 10) * cell_size, 
                link_y = (link.position.z / 10) * cell_size;
            drawing.fillRectangle(ctx, { x: center_x + link_x - 4, y: center_y + link_y - 4 }, { width: 8, height: 8 }, "blue");
        }
        ctx.restore();
    }

    drawMap = () => {
        const canvas = document.getElementById(this.state.id);
        if(!canvas){
            console.log('Error, mini map canvas not found');
            return;
        }
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.fillMapCells(ctx);
        this.drawMapLines(ctx);
    };

    render(){
        let size = this.props.size, map_size = CELL_SIZE * (2 * size + 1);
        let container_style = {
            display: 'inline-block',
            border: "1px solid white",
            width: (map_size + 2) + 'px',
            height: (map_size + 2) + 'px',
        }
        return <div style={container_style}>
            <canvas id={this.state.id}
                    style={{position: "relative", top: 0, left: 0, backgroundColor: 'white'}}
                    width={map_size} height={map_size} >
            </canvas>
        </div>
    }

}

function rotateSides(sides, rotation){
    if(rotation === -90){
        sides.unshift(sides.pop());
    }else{
        sides.push(sides.shift());
        if(rotation === 180){
            sides.push(sides.shift());
        }
    }
}

function computeBuildingFootprint(land, template){
    let footprint = [],
        x = land.position.x,
        y = land.position.y,
        width = Math.round(_.get(template, 'metadata.width', 10) / 10),
        length = Math.round(_.get(template, 'metadata.length', 10) / 10);
    for(let i = x; i < x + width; i++){
        for(let j = y; j < y + length; j++){
            footprint.push({x: i, y: j});
        }
    }
    return footprint;
}

function computeBuildingFootprintWithRotation(land, template, rotation = 0, position_offset = {x: 0, z: 0}) {
    console.log('computeBuildingFootprintWithRotation', land, template, rotation);
    let footprint = [];
    const x = land.position.x + Math.round(position_offset.x / 10);
    const y = land.position.y + Math.round(position_offset.z / 10);
    const width = Math.round(_.get(template, 'metadata.width', 10) / 10);
    const length = Math.round(_.get(template, 'metadata.length', 10) / 10);
    
    // Normalize rotation to 0, 90, 180, or 270
    rotation = ((rotation % 360) + 360) % 360;
    
    // Calculate dimensions based on rotation
    const [effectiveWidth, effectiveLength] = (rotation === 90 || rotation === 270) 
        ? [length, width] 
        : [width, length];
    
    for(let i = 0; i < effectiveWidth; i++) {
        for(let j = 0; j < effectiveLength; j++) {
            let rotatedPoint;
            
            switch(rotation) {
                case 0: // No rotation
                    rotatedPoint = {
                        x: x + i,
                        y: y + j
                    };
                    break;
                    
                case 90: // 90 degrees clockwise
                    rotatedPoint = {
                        x: x + j,
                        y: y - i
                    };
                    break;
                    
                case 180: // 180 degrees
                    rotatedPoint = {
                        x: x - i,
                        y: y - j
                    };
                    break;
                    
                case 270: // 270 degrees clockwise (or 90 counterclockwise)
                    rotatedPoint = {
                        x: x - j,
                        y: y + i
                    };
                    break;
            }
            
            footprint.push(rotatedPoint);
        }
    }
    
    return footprint;
}

/*

fillMapCellOld = (ctx, land, linked) => {
        let size = this.props.size, cell_size = CELL_SIZE, row_cell_count = 2 * size + 1, map_size = cell_size * row_cell_count, draw_roads = true;
        let land_x = land.position.x, land_y = land.position.y, center_x = this.props.x, center_y = this.props.y;
        let x = land_x - center_x, y = land_y - center_y, template = _.get(land, 'data.template.type');
        let is_selected = land_x === center_x && land_y === center_y;
        let half_map_size = (map_size - cell_size) / 2, half_cell_size = cell_size / 2;
        let cell_x = half_map_size + x * cell_size, cell_y = half_map_size + y * cell_size;
        let color = 'white';
        if(is_selected){
            color = 'red';
        }else if(land.owner && this.props.colorizeOwnedLands){
            color = 'black';
            let owner = land.owner;
            if(owner){
                if(owner.toLowerCase() === '0x159d4d6a45a94035389b4652dfa4d82c30484273'){
                    color = "#de5c89";
                }else{
                    color = "#" + owner.substring(2, 8);
                }
            }
        }else if(land.type === 'misc'){
            color = 'white';
            if(land_x >= arena_bounds.nw.x && land_x <= arena_bounds.se.x && land_y >= arena_bounds.nw.y && land_y <= arena_bounds.se.y){
                color = 'purple';
            }else if(land_x >= main_plaza_bounds.nw.x && land_x <= main_plaza_bounds.se.x && land_y >= main_plaza_bounds.nw.y && land_y <= main_plaza_bounds.se.y){
                color = 'blue';
            }else if(template === 'park_1'){
                color = '#64dd17';
            }else if(template === 'plaza_1'){
                color = 'blue';
            }
        }else{
            color = getZoneColor(land.zone);
        }
        if(draw_roads && template && template.indexOf('road') > -1){
            if(is_selected){
                drawing.fillRectangle(ctx, {x: cell_x - 2, y: cell_y - 2}, {width: cell_size + 4, height: cell_size + 4}, 'white');
            }
            let rotation =  _.get(land, 'data.template.rotation', 0);
            drawing.drawTemplate(ctx, template, rotation, cell_x, cell_y, cell_size);
        }else if(is_selected){
            drawing.fillRectangle(ctx, {x: cell_x, y: cell_y}, {width: cell_size, height: cell_size}, 'white');
            drawing.fillRectangle(ctx, {x: cell_x + 2, y: cell_y + 2}, {width: cell_size - 4, height: cell_size - 4}, color);
        }else{
            if(linked){
                drawing.fillRectangle(ctx, { x: cell_x, y: cell_y }, { width: cell_size, height: cell_size }, 'white');
                drawing.fillRectangle(ctx, { x: cell_x + 2, y: cell_y + 2 }, { width: cell_size - 4, height: cell_size - 4 }, color);
            }else{
                drawing.fillRectangle(ctx, { x: cell_x, y: cell_y }, { width: cell_size, height: cell_size }, color);
            }
        }
        if(is_selected){
            let highlight_sides = this.props.highlight_sides, rotation = this.props.rotation,
                h_size = SIDE_HIGHLIGHT_SIZE, h_cell_size = {width: h_size * 2, height: h_size * 2}, h_color = 'blue';
            if(highlight_sides && highlight_sides.length > 0){
                let sides = highlight_sides.slice();
                if(rotation){
                    rotateSides(sides, rotation);
                }
                if(sides[0]){
                    drawing.fillRectangle(ctx, {x: cell_x + half_cell_size - h_size, y: cell_y - h_size}, h_cell_size, h_color);
                }
                if(sides[1]){
                    drawing.fillRectangle(ctx, {x: cell_x + cell_size - h_size, y: cell_y + half_cell_size - h_size}, h_cell_size, h_color);
                }
                if(sides[2]){
                    drawing.fillRectangle(ctx, {x: cell_x + half_cell_size - h_size, y: cell_y + cell_size - h_size}, h_cell_size, h_color);
                }
                if(sides[3]){
                    drawing.fillRectangle(ctx, {x: cell_x - h_size, y: cell_y + half_cell_size - h_size}, h_cell_size, h_color);
                }
            }
        }
    };

*/