import API from "../api";
import web3 from "../web3";
import config from "../config";
import _ from "lodash";

const User = {

    auth_user: null,

    access_token: null,

    initialize: () => {
        try{
            let cached_user = sessionStorage.getItem('current_user');
            if(cached_user){
                cached_user = JSON.parse(cached_user);
                if(cached_user.tokenExpiresOn > Date.now()){
                    User.auth_user = cached_user;
                    User.access_token = User.auth_user.token;
                    web3.setUserAddress(User.auth_user.address);
                    web3.reloadUserBalance();
                    User.refreshUser();
                    return true;
                }
            }
        }catch(e){
            console.log('Error while retrieving cached user');
            console.log(e);
        }
    },

    isAuthenticated: (with_wallet) => {
        return User.auth_user && (!with_wallet || User.auth_user.from_wallet)
    },

    hasEarlyAccess: () => {
        return User.auth_user && (User.auth_user.early_access || User.auth_user.isAdmin);
    },

    isLandOwner: () => {
        return User.auth_user && User.auth_user.land_owner;
    },

    isAdmin: () => {
        return User.auth_user && User.auth_user.isAdmin;
    },

    isInLandHandlerWhitelist: () => {
        return User.auth_user && config.land_handlers_whitelist.indexOf(User.auth_user.address) !== -1;
    },

    getUser : () => {
        return User.auth_user;
    },

    getUserId: () => {
        return User.auth_user && User.auth_user.id;
    },

    getUserAddress: () => {
        return User.auth_user && User.auth_user.address;
    },

    updateUser: (data, cb) => {
        API.updateUserRequest(data, () => {
            Object.assign(User.auth_user, data);
            cb();
        }, (err) => {
            console.log('Error while updating user : ' + JSON.stringify(err));
            cb(err || true);
        })
    },

    changePassword: (data, cb) => {
        API.changePasswordRequest(data, () => {
            User.auth_user.has_password = true;
            cb();
        }, (err) => {
            console.log('Error while changing password : ' + JSON.stringify(err));
            cb(err || true);
        })
    },

    signInWithCredentials: (email, password, cb) => {
        API.signInWithCredentialsRequest(email, password, (data) => {
            User.auth_user = data.user;
            User.onSignin();
            return cb(null);
        }, (err) => {
            console.log('Error while signing in : ' + JSON.stringify(err));
            return cb(err);
        })
    },

    signInWithWallet: (cb) => {
        API.getOtpSecretRequest(web3.user_address, (data) => {
            let secret = data.secret;
            web3.signMessage(secret, (err, signed) => {
                if(err){
                    return cb(err);
                }
                API.otpSigninRequest(web3.user_address, secret, signed, (data) => {
                    User.auth_user = data.user;
                    User.onSignin();
                    return cb();
                })
            }, (err) => {
                return cb(err || true);
            });
        }, (err) => {
            return cb(err || true);
        })
    },

    onSignin: () => {
        sessionStorage.setItem('current_user', JSON.stringify(User.auth_user));
        User.access_token = User.auth_user.token;
        web3.setUserAddress(User.auth_user.address);
        web3.reloadUserBalance();
        window.model.loadOwnedEntities();
        User.refreshUser();
    },

    signOut: () => {
        User.auth_user = null;
        User.access_token = null;
        sessionStorage.removeItem('current_user');
        window.location.reload();
    },

    refreshUser: (cb = () => {}) => {
        API.getCurrentUserRequest((data) => {
            let user = data.user;
            for(let prop in user){
                User.auth_user[prop] = user[prop];
            }
            sessionStorage.setItem('current_user', JSON.stringify(User.auth_user));
            cb();
        }, (err) => {
            console.log('Error while refreshing user : ' + JSON.stringify(err));
            cb(err || true);
        })
    },

    addMediaFolder: (data, cb) => {
        API.addMediaFolderRequest(data, () => {
            User.refreshUser(cb);
        }, (err) => {
            console.log('Error while adding media folder : ' + JSON.stringify(err));
            cb(err || true);
        })
    },

    getFolderFromPath: (path) => {
        return _.find(User.auth_user.media_folders, {path: path});
    },

    getFoldersFromPath: (path) => {
        return _.filter(User.auth_user.media_folders, (folder) => {
            let split = folder.path.split('/');
            split.pop();
            return split.join('/') === path || split.length === 1 && path === '/';
        });
    },

    getFolderHierarchyDisplay: (path) => {
        let folder_list = [], path_hierarchy = path.split('/');
        // get folder and parent folders in hierarchy
        for(let i = 0; i < path_hierarchy.length; i++){
            let folder = User.getFolderFromPath(path_hierarchy.slice(0, i + 1).join('/'));
            if(folder){
                folder_list.push(folder);
            }
        }
        // return folder hierarchy names display as Root > Folder_1 > Folder_2 ...
        let name_list = folder_list.map((folder) => {
            return folder.name;
        });
        name_list.unshift('Home');
        return name_list.join(' > ');
    },

    updateAcceptedCurrencies: (currencies, cb) => {
        API.updateUserAcceptedCurrenciesRequest({currencies: currencies}, () => {
            User.refreshUser(cb);
        }, (err) => {
            console.log('Error while updating accepted currencies');
            cb(err || true);
        })
    }

};

export default User;

window.User = User;