import { getAddress } from 'ethers';
import _ from "lodash";
import moment from "moment";

const negative_bit = 0x1000,
  factor = 0x10000,
  clearLow =  0xffff0000,
  clearHigh = 0xffff;

const entry_refs = [{x: 0, y: -5}, {x: 5, y: 0}, {x: 0, y: 5}, {x: -5, y: 0}];

const utils = {

    getFileExtension: function(file) {
        return file.name.split('.').pop().toLowerCase();
    },

    encodeTokenId: (x, y) => {
        return (utils.encodeCoordinate(x) * factor) | utils.encodeCoordinate(y);
    },

    encodeCoordinate: (value) => {
        if (value >= 0) {
            return value;
        } else {
            return Math.abs(value) | negative_bit;
        }
    },

    decodeTokenId: (tokenId) => {
        let x = utils.decodeCoordinate((tokenId & clearLow) / factor);
        let y = utils.decodeCoordinate(tokenId & clearHigh);
        return {x, y};
    },

    decodeCoordinate: (value) => {
        if ((value & negative_bit) === negative_bit){
            return value - negative_bit;
        } else {
            return value;
        }
    },

    parseLandTransferEvent: (event) => {
        let owner = event.args.to;
        let tokenId = parseInt(event.args.tokenId._hex, 16);
        return {
            tokenId: tokenId,
            owner: owner
        }
    },

    isValidAddress: (address) => {
        try{
            getAddress(address);
            return true;
        }catch(e){
            return false;
        }
    },

    getLandEntriesFromLinks: (land) => {
        return utils.getSidesFromObjects(_.get(land, 'data.links', []));
    },

    getSidesFromObjects: (targets) => {
        let sides = [0, 0, 0, 0];
        for(let target of targets){
            let x = target.position.x, y = target.position.z;
            let distance_list = [];
            for(let entry of entry_refs){
                distance_list.push(Math.sqrt(Math.pow(x - entry.x, 2) + Math.pow(y - entry.y, 2)));
            }
            let closest_index, closest_distance;
            for(let i = 0; i < 4; i++){
                let d = distance_list[i];
                if(closest_index === undefined || d < closest_distance){
                    closest_index = i;
                    closest_distance = d;
                }
            }
            if(closest_index !== undefined){
                sides[closest_index] = 1;
            }
        }
        return sides;
    },

    formatBytes: (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    getDifferenceBetweenTwoDates: (date1, date2) => {
        let d1 = moment(date1), d2 = moment(date2), days = 0, hours = 0, minutes = 0, seconds = 0;
        if(d1.isBefore(d2)){
            let diff = d2.diff(d1);
            days = Math.floor(diff / (1000 * 60 * 60 * 24));
            diff -= days * (1000 * 60 * 60 * 24);
            hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);
            minutes = Math.floor(diff / (1000 * 60));
            diff -= minutes * (1000 * 60);
            seconds = Math.floor(diff / (1000));
        }
        return {days, hours, minutes, seconds};
    },

    getHexTokenId: (tokenId) => {
        return '0x' + tokenId.toString(16);
    },

    asyncMap: (list, func, callback, options = {}) => {
        if(list.length === 0){
            return callback();
        }
        if(options.keep_order){
            let index = 0, process_next = () => {
                let item = list[index];
                func(item, (err) => {
                    index++;
                    if(options.throw_error && err){
                        return callback(err);
                    }
                    if(index >= list.length){
                        return callback();
                    }else{
                        return process_next();
                    }
                });
            };
            process_next();
        }else if(options.max_concurrency) {
            let index = -1, running = 0, error = false, process_next = () => {
                if(running >= options.max_concurrency || (options.throw_error && error)){
                    return;
                }
                index++;
                if(index >= list.length){
                    return;
                }
                let item = list[index];
                running++;
                func(item, (err) => {
                    if(options.throw_error && err){
                        error = true;
                        return callback(err);
                    }
                    running--;
                    if(index >= list.length){
                        if(running === 0){
                            return callback();
                        }
                    }else{
                        return process_next();
                    }
                });
                process_next();
            };
            process_next();
        }else{
            let processed_count = 0, process_cb = () => {
                processed_count++;
                if(processed_count === list.length){
                    return callback();
                }
            };
            for(let item of list){
                func(item, process_cb);
            }
        }
    }

};

String.prototype.upperFirstLetter = function() {
    let target = this, delimiter;
    if(target.indexOf('-') > -1){
        delimiter = '-';
    }else if(target.indexOf('_') > -1){
        delimiter = '_';
    }
    if(!delimiter){
        return target.replace(target[0], target[0].toUpperCase());
    }else{
        let split = target.split(delimiter);
        for(let i = 0; i < split.length; i++){
            split[i] = split[i].upperFirstLetter();
        }
        return split.join('');
    }
};

String.prototype.replaceAll = function(search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
}

if (typeof(Number.prototype.toRad) === "undefined") {
    Number.prototype.toRad = function () {
        return this * Math.PI / 180;
    }
}

_.asyncMap = (list, func, callback, options = {}) => {
    if(list.length === 0){
        return callback();
    }
    if(options.keep_order){
        let index = 0, process_next = () => {
            let item = list[index];
            func(item, (err) => {
                index++;
                if(options.throw_error && err){
                    return callback(err);
                }
                if(index >= list.length){
                    return callback();
                }else{
                    return process_next();
                }
            });
        };
        process_next();
    }else if(options.max_concurrency) {
        let index = -1, running = 0, error = false, process_next = () => {
            if(running >= options.max_concurrency || (options.throw_error && error)){
                return;
            }
            index++;
            if(index >= list.length){
                return;
            }
            let item = list[index];
            running++;
            func(item, (err) => {
                if(options.throw_error && err){
                    error = true;
                    return callback(err);
                }
                running--;
                if(index >= list.length){
                    if(running === 0){
                        return callback();
                    }
                }else{
                    return process_next();
                }
            });
            process_next();
        };
        process_next();
    }else{
        let processed_count = 0, process_cb = () => {
            processed_count++;
            if(processed_count === list.length){
                return callback();
            }
        };
        for(let item of list){
            func(item, process_cb);
        }
    }
};

export default utils;

window.utils = utils;