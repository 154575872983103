import building_1 from '../images/templates/buildings/building_1.jpeg';
import building_2 from '../images/templates/buildings/building_2.jpeg';
import building_3 from '../images/templates/buildings/building_3.jpeg';
import villa_1 from '../images/templates/buildings/villa_1.jpeg';

import indoor_1 from '../images/templates/indoors/indoor_1.jpeg';
import indoor_plan_1 from '../images/templates/indoors/indoor_plan_1.png';
import indoor_2 from '../images/templates/indoors/indoor_2.jpeg';

const Dev = {

  initializeLandData: (land) => {
      land.tmp_data = {
          name: "Unnamed",
          access: {type: 'free'},
          building_template: 2,
          indoor_template: 1,
          screens: {},
          doors: {}
      }
  },

  getBuildingTemplatesIdList: () => {
      return Object.keys(Dev.templates.building);
  },

  getIndoorTemplatesIdList: () => {
    return Object.keys(Dev.templates.indoor);
  },

  getBuildingTemplate: (id) => {
      return Dev.templates.building[id];
  },

  getIndoorTemplate: (id) => {
    return Dev.templates.indoor[id];
  },

  templates: {

    building: {
        1: {
            id: 1,
            name: 'Building 1',
            url: building_1
        },
        2: {
            id: 2,
            name: 'Building 2',
            url: building_2
        },
        3: {
            id: 3,
            name: 'Building 3',
            url: building_3
        },
        4: {
            id: 4,
            name: 'Villa 1',
            url: villa_1
        }
    },

    indoor: {
      1: {
        id: 1,
        name: 'Indoor 1',
        url: indoor_1,
        plan_url: indoor_plan_1,
        screen_count: 3,
        door_count: 3,
        screens_coordinates: [{x: '9%', y: '51%'}, {x: '37%', y: '9%'}, {x: '84%', y: '51%'}],
        doors_coordinates: [{x: '12%', y: '37%'}, {x: '50%', y: '12%'}, {x: '81%', y: '38%'}],

      },
      /*2: {
        name: 'Indoor 2',
        url: indoor_2,
        screen_count: 4,
        door_count: 1
      }*/
    }

  }

};

window.Dev = Dev;

export default Dev;