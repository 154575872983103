import generic from "./generic";

const MultiScreen = {

    loadOwnedMultiScreens: (cb) => {
        generic.loadOwnedEntities('multi_screen', cb);
    },

    refreshMultiScreen: (screen_id, cb = () => {}) => {
        generic.refreshEntity('multi_screen', screen_id, cb);
    },

    getMultiScreen: (id) => {
        return generic.getEntity('multi_screen', id);
    },

    getOwnedMultiScreens: () => {
        return generic.getOwnedEntities('multi_screen');
    },

    updateMultiScreenMedia: (id, media_id_list, cb = () => {}) => {
        let multi_screen = generic.getEntity('multi_screen', id),
            data = multi_screen.data;
        data.media_id_list = media_id_list;
        generic.updateEntity('multi_screen', id, {data: data}, cb);
    }

}

export default MultiScreen;

window.MultiScreen = MultiScreen;