import React, { useState, useEffect } from 'react';
import ContextMenu from './ContextMenu';

export default class ContextMenuComponent extends React.Component {

    state = {
        contextMenu: {
            visible: false,
            x: 0,
            y: 0,
        },
    };

    componentDidMount() {
        if(this.props.enableContextMenu){
            document.addEventListener('mousedown', this.closeMenu);
        }
    }

    componentWillUnmount() {
        if(this.props.enableContextMenu){
            document.removeEventListener('mousedown', this.closeMenu);
        }
    }

    closeMenu = (e) => {
        if (this.state.context_menu_visible) {
            this.setState({context_menu_visible: false});
        }
    }

    onContextMenu = (e) => {
        e.preventDefault();
        if(this.props.enableContextMenu){
            this.setState({context_menu_visible: true, context_menu_x: e.clientX, context_menu_y: e.clientY});
        }
    }

    render() {
        return <div onContextMenu={this.onContextMenu} onClick={this.closeMenu}>
            {this.props.content}
            {this.state.context_menu_visible && <ContextMenu x={this.state.context_menu_x} y={this.state.context_menu_y} items={this.props.contextMenuItems} closeMenu={this.closeMenu}/>}
        </div>
    }

}