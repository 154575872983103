import React from "react";

import MediaCard from './MediaCard';
import FolderCard from './FolderCard';
import IconButton from "../global/IconButton";
import TagSelect from "./TagSelect";
import _ from "lodash";
import Media from "../../model/Media";
import User from "../../model/user";

export default class MediaSelector extends React.Component {

    state = {path: '/', type_filter: null, tag_filters: [], tag_select_opened: false}

    onTypeFilterClick = (type) => {
        this.setState({type_filter: type})
    }

    onTagClick = (tag) => {
        let selected = this.state.tag_filters.slice(), index = selected.indexOf(tag);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(tag);
        }
        this.setState({tag_filters: selected})
    }

    openTagSelect = () => {
        this.setState({tag_select_opened: true})
    };

    closeTagSelect = () => {
        this.setState({tag_select_opened: false})
    };

    onFolderSelect = (path) => {
        this.setState({path: path})
    }

    onFolderReturn = () => {
        let path = this.state.path;
        if(path !== '/'){
            path = path.substring(0, path.lastIndexOf('/'));
            if(path === '') path = '/';
            this.setState({path: path});
        }
    }

    getActions = () => {
        if(this.props.actions){
            return this.props.actions;
        }else if(this.state.path !== '/'){
            return [
                {icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn}
            ]
        }else{
            return [];
        }
    }

    renderActions = () => {
        let actions = this.getActions(), display = [], style = {margin: 'auto'};
        if(actions.length === 0){
            return undefined;
        }
        if(window.innerWidth < 1300){
            style.display = 'block';
        }
        for (let action of actions) {
            display.push(<IconButton icon={action.icon} onClick={action.onClick} style={style}
                                     tooltip={action.tooltip} className="media-selector-action-btn utils-btn theme-color large"/>)
        }
        return <div className={"col " + (this.props.largeWrapper ? "xl2 l3 m4 s6" : "l3 m4 s6")} style={{padding: "20px"}}>
            <div className="media-card z-depth-1" style={{position: "relative"}}>
                <div className={window.innerWidth < 1300 ? "" : "valign-wrapper"}
                     style={window.innerWidth < 1300 ? {height: '100%', paddingTop: '40px'} : {height: '100%'}}>
                    {display}
                </div>
            </div>
        </div>;
    }

    render() {
        let path = this.props.currentPath || this.state.path,
            selected = this.props.selected,
            medias = _.filter(this.props.medias, (media) => {return media.path === path}),
            folders = User.getFoldersFromPath(path), 
            display = [];
        let actions = this.renderActions();
        if(actions){
            display.push(actions);
        }
        for (let folder of folders) {
            display.push(<FolderCard name={folder.name} 
                                     path={folder.path} 
                                     largeWrapper={this.props.largeWrapper} 
                                     medias={this.props.medias}
                                     onMediaDrop={this.props.onMediaDrop}
                                     hideEmptyFolders={this.props.hideEmptyFolders}
                                     onClick={this.props.onFolderSelect ? this.props.onFolderSelect.bind(null, folder.path) : this.onFolderSelect.bind(null, folder.path)}
                                     />)
        }
        let tag_select_opened = this.state.tag_select_opened,
            type_filter = this.state.type_filter, tag_filters = this.state.tag_filters;
        if(!this.props.onlyFolders){
            let filtered = [];
            for (let media of medias) {
                let filter_out = false;
                if (type_filter && media.type !== type_filter) {
                    filter_out = true;
                } else if (tag_filters.length > 0) {
                    let match = false;
                    if (media.tags && media.tags.length > 0) {
                        for (let tag of tag_filters) {
                            if (media.tags.indexOf(tag) > -1) {
                                match = true;
                                break;
                            }
                        }
                    }
                    filter_out = !match;
                }
                if (!filter_out) {
                    filtered.push(media);
                }
            }
            for (let media of filtered) {
                let is_selected = false;
                if (selected != null) {
                    if (selected.constructor === Array && selected.indexOf(media.id) > -1) {
                        is_selected = true;
                    } else if (typeof selected === 'string' && selected === media.id) {
                        is_selected = true;
                    }
                }
                display.push(<MediaCard media_id={media.id} media_actions={this.props.media_actions} largeWrapper={this.props.largeWrapper}
                                        selectCheckBox={this.props.selectCheckBox} onSelectCheckBoxClick={this.props.onSelectCheckBoxClick}
                                        hideActionsIfNotSelected={this.props.hideActionsIfNotSelected}
                                        onClick={this.props.onSelect} isSelected={is_selected}/>)
            }
        }
        return <div>

            {!this.props.onlyFolders && <div className="reglisse" style={{position: 'relative'}}>
                <div className="inline-block" style={{
                    width: '200px',
                    borderRight: '1px solid white',
                    padding: '10px 0',
                    verticalAlign: 'top'
                }}>
                    <div>
                        <div className={'z-depth-1 clickable tag-badge' + (!type_filter ? " selected" : "")}
                             onClick={this.onTypeFilterClick.bind(null, null)}>All
                        </div>
                        <div
                            className={'z-depth-1 clickable tag-badge' + (type_filter === 'picture' ? " selected" : "")}
                            onClick={this.onTypeFilterClick.bind(null, 'picture')}>Picture
                        </div>
                    </div>
                    <div>
                        <div
                            className={'z-depth-1 clickable tag-badge' + (type_filter === 'video' ? " selected" : "")}
                            onClick={this.onTypeFilterClick.bind(null, 'video')}>Video
                        </div>
                        <div
                            className={'z-depth-1 clickable tag-badge' + (type_filter === 'video_360' ? " selected" : "")}
                            onClick={this.onTypeFilterClick.bind(null, 'video_360')}>Video 180/360
                        </div>
                    </div>
                </div>
                <div className={"inline-block transition-height" + (tag_select_opened ? ' opened z-depth-2' : '')}
                     style={{
                         width: 'calc(100% - 200px)',
                         padding: '10px',
                         zIndex: 1,
                         verticalAlign: 'top',
                         position: 'absolute',
                         overflow: 'hidden',
                         maxHeight: tag_select_opened ? '300px' : '60px',
                         backgroundColor: this.props.tagSelectColor || (tag_select_opened ? 'white' : "initial"),
                         border: tag_select_opened ? '1px solid white' : undefined,
                         borderBottomLeftRadius: '10px',
                         borderBottomRightRadius: '10px',
                         marginLeft: tag_select_opened ? '-1px' : undefined
                     }}
                     onMouseEnter={this.openTagSelect} onMouseLeave={this.closeTagSelect}>
                    {tag_select_opened ? <TagSelect onTagClick={this.onTagClick} selected={tag_filters}/> : <div>
                        <div>
                            <div className={'z-depth-1 clickable tag-badge'}>Select tags</div>
                            {_.map(tag_filters, (tag) => {
                                return <div className={'z-depth-1 clickable tag-badge selected'}>{tag}</div>
                            })}
                        </div>
                    </div>}
                </div>
            </div>}
            <div style={{color: this.props.textColor || 'black'}}>
                <h4>{"Current folder : " + User.getFolderHierarchyDisplay(path)}</h4>
            </div>
            <div className="row" style={{marginTop: '0'}}>
                {display}
            </div>
        </div>
    }

}
