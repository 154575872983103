
const drawing = {

    drawLine(ctx, from, to, color){
        ctx.beginPath();
        ctx.moveTo(from.x, from.y);
        ctx.lineTo(to.x, to.y);
        ctx.strokeStyle = color;
        ctx.stroke();
    },

    fillRectangle(ctx, from, size, color, debug){
        if(debug){
            console.log('FillRectangle : from = ' + JSON.stringify(from), ' , size = ' + JSON.stringify(size) + ' , color = ' + color);
        }
        ctx.fillStyle = color;
        ctx.fillRect(from.x, from.y, size.width, size.height);
    },

    drawTemplate(ctx, template, rotation, x, y, size){
        if(rotation === 90){
            rotation = -90;
        }else if(rotation === -90){
            rotation = 90;
        }
        let img = document.getElementById("template_" + template);
        ctx.save();
        ctx.translate(x + size / 2, y + size / 2);
        ctx.rotate(rotation.toRad());
        ctx.translate(- size / 2, - size / 2);
        ctx.drawImage(img, 0, 0, size, size);
        ctx.restore();
    }

};

export default drawing;