import React from "react";
import './App.css';
import web3 from "./web3";
import model from "./model/model";
import ReactTooltip      from "react-tooltip";

import MarketPlaceView from "./components/MarketPlaceView/MarketPlaceView";
import ManagerView from "./components/ManagerView/ManagerView";
import ProfileView from "./components/ProfileView/ProfileView";
import Navbar from "./components/Navbar";
import LoadingLayer from "./components/LoadingLayer";
import LoginModal from "./components/modal/LoginModal";
import AlertPopup from "./components/modal/AlertPopup";
import MediaForm from "./components/modal/MediaForm";
import DisplayMediaAddModal from "./components/modal/DisplayMediaAddModal";
import LandNameForm from "./components/modal/LandNameForm";
import SceneNameForm from "./components/modal/SceneNameForm";
import LandAccessForm from "./components/modal/LandAccessForm";
import LandBuildingTemplateForm from "./components/modal/LandBuildingTemplateForm";
import LandIndoorTemplateForm from "./components/modal/LandIndoorTemplateForm";
import LandMediaDisplayPositionModal from "./components/modal/LandMediaDisplayPositionModal";
import IndoorMediaDisplayPositionModal from "./components/modal/IndoorMediaDisplayPositionModal";
import LandInitializationForm from "./components/modal/LandInitializationForm";
import MediaUploadModal from "./components/modal/MediaUploadModal";
import ConfirmActionModal from "./components/modal/ConfirmActionModal";
import ProfileModal from "./components/modal/ProfileModal";
import EarlyAccessInfo from "./components/modal/EarlyAccessInfo";
import BuyLandModal from "./components/modal/BuyLandModal";
import MultiScreenModal from "./components/modal/MultiScreenModal";
import NameForm from "./components/modal/NameForm";
import MediaDisplayModal from "./components/modal/MediaDisplayModal";
import MediaFolderSelectModal from "./components/modal/MediaFolderSelectModal";
import MediaDetailsModal from "./components/modal/MediaDetailsModal";
import UserCurrenciesForm from "./components/modal/UserCurrenciesForm";
import SubscriptionForm from "./components/modal/SubscriptionForm";
import WaitingSubscriptionBuyModal from "./components/modal/WaitingSubscriptionBuyModal";

window.onAppViewSelect = null;

export default class extends React.Component {

    state = {
        selectedView: 'marketplace'
    };

    componentDidMount = () => {
        web3.onWalletUpdateSubscribe(this.refresh);
        model.initialize(() => {
            window.refreshMap();
            this.refresh();
        });
        web3.initialize(() => {
            this.refresh();
        });
        setInterval(() => {
            try {
                ReactTooltip.rebuild();
            } catch(e) {
                console.error("Tooltip rebuild error")
            }
        }, 1000);
        window.refreshApp = this.refresh;
        window.onAppViewSelect = this.onViewSelect;
        let info = [
            <p>We are currently performing maintenance on the Metaverse App.</p>,
            <p>The application will be available again soon.</p>,
            <p>The Marketplace and Land management are still operational.</p>,
            <p>VR version still available at the following URL : <a href='https://vr.terminal18.org/'>https://vr.terminal18.org/</a></p>,
            <p></p>,
            <p>Thank you for your patience.</p>
        ]
        setTimeout(() => {
            if(window.location.host === 'app.terminal18.org'){
                window.alertPopup('Metaverse Maintenance', info);
            }
        }, 1000);
    };

    onViewSelect = (view) => {
        if(view !== this.state.selectedView){
            this.setState({selectedView: view});
        }
    };

    refresh = () => {
        this.forceUpdate();
    };

    render () {
        let selected = this.state.selectedView;
        return <div id="app-container">
            <Navbar selectedView={selected} onViewSelect={this.onViewSelect} />
            <MarketPlaceView show={selected === 'marketplace'} />
            <ManagerView show={selected === 'management'}  />
            <ProfileView show={selected === 'profile'} />
            <ReactTooltip id={"application-tooltip"} className={"tool-tip z-depth-1"} effect={"solid"} backgroundColor="white" textColor="black"/>
            <LoadingLayer  />
            <LoginModal/>
            <AlertPopup/>
            <MediaForm/>
            <DisplayMediaAddModal/>
            <SceneNameForm/>
            <LandNameForm/>
            <LandAccessForm/>
            <LandBuildingTemplateForm/>
            <LandIndoorTemplateForm/>
            <LandMediaDisplayPositionModal/>
            <IndoorMediaDisplayPositionModal />
            <LandInitializationForm />
            <MediaUploadModal />
            <ConfirmActionModal />
            <ProfileModal />
            <EarlyAccessInfo />
            <BuyLandModal />
            <MultiScreenModal />
            <NameForm />
            <MediaDisplayModal />
            <MediaFolderSelectModal />
            <MediaDetailsModal />
            <UserCurrenciesForm />
            <SubscriptionForm />
            <WaitingSubscriptionBuyModal />
        </div>
    }
}
