
import * as AWS from 'aws-sdk';
import _ from 'lodash';
import config from './config';

const DEBUG = false;

function log(msg){
    if(DEBUG){
        console.log('aws-s3 : ' + msg);
    }
}

AWS.config.update(config.amazon.credentials);

const bucket_name = config.amazon.buckets.upload,
    s3 = new AWS.S3({ params: { Bucket: bucket_name } });

const DEFAULT_CHUNK_SIZE = Math.pow(1024, 2) * 5,
    DEFAULT_MAX_CONCURRENCY = 5;

const self = {

    createUpload: (key, cb, options = {}) => {
        log('Creating upload : ' + key);
        let params = {
            Key: key
        };
        if(options.bucket){
            params.Bucket = options.bucket;
        }
        s3.createMultipartUpload(params, (err, data) => {
            if (err) return cb(err);
            log('Upload created : ' + data.UploadId);
            return cb(null, data.UploadId);
        });
    },

    abortUpload: (key, uploadId, cb = () => {}, options = {}) => {
        log('Aborting upload : ' + uploadId);
        let params = {
            Key: key, UploadId: uploadId
        };
        if(options.bucket){
            params.Bucket = options.bucket;
        }
        s3.abortMultipartUpload(params, (err, data) => {
            if (err) return cb(err);
            log('Upload aborted : ' + uploadId);
            return cb(null, data);
        });
    },

    completeUpload: (key, uploadId, parts, cb, options = {}) => {
        let params = {
            Key: key,
            UploadId: uploadId,
            MultipartUpload: {
                Parts: _.map(parts, (part) => {return {ETag: part.ETag, PartNumber: part.partNumber}})
            }
        };
        if(options.bucket){
            params.Bucket = options.bucket;
        }
        s3.completeMultipartUpload(params, (err, data) => {
            if (err) return cb(err);
            return cb(null, data);
        });
    },

    uploadPart: (key, uploadId, partNumber, data, cb, options = {}) => {
        let params = {
            Key: key,
            Body: data,
            PartNumber: partNumber, // Any number from one to 10.000
            UploadId: uploadId, // UploadId returned from the first method
        };
        if(options.bucket){
            params.Bucket = options.bucket;
        }
        s3.uploadPart(params, (err, data) => {
            if (err) return cb({err, partNumber});
            return cb(null, {partNumber, ETag: data.ETag});
        });
    },

    uploadFile: (key, file, update_cb, complete_cb, options = {}) => {
        let chunkSize = options.chunk_size || DEFAULT_CHUNK_SIZE,
            fileSize = file.byteLength;
        console.time('uploadFile');
        self.createUpload(key, (err, uploadId) => {
            if(err){
                log('Error while creating upload', err);
                return complete_cb(err);
            }else{
                let parts = [];
                for(let i = 0; i * chunkSize < fileSize; i++) {
                    parts.push({partNumber: i + 1, data: file.slice(i * chunkSize, (i + 1) * chunkSize), status: 'pending'});
                }
                uploadFileParts(key, uploadId, parts, update_cb, (err, parts) => {
                    if(err){
                        log('Error while uploading file parts', err);
                        self.abortUpload(key, uploadId, (abort_err, data) => {
                            if(abort_err) {
                                log('Error while aborting upload', abort_err);
                            }
                            return complete_cb(err)
                        }, options);
                    }else{
                        log('File uploaded successfully');
                        self.completeUpload(key, uploadId, parts, (err, data) => {
                            if(err){
                                log('Error while completing upload', err);
                                self.abortUpload(key, uploadId, (abort_err, data) => {
                                    if(abort_err) {
                                        log('Error while aborting upload', abort_err);
                                    }
                                    return complete_cb(err)
                                }, options);
                            }else{
                                console.timeEnd('uploadFile');
                                return complete_cb(null, data);
                            }
                        }, options);
                    }
                }, options);
            }
        }, options);
    },

    deleteObject: (key, cb, options = {}) => {
        log('Deleting object : ' + key);
        let params = {
            Key: key
        }
        if(options.bucket){
            params.Bucket = options.bucket;
        }
        s3.deleteObject(params, (err, data) => {
            if (err) return cb(err);
            log('Object deleted : ' + key);
            return cb(null, data);
        });
    }

}

function uploadFileParts(key, uploadId, parts, update_cb, complete_cb, options = {}){
    log('Uploading file parts : ' + parts.length);
    let index = 0, current_try_count = 0;
    const process_next = () => {
        if(index >= parts.length) {
            return complete_cb(null, parts);
        }
        let part = parts[index];
        if(part.status === 'pending') {
            self.uploadPart(key, uploadId, part.partNumber, part.data, (err, data) => {
                if(err) {
                    log('Error while uploading part : ' + part.partNumber, err);
                    if(current_try_count < 3) {
                        log('Retrying...');
                        current_try_count++;
                        return process_next();
                    }
                    log('Aborting...');
                    return complete_cb(err);
                }
                log('Part uploaded successfully : ' + part.partNumber);
                current_try_count = 0;
                part.status = 'done';
                part.ETag = data.ETag;
                index++;
                process_next();
                update_cb(Math.round(100 * index / parts.length));
            }, options);
        } else {
            index++;
            process_next();
        }
    }
    process_next();
}

function uploadFilePartsV2(key, uploadId, parts, update_cb, complete_cb, options = {}){
    log('Uploading file parts concurrently : ' + parts.length);
    let processed_count = 0;
    _.asyncMap(parts, (part, cb) => {
        uploadFilePart(key, uploadId, part, (err) => {
            if(err) return cb(err);
            processed_count++;
            log("Part uploaded successfully : " + processed_count);
            update_cb(Math.round(100 * processed_count / parts.length));
            return cb(null);
        }, options);
    }, (err) => {
        if(err){
            return complete_cb(err);
        }else{
            return complete_cb(null, parts);
        }
    }, {max_concurrency: DEFAULT_MAX_CONCURRENCY, throw_error: true});
}

function uploadFilePart(key, uploadId, part, cb, options = {}, try_count = 0){
    log('Uploading part : ' + part.partNumber);
    if(part.status === 'pending') {
        self.uploadPart(key, uploadId, part.partNumber, part.data, (err, data) => {
            if(err) {
                log('Error while uploading part : ' + part.partNumber, err);
                if(try_count < 3) {
                    log('Retrying...');
                    return uploadFilePart(key, uploadId, part, cb, try_count + 1);
                }
                log('Aborting...');
                return cb(err);
            }
            log('Part uploaded successfully : ' + part.partNumber);
            part.status = 'done';
            part.ETag = data.ETag;
            return cb(null);
        }, options);
    } else {
        return cb(null);
    }
}

export default self;

window.aws_s3 = self;