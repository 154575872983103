import React from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import generic from '../../model/generic';

export default class ModalModel extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openSubscriptionForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function () { }) => {
        let state = { opened: true, name: "", price: 0, unit_time: 2592000, undismissible: options.undismissible, close_cb: close_cb };
        if (options.action === 'edit') {
            state.action = 'edit';
            state.subscription_id = options.subscription_id;
            let subscription = generic.getEntity('subscription', options.subscription_id);
            state.name = subscription.name;
            state.price = subscription.price;
            state.unit_time = subscription.unit_time;
        }else{
            state.action = 'create';
            state.subscription_id = null;
        }
        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onNameChange = (value) => {
        this.setState({ name: value });
    };

    onPriceChange = (value) => {
        this.setState({ price: value });
    };

    onUnitTimeChange = (value) => {
        this.setState({ unit_time: value });
    };

    onSubmit = () => {
        let name = this.state.name, 
            price = parseFloat(this.state.price), 
            unit_time = parseInt(this.state.unit_time),
            action = this.state.action;
        if(action === 'edit'){
            generic.updateEntity('subscription', this.state.subscription_id, {name: name, price: price, unit_time: unit_time}, (err) => {
                this.state.closeModal();
                if(err){
                    window.alertPopup('Error', 'An error has occurred while updating subscription.');
                }
            });
        }else{
            generic.createEntity('subscription', {name: name, price: price, unit_time: unit_time}, (err) => {
                this.state.closeModal();
                if(err){
                    window.alertPopup('Error', 'An error has occurred while creating subscription.');
                }
            });
        }
    };

    render() {
        let disabled = false, content;
        if (this.state.opened) {
            let action = this.state.action, 
                name = this.state.name, 
                price = this.state.price,
                unit_time = this.state.unit_time;
            if(name.length === 0 || isNaN(parseFloat(price))){
                disabled = true;
            }
            content = <div id="subscription-form-content" className="reglisse">
                <h3 style={{ margin: 0 }}>{(action === 'edit' ? 'Edit' : 'Create') + ' subscription'}</h3>
                <div style={{ marginTop: '50px' }}>
                    <InputField label={'Subscription Name'} value={name} onChange={this.onNameChange} />
                </div>
                <div style={{ marginTop: '50px' }}>
                    <InputField label={'Price ( $ )'} value={price} onChange={this.onPriceChange} 
                        type='number' step={0.01} min={0} max={1000} />
                </div>
                <div style={{ marginTop: '50px' }}>
                    <span>Duration: </span>
                    <CheckBox label={'1 week'} checked={unit_time === 604800} onClick={this.onUnitTimeChange.bind(null, 604800)} />
                    <CheckBox label={'1 month'} checked={unit_time === 2592000} onClick={this.onUnitTimeChange.bind(null, 2592000)} />
                    <CheckBox label={'1 year'} checked={unit_time === 31536000} onClick={this.onUnitTimeChange.bind(null, 31536000)} />
                </div>
                <div className="modal-footer">
                    <Button text={action === 'edit' ? 'Submit' : 'Create'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{ fontSize: "20px" }} />
                    <Button text={"Cancel"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                        style={{ fontSize: "20px" }} />
                </div>
            </div>;
        }
        return (
            <Modal id="subscription-form"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={this.state.undismissible}
            />
        )
    }
};
