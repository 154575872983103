import React from 'react';
import moment from "moment";
import User from "../../model/user";
import Config from '../../config';

import web3 from "../../web3";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import LoadingAnimation from "../global/LoadingAnimation";

import metamask_icon from "../../images/wallet_icons/metamask.png";
import ledger_icon from "../../images/wallet_icons/ledger.png";
import coinbase_icon from "../../images/wallet_icons/coinbase.png";
import wallet_connect_icon from "../../images/wallet_icons/wallet_connect.png";

const NETWORK = Config.contract.network_name;

export default class LoginModal extends React.Component {

    state = {opened: false};

    componentDidMount = () => {
        window.openLoginModal = this.openModal;
    };

    getOpen = (open) => {
        this.setState({openModal: open});
    };

    openModal = (undismissible = false, close_cb = () => {}) => {
        this.setState({
            type: null,
            opened: true,
            undismissible: undismissible,
            close_cb: close_cb
        }, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({closeModal: close});
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onCredentialsLogin = () => {
        this.setState({type: 'credentials'})
    }

    onWalletLogin = () => {
        this.setState({type: 'wallet'})
    };

    render = () => {
        let type = this.state.type;
        let content = this.state.opened ? <div className="reglisse">
            <h2 className="center-align">Log In</h2>
            {!type ? <div>
                <div className="center-align">
                    <Button className="t18-btn" style={{
                        fontSize: "30px",
                        height: "58px",
                        padding: "10px 20px",
                        borderRadius: "15px",
                        marginTop: "15px"
                    }}
                            text={"Credentials"} onClick={this.onCredentialsLogin}/>
                </div>
                <div className="center-align tm">
                    <Button className="t18-btn" style={{
                        fontSize: "30px",
                        height: "58px",
                        padding: "10px 20px",
                        borderRadius: "15px",
                        marginTop: "15px"
                    }}
                            text={"Use Wallet"} onClick={this.onWalletLogin}/>
                </div>
            </div> : undefined}
            {type === 'wallet' && <WalletSignIn close={this.state.closeModal}/>}
            {type === 'credentials' && <CredentialSignIn close={this.state.closeModal}/>}
            <div className="modal-footer">
                <Button className='t18-btn lm black' text="Cancel" style={{fontSize: "20px"}}
                        onClick={this.state.closeModal} large={true}/>
            </div>
        </div> : undefined;

        return (
            <Modal id="login-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.state.close_cb}
                   undismissible={this.state.undismissible}
            />
        )
    }

}

class CredentialSignIn extends React.Component {

    state = {email: "", password: ""};

    onEmailChange = (value) => {
        this.setState({email: value});
    }

    onPasswordChange = (value) => {
        this.setState({password: value});
    }

    onSubmit = () => {
        let email = this.state.email, password = this.state.password;
        User.signInWithCredentials(email, password, (err) => {
            if (err) {
                console.log('Signin Error : ' + JSON.stringify(err));
                let msg = 'An error has occurred while signing in with wallet.';
                if (err.message === 'Not authenticated : invalid-credentials') {
                    msg = 'Invalid credentials.';
                }
                window.alertPopup('Signin error', msg)
            } else {
                this.props.close();
                window.refreshApp();
            }
        })
    }

    render() {
        return <div>
            <div className="center-align tm">
                <div className="center-align tm">
                    <h4 style={{display: 'inline-block'}}>Credentials sign in</h4>
                </div>
                <div className="center-align tm">
                    <InputField style={{display: 'inline-block', width: '300px'}} label="Email" type="email"
                                onChange={this.onEmailChange}/>
                </div>
                <div className="center-align tm">
                    <InputField style={{display: 'inline-block', width: '300px'}} label="Password" type="password"
                                onChange={this.onPasswordChange}/>
                </div>
                <div className="center-align tm">
                    <Button className="t18-btn tm" text="Sign In" onClick={this.onSubmit}/>
                </div>
            </div>
        </div>
    }

}

const wallet_list = [
    {name: 'Metamask', icon: metamask_icon, id: 'MetaMask'},
    {name: 'Coinbase', icon: coinbase_icon, id: 'Coinbase'},
    {name: 'WalletConnect', icon: wallet_connect_icon, id: 'WalletConnect'},
    //{name: 'Ledger', icon: ledger_icon, id: 'Ledger'}
]

class WalletSignIn extends React.Component {

    refresh_interval = null;

    wrong_network = false;

    connecting = false;

    componentDidMount = () => {
        this.refresh_interval = setInterval(this.refresh, 500);
    };

    componentWillUnmount = () => {
        clearInterval(this.refresh_interval);
    };

    refresh = () => {
        this.forceUpdate();
    };

    onConnect = (wallet_id) => {
        if (wallet_id) {
            this.connecting = true;
            this.refresh();
            web3.connectWallet(wallet_id).then(() => {
                if(web3.network_name !== NETWORK) {
                    this.wrong_network = true;
                    this.connecting = false;
                    this.refresh();
                }else{
                    User.signInWithWallet((err) => {
                        this.connecting = false;
                        if (err) {
                            console.log('Signin Error : ', err);
                            if(err.reason === 'user rejected signing'){
                                window.alertPopup('Signin error', 'You have rejected the auth signing. Please try again.')
                            }else{
                                window.alertPopup('Signin error', 'An error has occurred while signing in with wallet.')
                            }
                        } else {
                            this.props.close();
                            window.refreshApp();
                        }
                    })
                }
            }).catch((err) => {
                this.connecting = false;
                if(err.toString() === 'Error: User closed modal'){
                    web3.resetWallet("WalletConnect");
                    return this.refresh();
                }
                if(wallet_id === 'Ledger'){
                    window.alertPopup('Wallet error', 'It seems no Ledger device is connected. Please connect your Ledger device and try again. If this error persists, please contact our support on our Discord server.')
                    return this.refresh();
                }
                console.log('Signin Error : ', err);
                console.log(err);
                window.test_err = err;
                window.alertPopup('Wallet error', 'An error has occurred while connecting wallet.');
            });
        }
    };

    renderConnectButtons = () => {
        let buttons = [];
        for(let wallet of wallet_list) {
            buttons.push(<div className="center-align tm">
                <Button className="t18-btn" style={{
                    fontSize: "30px",
                    height: "58px",
                    padding: "10px 20px",
                    borderRadius: "15px",
                    marginTop: "15px",
                    width: '320px',
                    textAlign: 'left'
                }}
                        text={<span>
                            <img style={{height: '40px', padding: '5px', backgroundColor: 'white', borderRadius: '5px', marginRight: '15px'}}
                                         src={wallet.icon} />
                            <span style={{position: 'relative', bottom: '10px'}}>{wallet.name}</span>
                        </span>}
                        onClick={this.onConnect.bind(null, wallet.id)}
                        disabled={web3.getWallet(wallet.id) === null}
                />
            </div>)
        }
        return buttons;
    }

    render() {
        return <div>
            <div className="center-align tm">
                <h4 style={{display: 'inline-block'}}>Wallet authentication</h4>
            </div>
            {this.wrong_network ? <WrongNetworkDisplay/> : undefined}
            {this.connecting ? <AwaitingWalletConnection/> : <div>
                {this.renderConnectButtons()}
            </div>}
        </div>
    }

}

class NoWalletDisplay extends React.Component {

    render() {
        return <div>
            <h5>No wallet seems to be installed.</h5>
            <h5>Install MetaMask or another wallet browser extension and reload the page.</h5>
        </div>
    }

}

class AwaitingWalletConnection extends React.Component {

    render() {
        return <div>
            <h5>Waiting for wallet connection ...</h5>
            <LoadingAnimation/>
        </div>
    }

}

class WrongNetworkDisplay extends React.Component {

    render() {
        return <div className="center-align red-text">
            <h5 className="inline-block">{"Your wallet is connected to the wrong network (" + web3.network_name + ")."}</h5>
            <h5 className="inline-block">{"Connect it to the " + NETWORK + " network then refresh the page."}</h5>
        </div>
    }

}