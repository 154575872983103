import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";

import SceneTemplateTopViewDisplay from "../Common/SceneTemplateTopViewDisplay";
import Scene from "../../model/Scene";
import Templates from "../../model/Templates";

export default class IndoorMediaDisplayPositionModal extends React.Component {

  state = { opened: false, openModal: null, closeModal: null };

  componentDidMount() {
    window.openIndoorMediaDisplayPositionModal = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  openModal = (options, close_cb = function() {}) => {
    let state = { opened: true, scene_id: options.scene_id, screen_id: options.screen_id, link_id: options.link_id,
        multi_screen_id: options.multi_screen_id, undismissible: options.undismissible, close_cb: close_cb };

    this.setState(state, this.state.openModal);
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
  };

  afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
  };

  render() {
    let disabled = false, content;
    if(this.state.opened){
      let scene = Scene.getScene(this.state.scene_id), show_screens = [], show_multi_screens = [], show_links = [],
          template = Templates.getSceneTemplate(scene.scene_template_id);
      if(this.state.screen_id){
          show_screens.push(Scene.getSceneEntityData(this.state.scene_id, 'screen', this.state.screen_id));
      }
      if(this.state.multi_screen_id){
        show_multi_screens.push(Scene.getSceneEntityData(this.state.scene_id, 'multi_screen', this.state.multi_screen_id));
      }
      if(this.state.link_id){
        show_links.push(Scene.getSceneEntityData(this.state.scene_id, 'link', this.state.link_id));
      }
      content = <div id="indoor-media-display-position-content" className="reglisse">
        <h3 style={{margin: 0}}>{"Scene screen position"}</h3>
        <div className="tm" style={{display: 'inline-block', width: "60%", marginLeft: '20%'}}>
            <SceneTemplateTopViewDisplay template={template} showScreens={show_screens} showMultiScreens={show_multi_screens} showLinks={show_links} />
        </div>
        <div className="modal-footer">
          <Button text={"Close"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                  style={{fontSize: "20px"}}/>
        </div>
      </div>;
    }
    return (
      <Modal id="indoor-media-display-position-modal"
             content={content}
             getOpen={this.getOpen}
             getClose={this.getClose}
             afterOpen={this.afterOpen}
             afterClose={this.afterClose}
             undismissible={this.state.undismissible}
      />
    )
  }
};
