import generic from "./generic";
import User from "./user";
import _ from "lodash";

const Templates = {

    initialize: (cb = () => {}) => {
        generic.loadEntities('land_template', (err) => {
            if(err){
                return cb(err);
            }
            generic.loadEntities('scene_template', cb);
        });
    },

    getLandTemplate: (id) => {
        return generic.getEntity('land_template', id);
    },

    getLandTemplates: (as_array) => {
        return generic.getEntities('land_template', as_array);
    },

    getSceneTemplate: (id) => {
        return generic.getEntity('scene_template', id);
    },

    getSceneTemplates: (as_array) => {
        let templates = generic.getEntities('scene_template', true);
        if(!User.isAdmin()){
            templates = _.filter(templates, (template) => {
                return !template.admin;
            });
        }
        return as_array ? templates : _.keyBy(templates, 'id');
    },

    getDefaultLandTemplate: () => {
        let templates = Templates.getLandTemplates(true);
        return templates.length > 0 ? templates[0] : null;
    },

    getDefaultSceneTemplate: () => {
        let templates = Templates.getSceneTemplates(true);
        return templates.length > 0 ? templates[0] : null;
    }

}
export default Templates;

window.Templates = Templates;