import React from "react";

import Land from "../../../model/Land";
import Templates from "../../../model/Templates";
import Dev from '../../../model/Dev';

import logo_t18 from '../../../images/logo512.png';

export default class extends React.Component {

    render() {
        let lands = Land.getOwnedLands(), display = [];
        for (let land of lands) {
            display.push(<LandCard land={land} onClick={this.props.onSelect} isSelected={this.props.selected === land.id} />)
        }
        return <div >
            <div className="center-align white-text">
                <h4 className="inline-block">Lands</h4>
            </div>
            <div>
                {display}
            </div>

        </div>
    }

}

class LandCard extends React.Component {

    render() {
        let land = this.props.land;
        let land_template = Templates.getLandTemplate(land.land_template_id);
        return <div className="col s6 reglisse" style={{ padding: "20px" }}>
            <div className={"land-card" + (this.props.isSelected ? ' selected' : '')} onClick={this.props.onClick.bind(null, land.id)}
                style={this.props.isSelected ? {} : {/*margin: '5px'*/ }}>
                <div style={{ fontSize: '16px' }}>
                    <span>{'X : ' + land.position.x}</span>
                    <span>{' | Y : ' + land.position.y}</span>
                    <span>{' (Zone ' + land.zone + ')'}</span>
                </div>
                <img style={{ maxHeight: "200px", width: "100%" }} src={(land_template && land_template.preview_url) || logo_t18} />
                <div className="land-card-name">{land.name || 'Unnamed'}</div>
            </div>
        </div>
    }

}