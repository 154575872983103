import React from "react";
import Button from "../global/Button";
import Land from "../../model/Land";
import Scene from "../../model/Scene";
import generic from "../../model/generic";

import LoadingAnimation from "../global/LoadingAnimation";
import SceneToolBox from "./LandView/SceneToolBox";
import EntityLoader from "../Common/EntityLoader";
import ScreenEditor from "../Common/ScreenEditor";
import SceneTemplateTopViewDisplay from "../Common/SceneTemplateTopViewDisplay";

const demo_scene_names = {
    Indoor_1 : 'demo_scene_indoor_1',
    Indoor_3 : 'demo_scene_indoor_3',
    Indoor_4 : 'demo_scene_indoor_4',
}

export default class extends React.Component {

    state = {selected_demo_scene: 'Indoor_1', scenes_loaded: false};

    componentDidMount() {
        generic.addEntityListener('scene', () => {this.forceUpdate()});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show && !prevProps.show && !this.state.scenes_loaded){
           Scene.loadAllScenes(() => {this.setState({scenes_loaded: true})});
        }
    }

    onSceneSelect = (scene) => {
        this.setState({selected_demo_scene: scene});
    };

    getSceneName = (scene_id) => {

    };

    render() {
        let scenes_loaded = this.state.scenes_loaded, selected_scene = this.state.selected_demo_scene, scene_id;
        if(scenes_loaded){
            let scene = generic.getEntityFromProperty('scene', 'name', demo_scene_names[selected_scene]);
            if(scene && scene.id){
                scene_id = scene.id;
            }
        }
        return <div className={"row" + (this.props.show ? '' : ' hide')} style={{color: 'white', paddingTop: '30px'}}>
            {scenes_loaded && scene_id ? <div>
                <div className="center-align">
                    <Button className={"view-select-btn" + (selected_scene === 'Indoor_1' ? ' selected': '')} text="Indoor_1"
                            onClick={this.onSceneSelect.bind(null, 'Indoor_1')} />
                    <Button className={"view-select-btn lm" + (selected_scene === 'Indoor_3' ? ' selected': '')} text="Indoor_3"
                            onClick={this.onSceneSelect.bind(null, 'Indoor_3')} />
                    <Button className={"view-select-btn lm" + (selected_scene === 'Indoor_4' ? ' selected': '')} text="Indoor_4"
                            onClick={this.onSceneSelect.bind(null, 'Indoor_4')} />
                </div>
                <div className="row">
                    <div className="col s8 offset-s2">
                         <ToolBox selected={scene_id} disableTemplateEdit={true} />
                    </div>
                </div>
            </div> : <LoadingAnimation type="circular" size="big" />}
        </div>
    }

}

class ToolBox extends React.Component {

    componentDidMount() {
        window.printSelectedScene = () => {
            console.log(generic.getEntity('scene', this.props.selected));
        }
    }

    onEditName = () => {
        window.openSceneNameForm({scene_id: this.props.selected});
    };

    onEditTemplate = () => {
        window.openLandIndoorTemplateForm({scene_id: this.props.selected});
    };

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let scene = Scene.getScene(this.props.selected);
        if(!scene){
            return <EntityLoader entity='scene' id={this.props.selected} onLoad={this.refresh} />;
        }
        let template = Templates.getSceneTemplate(scene.scene_template_id),
            screens = _.get(scene, 'data.screens', []),
            multi_screens = _.get(scene, 'data.multi_screens', []),
            links = _.get(scene, 'data.links', []),
            screen_display = [], multi_screen_display = [], link_display = [];
        for(let screen of screens){
            screen_display.push(<ScreenEditor entity_type="screen" entity_id={screen.id} scene_id={this.props.selected} screen_type="scene" />);
        }
        for(let multi_screen of multi_screens){
            multi_screen_display.push(<ScreenEditor entity_type="multi_screen" entity_id={multi_screen.id} scene_id={this.props.selected} screen_type="scene" />);
        }
        for(let link of links){
            let link_data = generic.getEntity('link', link.id);
            if(link_data && link_data.type !== 'vid360'){
                continue;
            }
            link_display.push(<ScreenEditor entity_type="link" entity_id={link.id} scene_id={this.props.selected} screen_type="scene" />);
        }
        return <div className={"row"} style={{color: 'white', paddingTop: '30px'}}>
            <div style={{marginLeft: '50px'}}>
                <h4 className="inline-block rm">{'Scene name : ' + (scene.name || 'Unnamed')}</h4>
                {!this.props.disableTemplateEdit && <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}} onClick={this.onEditName}
                                                            text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} />}
                {!this.props.disableTemplateEdit && <Button className="t18-btn lm" color="red"
                                                            style={{position: 'relative', bottom: '6px'}} disabled={scene.land_root}
                                                            text="Remove floor" onClick={this.props.onRemoveFloor} />}
            </div>
            <div style={{marginLeft: '50px'}}>
                <div>
                    <h4 className="inline-block rm">Scene template</h4>
                    {!this.props.disableTemplateEdit ? <Button className="t18-btn utils-btn lm" style={{position: 'relative', bottom: '6px'}} onClick={this.onEditTemplate}
                                                               text={<i className="material-icons" style={{position: 'relative', bottom: '3px'}}>edit</i>} /> : undefined}
                </div>
                <div className="tm">
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px',
                        height: '300px', padding: '32px', verticalAlign: 'top'}}>
                        <img style={{height: "100%"}} src={template.preview_url} />
                    </div>
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px', width: '300px',
                        height: '300px', padding: '32px', marginLeft: '40px', verticalAlign: 'top'}}>
                        <SceneTemplateTopViewDisplay template={template} showAllScreens={true} showAllMultiScreens={true} showAllLinks={true}
                                                     screen_display_size={{width: 40, height: 15}} link_display_size={{width: 40, height: 15}} />
                    </div>
                </div>
            </div>
            <div className="row" style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Scene screens</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {screen_display}
                </div>
            </div>
            <div className="row" style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Scene media doors</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {link_display}
                </div>
            </div>
            <div className="row" style={{marginLeft: '50px'}}>
                <div className=""><h4 className="inline-block">Scene multi screens</h4></div>
                <div style={{marginLeft: '-0.75rem', paddingRight: "50px"}}>
                    {multi_screen_display}
                </div>
            </div>
        </div>
    }

}
