import React from "react";

import Land from "../../../model/Land";

import LandSelector from "./LandSelector";
import LandToolBox from "./LandToolBox";

window.onLandManagementViewLandSelect = null;

export default class extends React.Component {

    state = {selected_land: null};

    componentDidMount = () => {
        Land.onLandUpdateSubscribe(this.refresh);
        window.onLandManagementViewLandSelect = this.onLandSelect;
        window.printSelectedLand = () => {
            console.log(Land.getLand(this.state.selected_land));
        }
    };

    refresh = () => {
        this.forceUpdate();
    };

    onLandSelect = (id) => {
        Land.refreshLand(id);
        this.setState({selected_land: id});
    };

    render() {
    return <div id="manager-land-view" className={"row" + (this.props.show ? '' : ' hide')} style={{backgroundColor: 'black'}}>
      <div className="col s8" style={{borderRight: '2px solid white'}}>
        <LandToolBox selected={this.state.selected_land} />
      </div>
      <div className="col s4" style={{borderLeft: '2px solid white', marginLeft: '-2px'}}>
        <LandSelector selected={this.state.selected_land} onSelect={this.onLandSelect}  />
      </div>
    </div>
    }

}