import React from "react";

import Media from "../../../model/Media";

import Button from "../../global/Button";

export default class extends React.Component {

  onAddMedia = () => {
    window.openMediaForm({action :'add'});
  };

  onUploadMedia = () => {
      window.openMediaUploadModal();
  };

  onEditMedia = () => {
    window.openMediaForm({action :'edit', target_id: this.props.selected});
  };

  onDeleteMedia = () => {
      window.openConfirmActionModal({
          action_text: "Do you really want to delete this media ?",
          confirm_cb: () => {
              generic.deleteEntity("media", this.props.selected, (err) => {
                  if(err){
                      window.alertPopup('Error', 'An error has occurred while deleting the media.');
                  }
              });
          }
      });
  };

  render() {
    let media, access;
    if(this.props.selected){
      media = Media.getMedia(this.props.selected);
      access = media.access || {type: 'free'};
    }
    return <div style={{paddingLeft: '50px'}}>
            <div className=''>
                {User.isAdmin() && <Button className="t18-btn" style={{fontSize: "30px", height: "58px", padding: "10px 20px", borderRadius: "15px", marginTop: "15px"}}
                         text={"Add Media"} onClick={this.onAddMedia}  />}
                <Button className="t18-btn" style={{fontSize: "30px", height: "58px", padding: "10px 20px", borderRadius: "15px", marginTop: "15px", marginLeft: '15px'}}
                        text={"Upload Media"} onClick={this.onUploadMedia}  />
            </div>
            {media ? <div className='white-text reglisse' style={{marginTop: '40px'}}>
              <div className="media-toolbox-text"><span>Name : </span><span>{media.name}</span></div>
              <div className="media-toolbox-text"><span>Type : </span><span>{media.type}</span></div>
                {media.hosting === 'external' ? <div className="media-toolbox-text"><span>Url : </span><span>{media.url}</span></div> : null}
                {media.hosting === 'internal' ? <div className="media-toolbox-text"><span>Filename : </span><span>{media.filename}</span></div> : null}
              {media.preview_url ? <div className="media-toolbox-text"><span>Preview url : </span><span>{media.preview_url}</span></div> : undefined}
              <div className="media-toolbox-text"><span>Access : </span><span>{access.type.upperFirstLetter()}</span></div>
              {access.type === 'buy' || access.type === 'rent' ? <div className="media-toolbox-text">
                <span>Price : </span><span>{media.access.price + ' €'}</span>
              </div> : undefined}
              {access.type === 'rent' ? <div className="media-toolbox-text">
                <span>Renting time : </span><span>{access.renting_time > 24 ? (Math.round(access.renting_time / 24) + " days") : (access.renting_time + " hours")}</span>
              </div> : undefined}
              <div className={''} style={{marginTop: '30px'}}>
                <Button className="t18-btn" style={{fontSize: "30px", height: "58px", padding: "10px 20px", borderRadius: "15px", marginTop: "15px"}}
                        text={"Edit Media"} onClick={this.onEditMedia}  />
                  <Button className="t18-btn" color="black" style={{fontSize: "30px", height: "58px", padding: "10px 20px",
                      borderRadius: "15px", marginTop: "15px", marginLeft: '10px', border: '2px solid white'}}
                          text={"Delete Media"} onClick={this.onDeleteMedia}  />
              </div>
            </div> : <h5 className='white-text'>
              No selected media
            </h5>}

    </div>
  }

}