import React  from 'react';
import _ from 'lodash';

import Media from "../../model/Media";
import config from "../../config";
import media_info from "../../media_info";

import Modal from "../global/Modal";
import Button from "../global/Button";
import CheckBox from "../global/CheckBox";
import DataTable from "../global/DataTable";

const DEBUG = false;

export default class MediaUploadModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, files: [], uploading: false };

    _inputRef;

    componentDidMount() {
        window.openMediaUploadModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { opened: true, undismissible: options.undismissible, close_cb: close_cb, files: [], uploading: false, path: options.path  };
        if(this._inputRef)
            this._inputRef.value = "";
        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onFileInputClick = () => {
        this._inputRef.click()
    };

    onFileInputChange = () => {
        if(this._inputRef == null || this._inputRef.value === "")
            return;
        const new_files = this._inputRef.files, files = this.state.files.slice();
        if(new_files.length > 0) {
            _.asyncMap(new_files, (file, cb) => {
                if(!_.find(files, {name: file.name})){
                    let ext = utils.getFileExtension(file);
                    if(['mp4', 'webm', 'avi', 'mkv'].indexOf(ext) > -1) {
                        let error;
                        media_info.getMediaFileMetadata(file, (err, metadata) => {
                            if(err){
                                console.log("Error while getting media file metadata : " + err);
                                error = 'metadata-parsing';
                            }else if(DEBUG){
                                console.log("Media file metadata : " + JSON.stringify(metadata));
                            }
                            if(file.size > config.max_video_size){
                                error = 'max-size';
                            }
                            files.push({file: file, name: file.name, type: "video", size: file.size, metadata: metadata, error: error});
                            return cb();
                        });
                    }else if(['jpg', 'jpeg', 'png'].indexOf(ext) > -1) {
                        files.push({file: file, name: file.name, type: "picture", size: file.size});
                        return cb();
                    }else{
                        files.push({file: file, name: file.name, type: "unsupported", size: file.size, error: 'unsupported-format'});
                        return cb();
                    }
                }
            }, () => {
                this.setState({files: files}, () => {
                    this._inputRef.value = "";
                });
            });
        }
    };

    onRemoveFile = (index) => {
        let files = this.state.files.slice();
        files.splice(index, 1);
        this.setState({files: files});
    };

    onUpload = () => {
        let upload_count = 0;
        for(let i = 0; i < this.state.files.length; i++){
            let file = this.state.files[i];
            if(!file.uploaded && !file.error && !file.uploading){
                this.onStartFileUpload(i);
                upload_count++;
            }
        }
        if(upload_count > 0)
            this.setState({uploading: true});
    };

    onStartFileUpload = (index) => {
        let files = this.state.files.slice(), file = files[index], path = this.state.path;
        file.uploading = true;
        file.progress = 0;
        if(file.type === 'picture'){
            //return test(file.file);
            getResizedImage(file.file, (resized_image, width, height) => {
                file.file = new File([resized_image], file.file.name);
                let metadata = {width: width, height: height};
                this.setState({files: files});
                Media.uploadMedia(file.type, file.file, metadata, path, this.onFileUploadComplete.bind(this, index), this.onFileUploadProgress.bind(this, index));
            });
        }else{
            this.setState({files: files});
            Media.uploadMedia(file.type, file.file, file.metadata, path, this.onFileUploadComplete.bind(this, index), this.onFileUploadProgress.bind(this, index));
        }
    };

    onFileUploadProgress = (index, progress) => {
        let files = this.state.files.slice(), file = files[index];
        file.progress = progress;
        this.setState({files: files});
    };

    onFileUploadComplete = (index, err) => {
        let files = this.state.files.slice(), file = files[index];
        file.uploading = false;
        if(err){
            file.upload_error = true;
        }else{
            file.uploaded = true;
        }
        let new_state = {files: files};
        if(!_.find(files, {uploading: true})){
            new_state.uploading = false;
        }
        this.setState(new_state);
    };

    onFileTypeChange = (index, type) => {
        let files = this.state.files.slice(), file = files[index];
        file.type = type;
        this.setState({files: files});
    };

    getFileTypeDisplay = (index) => {
        let file = this.state.files[index], type = file.type;
        if(type === "picture"){
            return "Picture";
        } else if(type === "video" || type === "video_360"){
            return <div>
                <CheckBox label={"Video"} checked={file.type === "video"} onClick={this.onFileTypeChange.bind(this, index, 'video')} disabled={file.uploading || file.uploaded} />
                <CheckBox label={"Video 180/360"} checked={file.type === "video_360"} onClick={this.onFileTypeChange.bind(this, index, 'video_360')} disabled={file.uploading || file.uploaded} />
            </div>
        }
        return "Unknown";
    };

    getErrorText = (error) => {
        if(error === 'unsupported-format'){
            return "Unsupported format";
        }else if(error === 'max-size'){
            return "Max size exceeded (4 GB)";
        }else if(error === 'metadata-parsing'){
            return "Error while parsing metadata";
        }
        return "Unknown error";
    }

    onCancel = () => {
        this.state.closeModal();
    };

    render() {
        let upload_disabled = true, content;
        if(this.state.opened){
            let files = this.state.files, rows = [], i = 0, uploading = this.state.uploading;
            if(!uploading && files.length > 0){
                for(let file of files){
                    if(!file.uploaded && !file.error){
                        upload_disabled = false;
                        break;
                    }
                }
            }
            for(let file of files){
                let row = [file.name];
                if(file.error === 'unsupported-format' || file.error === 'metadata-parsing'){
                    row.push(<span className={"red-text"}>{this.getErrorText(file.error)}</span>);
                }else{
                    row.push(this.getFileTypeDisplay(i));
                }
                if(file.error === 'max-size'){
                    row.push(<span className={"red-text"}>{this.getErrorText(file.error)}</span>);
                }else{
                    row.push(utils.formatBytes(file.size));
                }
                if(uploading){
                    if(file.uploading){
                        row.push(<span className={"green-text"}>{file.progress + "%"}</span>)
                    }else if(file.upload_error){
                        row.push(<span className={"red-text"}>{"Error"}</span>)
                    }else if(file.uploaded){
                        row.push(<i className={"material-icons green-text"}>check</i>)
                    }
                }else{
                    if(file.uploaded){
                        row.push(<i className={"material-icons green-text"}>check</i>)
                    }else{
                        row.push(<i className="material-icons red-text clickable" onClick={this.onRemoveFile.bind(null, i)}>clear</i>);
                    }
                }
                rows.push({data: row});
                i++;
            }
            let file_display = rows.length > 0 ? <DataTable headers={{headers: ["Name", "Type", "Size", ""]}} rows={rows} /> : <h5>Select files to upload</h5>;
            content = <div id="media-upload-modal-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Upload Medias"}</h3>
                <div>
                    <div>
                        {file_display}
                    </div>
                    <div className="center-align tm">
                        <Button text={"Import"} className={"t18-btn"}
                                style={{ marginBottom: ".25em", fontSize: "20px" }}
                                onClick={this.onFileInputClick} disabled={uploading}/>
                        <input ref={c => this._inputRef = c}
                               type="file" onChange={this.onFileInputChange} style={{ display: "none" }} multiple/>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button text={'Upload'} className='t18-btn' onClick={this.onUpload} disabled={upload_disabled} style={{fontSize: "20px"}} />
                    <Button text={"Close"} className='t18-btn lm black' onClick={this.onCancel} large={true}
                            style={{fontSize: "20px", marginLeft: '15px', backgroundColor: 'black'}} disabled={uploading} />
                </div>
            </div>;
        }
        return (
            <Modal id="media-upload-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={true}
            />
        )
    }
};

function test(file){
    getResizedImage(file, (blob) => {
        downloadBlob(blob, "test.png");
    })
}

function getTargetSize(width, height){
    let max = Math.max(width, height), size;
    for(let i = 0; i < config.picture_formats.length; i++){
        size = config.picture_format_sizes[config.picture_formats[i]];
        if(max <= size){
            return i === 0 ? size : config.picture_format_sizes[config.picture_formats[i - 1]];
        }
    }
    return size;
}

function getResizedImage(file, cb){
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    createImageBitmap(file).then(bitmap => {
        const { width, height } = bitmap;
        let target_size = getTargetSize(width, height),
            ratio = Math.min(target_size / width, target_size / height);
        canvas.width = width * ratio;
        canvas.height = height * ratio;

        const x = (canvas.width - (width * ratio)) / 2
        const y = (canvas.height - (height * ratio)) / 2

        ctx.drawImage(bitmap, 0, 0, width, height, x, y, width * ratio, height * ratio)

        canvas.toBlob(blob => {
            cb(blob, canvas.width, canvas.height);
        }, 'image/png', 1)
    })
}

function downloadBlob(blob, name){
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}