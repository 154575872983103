import React  from 'react';

import Land from "../../model/Land";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";


const RENTING_TIME_VALUES = [
  {d: 1, t: 'h'}, {d: 2, t: 'h'}, {d: 3, t: 'h'}, {d: 6, t: 'h'}, {d: 12, t: 'h'},
  {d: 1, t: 'd'}, {d: 2, t: 'd'}, {d: 7, t: 'd'}, {d: 14, t: 'd'}, {d: 30, t: 'd'}
];

export default class ModalModel extends React.Component {

  state = { opened: false, openModal: null, closeModal: null };

  componentDidMount() {
    window.openLandAccessForm = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  openModal = (options, close_cb = function() {}) => {
    let state = { opened: true, land_id: options.land_id, undismissible: options.undismissible, close_cb: close_cb };
    let land_data = Land.getLandData(options.land_id);
    state.access_type = (land_data.access && land_data.access.type) || 'free';
    state.price = (land_data.access && land_data.access.price) || 1;
    state.renting_time = (land_data.access && land_data.access.renting_time) || 1;
    this.setState(state, this.state.openModal);
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
  };

  afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
  };

  onAccessTypeClick = (type) => {
    this.setState({access_type: type});
  };

  onPriceChange = (value) => {
    this.setState({price: value});
  };

  onRentingTimeClick = (duration, duration_type) => {
    let renting_time = parseInt(duration);
    if(duration_type === 'd'){
      renting_time *= 24;
    }
    this.setState({renting_time});
  };

  onSubmit = () => {
    let land_data = Land.getLandData(this.state.land_id), access_type = this.state.access_type, price = this.state.price, renting_time = this.state.renting_time;
    let access = {type: access_type};
    if(access_type === 'buy' || access_type === 'rent'){
      access.price = price;
    }
    if(access_type === 'rent'){
      access.renting_time = renting_time;
    }
    land_data.access = access;
    Land.onLandUpdate();
    this.state.closeModal();
  };

  getRentingTimeButtons = () => {
    let renting_time = this.state.renting_time, buttons = [];
    for(let value of RENTING_TIME_VALUES){
      let duration = value.d * (value.t === 'd' ? 24 : 1), selected = duration === renting_time;
      buttons.push(<Button className={"t18-btn utils-btn" + (selected ? "" : " grey")} text={value.d + '' + value.t} style={{margin: '5px'}}
                           onClick={this.onRentingTimeClick.bind(null, value.d, value.t)} />)
    }
    return buttons;
  };

  render() {
    let disabled = false, access_type = this.state.access_type, price = this.state.price;
    const content = this.state.opened ? <div id="land-access-form-content" className="reglisse">
      <h3 style={{margin: 0}}>{"Edit Land Access"}</h3>
      <div style={{fontSize: '22px'}}>
        <div className="bold" >Access : </div>
        <div><CheckBox label={'Free'} checked={access_type === 'free'} onClick={this.onAccessTypeClick.bind(null, 'free')} /></div>
        <div><CheckBox label={'Subscription'} checked={access_type === 'subscription'} onClick={this.onAccessTypeClick.bind(null, 'subscription')} /></div>
        <div><CheckBox label={'Buy'} checked={access_type === 'buy'} onClick={this.onAccessTypeClick.bind(null, 'buy')} /></div>
        <div><CheckBox label={'Rent'} checked={access_type === 'rent'} onClick={this.onAccessTypeClick.bind(null, 'rent')} /></div>
      </div>
      {access_type === 'buy' || access_type === 'rent' ? <div style={{fontSize: '22px'}}>
        <span className="bold" style={{display: "inline-block", width: "80px"}}>Price : </span>
        <InputField style={{display: "inline-block", width: "50px"}} type='number' min={1} value={price} onChange={this.onPriceChange} />
        <span className="bold" style={{fontFamily: 'Roboto'}}>€</span>
      </div> : undefined}
      {access_type === 'rent' ? <div style={{fontSize: '22px'}}>
        <div className="bold" >Renting time : </div>
        <div>{this.getRentingTimeButtons()}</div>
      </div> : undefined}
      <div className="modal-footer">
        <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
        <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                style={{fontSize: "20px"}}/>
      </div>
    </div> : undefined;
    return (
      <Modal id="land-access-form"
             content={content}
             getOpen={this.getOpen}
             getClose={this.getClose}
             afterOpen={this.afterOpen}
             afterClose={this.afterClose}
             undismissible={this.state.undismissible}
      />
    )
  }
};
