import generic from "./generic";

const self = {

    loadOwnedSubscriptions: (cb) => {
        //generic.loadOwnedEntities('subscription', cb);
        generic.loadEntities('subscription', cb);
    },

    getUnitTimeText: (unit_time) => {
        if(unit_time === 604800){
            return 'week';
        }else if(unit_time === 2592000){
            return 'month';
        }else if(unit_time === 31536000){
            return 'year';
        }
    }

}

export default self;