import { JsonRpcProvider, BrowserProvider, AbstractProvider, Contract, parseEther, formatEther } from 'ethers';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
//import { LedgerConnector } from "@web3-react/ledger-connector";
import LT18 from './artifacts/contracts/LT18.sol/LandTerminal18.json';
import model from "./model/model";
import Land from "./model/Land";
import Config from './config';
import FlashSales from "./model/FlashSales";

// Update with the contract address logged out to the CLI when it was deployed
const contractAddress = Config.contract.address;

const DEBUG = false;

const CHAIN_IDS = {
    mainnet: 1,
    sepolia: 11155111
}

function log(str){
    if(DEBUG){
        console.log('ContractHandler: ' + str);
    }
}

const ERROR_CODES = {
    EROP1: "Cannot set zero as target",
    EROW1: "Caller is not contract owner",
    EROW2: "Error while sending fund to target",
    ERLO1: "Lock is not disengaged by other owner",
    ERLO2: "Lock was not disengaged recently",
    ERLO3: "Lock target is not set",
    ERLO4: "Unlocked target not corresponding to given target",
    ERLT1: "Invalid token id",
    ERLT2: "Public sale not open for this zone",
    ERLT3: "Value sent does not correspond to price",
    ERLT4: "Land already minted",
    ERZ1: "Invalid zone number",
    "user rejected transaction": "Transaction rejected by user",
    "insufficient funds for intrinsic transaction cost": "Insufficient funds for transaction",
};

const web3 = {

    initialized: false,
    provider: null,
    signer: null,
    contract: null,
    user_address: null,
    user_balance: 0,
    watching_transfer_events: false,

    wallets: {
        MetaMask: null,
        Coinbase: null,
        WalletConnect: null,
        Ledger: null,
    },
    
    connected_wallet: null,

    initialize: (cb = () => {}) => {
        web3.detectWallets().then();
        setInterval(() => {
            web3.detectWallets().then();
        }, 1000);
        // web3.provider = new JsonRpcProvider(Config.contract.infura_url);
        // web3.contract = new Contract(contractAddress, LT18.abi, web3.provider);
        web3.reloadData().then(() => {
            web3.initialized = true;
            FlashSales.initialize(web3.contract, () => {
                cb();
                let connected_wallet = sessionStorage.getItem('connected_wallet');
                if(connected_wallet){
                    web3.connectWallet(connected_wallet).then(() => {
                       console.log('Wallet reconnected');
                    });
                }
            });
        });
    },

    initializeTransferWatcher: () => {
        return;
        if(web3.watching_transfer_events){
            return;
        }
        web3.watching_transfer_events = true;
        web3.contract.on("Transfer", (from, to, value, event) => {
            model.onLandTransferEvent(event);
        });
    },

    detectWallets: async () => {

        if(window.ethereum){
            if(window.ethereum.providers){
                for(let provider of window.ethereum.providers){
                    if(provider.isMetaMask){
                        web3.wallets.MetaMask = provider;
                    }else if(provider.isCoinbaseWallet){
                        web3.wallets.Coinbase = provider;
                    }
                }
            }else{
                if (web3.wallets.MetaMask === null && typeof window.ethereum !== "undefined") {
                    web3.wallets.MetaMask = window.ethereum;
                    window.ethereum.on('accountsChanged', function (accounts) {
                        sessionStorage.setItem('current_user', undefined);
                        window.location.reload();
                    });
                    window.ethereum.on('networkChanged', function (networkId) {
                        sessionStorage.setItem('current_user', undefined);
                        window.location.reload();
                    });
                }

                if (
                    web3.wallets.Coinbase === null &&
                    typeof window.web3 !== "undefined" &&
                    (typeof window.web3.currentProvider.isCoinbaseWalletApp !== "undefined" || window.web3.currentProvider.isCoinbaseWallet)
                ) {
                    web3.wallets.Coinbase = window.web3.currentProvider;
                }
            }
        }

        if (web3.wallets.WalletConnect === null) {
            web3.wallets.WalletConnect = await EthereumProvider.init({
                projectId: Config.contract.wallet_connect_project_id, // required
                chains: [1, 11155111], // required
                showQrModal: true // requires @walletconnect/modal
            });
        }

        /*if (web3.wallets.Ledger === null) {
            web3.wallets.Ledger = new LedgerConnector({
                chainId: 1,
                url: Config.contract.infura_url,
                pollingInterval: 15000,
            });
        }*/

    },

    getWallet: (walletName) => {
        return web3.wallets[walletName];
    },

    resetWallet: (walletName) => {
        web3.wallets[walletName] = null;
    },

    connectWallet: async (walletName) => {
        console.log('connectWallet', walletName);
        let walletProvider = web3.getWallet(walletName);
        if(!walletProvider){
            return;
        }
        /*if (walletProvider instanceof LedgerConnector) {
            const ledgerProvider = await walletProvider.connect();
            walletProvider = ledgerProvider;
        } else */if (walletProvider instanceof EthereumProvider) {
            await walletProvider.enable();
        } else {
            await walletProvider.enable();
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        let provider = ['MetaMask', 'Coinbase'].indexOf(walletName) > -1  ? new BrowserProvider(window.ethereum) :  new AbstractProvider(walletProvider);
        web3.provider = provider;
        let network_data = await provider.getNetwork();
        //console.log(network_data)
        web3.network_name = network_data.name || getNetworkNameFromChainId(network_data.chainId);
        web3.signer = await provider.getSigner();
        web3.user_address = await web3.signer.getAddress();
        web3.contract = new Contract(contractAddress, LT18.abi, web3.signer);

        sessionStorage.setItem('connected_wallet', walletName);
        if(web3.network_name === config.contract.network_name){
            await web3.reloadData();
        }

    },

    reloadData: async () => {
        return;
        let data = await web3.contract.getZonesData();
        model.loadZones(data);
    },

    reloadLandsData: async function(){
        return;
        let eventFilter = web3.contract.filters.Transfer();
        let events = await web3.contract.queryFilter(eventFilter);
        model.loadLandsDataFromTransfers(events);
    },

    setUserAddress: (address) => {
        web3.user_address = address;
    },

    reloadUserBalance: (cb = () => {}) => {
        if(!web3.provider) return setTimeout(web3.reloadUserBalance.bind(null, cb), 1000);
        web3.provider.getBalance(web3.user_address).then((data) => {
            log("User balance : " + data);
            web3.user_balance = formatEther(data);
            return cb();
        })
    },

    buyLand: (land_id, tokenId, price, cb = () => {}) => {
        log('Buying land : ' + tokenId);
        const options = {value: parseEther(price)};
        web3.contract.buyLand(tokenId, options).then(data => {
            log("Land bought, data : " + JSON.stringify(data));
            let land = Land.getLandFromTokenId(tokenId);
            land.processing_transaction = true;
            Land.onLandUpdate();
            //API.landBuyRequested(land_id, data.hash);
            cb(null, data);
            web3.initializeTransferWatcher();
        }).catch(err => {
            log('Error while buying land : ' + JSON.stringify(err));
            log(err);
            let error = "Error while buying land : ", reason = err.reason || "";
            web3.handleContractError(reason, error);
            return cb(err);
        })
    },

    buyFlashSaleLand: (land_id, tokenId, price, cb = () => {}) => {
        log('Buying flash sale land : ' + tokenId);
        const options = {value: parseEther(price)};
        web3.contract.buyFlashSaleLand(tokenId, options).then(data => {
            log("Land bought, data : " + JSON.stringify(data));
            let land = Land.getLandFromTokenId(tokenId);
            land.processing_transaction = true;
            Land.onLandUpdate();
            //API.landBuyRequested(land_id, data.hash);
            cb(null, data);
            web3.initializeTransferWatcher();
        }).catch(err => {
            log('Error while buying land : ' + JSON.stringify(err));
            log(err);
            let error = "Error while buying land : ", reason = err.reason || "";
            web3.handleContractError(reason, error);
            return cb(err);
        })
    },

    signMessage: (message, cb) => {
        try{
            let signer = web3.signer;
            signer.signMessage(message).then((data) => {
                return cb(null, data);
            }).catch(err => {
                log('Error while signing message : ' + JSON.stringify(err));
                log(err);
                return cb(err);
            });
        }catch(e){
            return cb(e);
        }
    },

    toggleZonePublicSale: (zone_number, enable, cb) => {
        web3.contract.toggleZonePublicSale(zone_number, enable).then((data) => {
            log("Zone public sale successfully toggled");
            log(data);
            return cb(null, data);
        }).catch(err => {
            log('Error while toggling zone public sale : ' + JSON.stringify(err));
            log(err);
            web3.handleContractError(err.reason || "");
            return cb(err);
        })
    },

    editZonePrice: (zone_number, price, cb) => {
        let contract_price = Math.round(price * Config.price_decimals);
        web3.contract.setZonePrice(zone_number, contract_price).then((data) => {
            log("Zone price successfully edited");
            log(data);
            return cb(null, data);
        }).catch(err => {
            log('Error while editing zone price : ' + JSON.stringify(err));
            log(err);
            web3.handleContractError(err.reason || "");
            return cb(err);
        })
    },

    unlockContract: (cb) => {
        web3.contract.disengageLock().then((data) => {
            log("Contract lock successfully disengaged");
            log(data);
            return cb(null, data);
        }).catch(err => {
            log('Error while disengaging contract lock : ' + JSON.stringify(err));
            log(err);
            web3.handleContractError(err.reason || "");
            return cb(err);
        })
    },

    unlockContractWithTarget: (target, cb) => {
        web3.contract.disengageLockWithTarget(target).then((data) => {
            log("Contract lock successfully disengaged with target");
            log(data);
            return cb(null, data);
        }).catch(err => {
            log('Error while disengaging contract lock with target : ' + JSON.stringify(err));
            log(err);
            web3.handleContractError(err.reason || "");
            return cb(err);
        })
    },

    drainContractFunds: (target, cb) => {
        web3.contract.drain(target).then((data) => {
            log("Contract funds successfully drained to target");
            log(data);
            return cb(null, data);
        }).catch(err => {
            log('Error while draining contract funds to target : ' + JSON.stringify(err));
            log(err);
            web3.handleContractError(err.reason || "");
            return cb(err);
        })
    },

    handleContractError: (err, msg_prefix) => {
        let error_msg = "Unknown";
        if(err){
            for(let code in ERROR_CODES){
                if(err.indexOf(code) > -1){
                    error_msg = ERROR_CODES[code];
                    break;
                }
            }
        }
        if(msg_prefix){
            error_msg = msg_prefix + error_msg;
        }
        window.alertPopup('Smart Contract Error', error_msg);
    },

    awaitTransaction: (tx_id, cb) => {
        let check_interval = setInterval(() => {
            web3.provider.getTransaction(tx_id).then(data => {
                if(data.blockNumber){
                    clearInterval(check_interval);
                    return cb();
                }
            })
        }, 1000);
    },

    onWalletUpdateListenerList: [],

    onWalletUpdateSubscribe: (cb) => {
        web3.onWalletUpdateListenerList.push(cb);
    },

    onWalletUpdate: () => {
        for(let cb of web3.onWalletUpdateListenerList){
            cb();
        }
    }

}

function getNetworkNameFromChainId(chain_id){
    for(let name in CHAIN_IDS){
        if(CHAIN_IDS[name] === chain_id){
            return name;
        }
    }
}

window.t18_web3 = web3;

export default web3;