import generic from "./generic";
import API from "../api";

const Screen = {

    loadOwnedScreens: (cb) => {
        generic.loadOwnedEntities('screen', cb);
    },

    refreshScreen: (screen_id, cb = () => {}) => {
        generic.refreshEntity('screen', screen_id, cb);
    },

    getScreen: (id) => {
        return generic.getEntity('screen', id);
    },

    getOwnedScreens: () => {
        return generic.getOwnedEntities('screen');
    },

    updateScreenMedia: (screen_id, media_id, options, cb = () => {}) => {
        options = options || {};
        let media = generic.getEntity('media', media_id),
            update = {};
        if(media.type === 'picture'){
            update.type = 'image';
            update.data = {
                zoom: false,
                trigger: 0,
                media_id: media_id
            }
        }else if(media.type === 'video') {
            update.type = 'video';
            update.data = {
                zoom: true,
                showControls: true,
                trigger: 0,
                media_id: media_id
            }
        }else{
            console.log('Error while updating screen media : invalid media type : ' + media.type);
            return cb('invalid media type');
        }
        generic.updateEntity('screen', screen_id, update, (err, data) => {
            if(err){
                return cb(err);
            }else{
                return cb(null, data);
                if(options.target_entity && options.target_entity_id && options.target_index != null) {
                    Screen.checkMediaEntityScreenScale(media_id, options.target_entity, options.target_entity_id, options.target_index, cb);
                }
            }
        });
    },

    checkMediaEntityScreenScale: (media_id, target_entity, target_entity_id, target_index, cb = () => {}) => {
        let media = generic.getEntity('media', media_id),
            media_width = _.get(media, 'metadata.width', 0),
            media_height = _.get(media, 'metadata.height', 0),
            target = generic.getEntity(target_entity, target_entity_id),
            target_screen = _.get(target, 'data.screens[' + target_index + ']'),
            target_template = generic.getEntity(target_entity + '_template', target[target_entity + '_template_id']),
            target_template_screen = _.get(target_template, 'data.screens[' + target_index + ']');
        if(media && target_screen && media_width && media_height && target_template_screen){
            let media_ratio = media_width / media_height,
                screen_width = target_screen.scale.x,
                screen_height = target_screen.scale.y,
                max_width = target_template_screen.scale.x,
                max_height = target_template_screen.scale.y,
                screen_ratio = screen_width / screen_height;
            if(media_ratio < 0.95 * screen_ratio || media_ratio > 1.05 * screen_ratio){
                let scale_update = {
                    x: screen_height * media_ratio,
                    y: screen_height,
                    z: target_screen.scale.z
                };
                if(media_ratio < 0.95 * screen_ratio){
                    scale_update.x = Math.min(max_height * media_ratio, max_width);
                    scale_update.y = max_height;
                }else{
                    scale_update.x = max_width;
                    scale_update.y = Math.min(max_width / media_ratio, max_height);
                }
                API.editEntityTarget(target_entity, target_entity_id, 'screen', target_index, {scale: scale_update}, (err, data) => {
                    console.log('Screen scale updated : ' + JSON.stringify(scale_update));
                    target_screen.scale = scale_update;
                }, (err) => {
                    console.log('Error while updating screen scale : ' + JSON.stringify(err) + ' ==> ' + JSON.stringify(scale_update));
                });
            }
        }else{
            console.log('Cannot update screen scale, missing data :');
            if(!media){
                console.log('- media');
            }else if(!media_width || !media_height){
                console.log('- media metadata');
            }
            if(!target){
                console.log('- target');
            }
            if(!target_screen){
                console.log('- target screen');
            }
            if(!target_template){
                console.log('- target template');
            }
            if(!target_template_screen){
                console.log('- target template screen');
            }
        }
    }

}

export default Screen;

window.Screen = Screen;